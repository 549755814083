import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {FileUpload} from "primereact/fileupload";

import ReceiptCostImportService from "../../services/receipt/ReceiptCostImportService";
import PartnerListService from "../../services/partner/PartnerListService";
import ReceiptgroupListService from "../../services/receiptgroup/ReceiptgroupListService";
import {AutoComplete} from "primereact/autocomplete";
import {Dropdown} from "primereact/dropdown";
import CompanyListService from "../../services/company/CompanyListService";
import ReceiptSzamlazzhuImportService from "../../services/receipt/ReceiptSzamlazzhuImportService";
import ImportqueueListComponent from "../importqueue/ImportqueueListComponent";

const ReceiptSzemlazzhuImportComponent = (props) => {

  //default form
  const [formData, setFormData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [receiptgroups, setReceiptgroups] = useState([]);

  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    ReceiptSzamlazzhuImportService(data)
      .then(response => {
        window.App.toastShow('success', 'Sikeres importálás!', '');
        setFormData({...formData, xls: null})
        window.App.setState({
          //sidebarReceiptSzamlazzhuImport: false,
          rerenderReceiptList: true,
          rerenderImportqueueList: true,
        })
        setLoading(false)
      })
      .catch(function (error) {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setLoading(false)
      })
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  const fileUpload = (files) => {
    var reader = new FileReader();
    reader.onloadend = () => {
      let base64data = reader.result;
      setFormData({
        ...formData,
        file: base64data.toString().replace(/^data:(.*,)?/, '')
      })
    }
    reader.readAsDataURL(files.files[0])
  }

  // company autocomplete
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(localStorage.getItem('defaultCompanyName'));

  const searchCompany = (event) => {
    CompanyListService(JSON.stringify({filters: {name: {value: event.query}}}))
      .then(response => {
        setCompanies(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  useEffect(() => {
    let _formData = {}
    ReceiptgroupListService(JSON.stringify({
      filters: {
        external: {value: true},
        active: {value: true},
        type: {value: 'invoice'},
        outgoing: {value: true}
      }
    }))
      .then(response => {
        setReceiptgroups(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    if (localStorage.getItem('defaultCompanyId')) {
      _formData = {
        ..._formData,
        company: {
          id: Number(localStorage.getItem('defaultCompanyId'))
        }
      }
    }
    if (window.App.state.props && window.App.state.props.receiptgroupId) {
      _formData = {
        ..._formData,
        receiptGroupId: Number(window.App.state.props.receiptgroupId)
      }
    }
    setFormData(_formData)
  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">

        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-12 p-xl-12  p-col-align-center"}>
              <h3>
                <i className={"pi pi-upload"}></i> Fájl feltöltése
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            <div className={"p-col"}>
              <Field name="company" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="company"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Vállalkozás</label>
                  <span className="p-d-block">
                            <AutoComplete value={selectedCompany}
                                          id={"company"}
                                          suggestions={companies}
                                          forceSelection
                                          completeMethod={searchCompany}
                                          field={"name"}
                                          delay="1000"
                                          placeholder={"Keresés gépeléssel..."}
                                          className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                          onSelect={(e) => {
                                            setFormData({
                                              ...formData,
                                              company: {id: e.value.id}
                                            })
                                            localStorage.setItem('defaultCompanyId', e.value.id)
                                            localStorage.setItem('defaultCompanyName', e.value.name)
                                          }}
                                          onChange={(e) => {
                                            setFormData({
                                              ...formData,
                                              company: {id: null}
                                            })
                                            setSelectedCompany(e.value)
                                          }}
                                          dropdown
                                          onClear={(e) => {
                                            setSelectedCompany('')
                                          }}
                            />
                          </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4 p-xl-4"}>
              <Field name="receiptGroup" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="receiptGroup"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Számlatömb</label>
                  <span className="p-d-block">
                              <Dropdown {...input}
                                        value={Number(formData.receiptGroupId)}
                                        options={receiptgroups}
                                        optionLabel="name"
                                        optionValue="id"
                                        id={"receiptGroup"}
                                        onChange={(e) => {
                                          setFormData({
                                            ...formData,
                                            receiptGroupId: e.value
                                          })
                                        }}
                                        emptyMessage={"Még nincs számlatömb..."}
                                        className={classNames({'p-error': isFormFieldValid(meta)})}
                              />
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col"}>
              <Field name="file" render={({input, meta}) => (
                <div className="p-field">
                  {!formData.file ? (
                    <>
                      <label htmlFor="image"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>XLS kiválasztása</label>
                      <span className="p-d-block">
                               <FileUpload mode="basic"
                                           name="file"
                                           id={"file"}
                                           accept=""
                                           auto="true"
                                 //maxFileSize={1000000}
                                           customUpload
                                           uploadHandler={fileUpload}
                                           chooseLabel={"Fájl kiválasztása"}
                                           uploadLabel={"Feltöltés"}
                               />
                            </span>
                    </>
                  ) : (
                    <>
                      <label htmlFor="image"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Feltöltött XLS
                        törlése</label>
                      <span className="p-d-block">
                              <Button type="button"
                                      className="p-button p-button-danger"
                                      icon={"pi pi-trash"}
                                      onClick={() => setFormData({...formData, file: null})}
                              >Törlés</Button>
                            </span>
                    </>
                  )}
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid p-mt-1"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              {formData.file ? (
                <Button type="submit" label="Import indítása" icon={"pi pi-check"} loading={loading}
                        className="p-button-success"/>
              ) : (
                <Button type="button" label="Import indítása" icon={"pi pi-check"}
                        className="p-button-success"
                        onClick={() => {
                          alert('Tölts fel egy XLS fájlt!')
                        }}
                />
              )}
            </div>
          </div>
        </Panel>
        <Panel className={"p-mt-3"}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-12 p-xl-12 p-col-align-center"}>
              <h3>
                <i className={"pi pi-upload"}></i> Import folyamatok
              </h3>
            </div>
          </div>
          <ImportqueueListComponent/>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(ReceiptSzemlazzhuImportComponent);
