import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {SplitButton} from "primereact/splitbutton";
import moment from "moment";
import download from 'js-file-download';

import EmployeeListService from "../../services/employee/EmployeeListService";
import EmployeeDeleteService from "../../services/employee/EmployeeDeleteService";
import EmployeeMassInviteService from "../../services/employee/EmployeeMassInviteService";
import EmployeeExportService from "../../services/employee/EmployeeExportService";

const EmployeeListComponent = (props) => {

  const [employee, setEmployee] = useState([]);
  const [selectedEmployees, setSelectedEmloyees] = useState([]);

  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(Number(process.env.REACT_APP_LIST_ROW_DEFAULT));
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'name',
    sortOrder: -1
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderEmployeeList: false})
      EmployeeListService(JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setEmployee(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: Number(process.env.REACT_APP_LIST_ROW_OP1), value: Number(process.env.REACT_APP_LIST_ROW_OP1)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP2), value: Number(process.env.REACT_APP_LIST_ROW_OP2)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP3), value: Number(process.env.REACT_APP_LIST_ROW_OP3)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP4), value: Number(process.env.REACT_APP_LIST_ROW_OP4)},
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  const deleteEmployee = (rowData) => {
    EmployeeDeleteService(rowData.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres törlés!',);
        loadLazyData();
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {localStorage.getItem('roles').toString().includes('role_employee_write') &&
        <Button icon="pi pi-pencil" className="  p-button-warning p-mr-2"
                tooltip="Módosítás"
                onClick={() => {
                  window.App.setState({
                    sidebarEmployeeView: true,
                    props: rowData,
                    activeindexEmloyeeView: 0,
                    modeEmployeeView: 'edit',
                  })
                }}/>
        }
        <Button icon="pi pi-eye" className=" p-button p-mr-2"
                tooltip="Megtekintés"
                onClick={() => {
                  window.App.setState({
                    sidebarEmployeeView: true,
                    props: rowData,
                    activeindexEmloyeeView: 0,
                    modeEmployeeView: 'show',
                  })
                }}/>
        {/*{localStorage.getItem('roles').toString().includes('role_employee_write') &&*/}
        {/*<Button icon="pi pi-trash" className="  p-button-danger p-mr-2"*/}
        {/*        tooltip="Törlés"*/}
        {/*        onClick={() => {*/}
        {/*          if (window.confirm('A törlés megerősítésére van szükség!')) {*/}
        {/*            deleteEmployee(rowData)*/}
        {/*          }*/}
        {/*        }}*/}
        {/*/>*/}
        {/*}*/}
        {/*{localStorage.getItem('roles').toString().includes('role_employee_write') &&*/}
        {/*<Button icon="pi pi-envelope" className=" p-button-success p-mr-2"*/}
        {/*        tooltip="Meghívás"*/}
        {/*        onClick={() => {*/}
        {/*          EmployeeInviteService(rowData.id)*/}
        {/*            .then(response => {*/}
        {/*              window.App.toastShow('success', 'Sikeres meghívás!',);*/}
        {/*            })*/}
        {/*            .catch(error => {*/}
        {/*              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);*/}
        {/*            })*/}
        {/*        }}/>*/}
        {/*}*/}
      </React.Fragment>
    );
  }

  useEffect(() => {
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderEmployeeList === true])

  return (
    <>
      {props && props.view === 'simple' &&
        <>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6 p-col-align-center p-text-center p-text-lg-left"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-col-align-center p-text-center p-text-lg-right"}>
              {localStorage.getItem('roles').toString().includes('role_employee_import') &&
                <a onClick={() => window.App.setState({sidebarEmployeeImport: true})}
                   className={"p-button  p-button-secondary p-ml-md-2 p-my-1 p-my-lg-0"}>
                  <i className={"pi pi-cloud-upload"}></i>
                  &nbsp;
                  Munkavállalók importálása
                </a>
              }
              {localStorage.getItem('roles').toString().includes('role_employee_write') &&
                <a onClick={() => window.App.setState({sidebarEmployeeCreate: true})}
                   className={"p-button  p-button-success p-ml-md-2 p-my-1 p-my-lg-0"}>
                  <i className={"pi pi-plus"}></i>
                  &nbsp;
                  Új munkavállaló
                </a>
              }
            </div>
          </div>
        </>
      }
      <DataTable size={localStorage.getItem('scale')}
        emptyMessage="Nincs találat."
        value={employee} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
        paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
        autoLayout={true}
        stripedRows
        selection={selectedEmployees}
        onSelectionChange={e => {
          setSelectedEmloyees(e.value)
        }}
        selectionMode="checkbox"
        dataKey="id"
        filterDisplay="row"
        responsiveLayout="scroll"
      >
        <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
        <Column field="name" header="Név" sortable filter filterPlaceholder="Név"></Column>
        <Column field="companyName" header="Cég" sortable filter filterPlaceholder="Cég"></Column>
        <Column field="employeePositionName" header="Beosztás" sortable filter filterPlaceholder="Beosztás"></Column>
        <Column field="active" header="Aktív" sortable className={"p-text-center"} body={(rowData) => {
          return (
            <span id={rowData.id}>
            {rowData.active === true ? (
              <i className={"pi pi-check text-success"}></i>
            ) : (
              <i className={"pi pi-times text-danger"}></i>
            )}
            </span>
          )
        }}></Column>
        {process.env.REACT_APP_EMPLOYEE_INVITE_TIME === 'true' &&
        <Column field="invitedAt" header="Meghívás időpontja" sortable body={(rowData) => {
          if (rowData.invitedAt !== null) {
            return (
              <span>{moment.utc(rowData.invitedAt).local().format('YYYY-MM-DD HH:mm:ss')}</span>
            )
          }
        }}></Column>
        }
        {/*<Column field="createdAt" header="Létrehozás ideje" sortable body={(rowData) => {*/}
        {/*  if (rowData.createdAt !== null) {*/}
        {/*    return (*/}
        {/*      <span>{moment.utc(rowData.createdAt).local().format('YYYY-MM-DD HH:mm:ss')}</span>*/}
        {/*    )*/}
        {/*  }*/}
        {/*}}></Column>*/}
        <Column body={actionBodyTemplate} className={"p-py-0 p-text-right p-text-nowrap"}></Column>
      </DataTable>
      <div className={"p-grid p-mt-1"}>
        <div className={"p-col-12 p-lg-6 p-xl-6"}>
        </div>
        <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
          {localStorage.getItem('roles').toString().includes('role_employee_write') &&
          <>
            <Button type="button"
                    label="Kijelöltek meghívása"
                    icon={"pi pi-envelope"}
                    loading={loading}
                    onClick={() => {
                      setLoading(true)
                      let _selectedEmployees = []
                      Object.entries(selectedEmployees).map(function (employee, index) {
                        _selectedEmployees.push(employee[1].id)
                      })
                      EmployeeMassInviteService(_selectedEmployees)
                        .then(response => {
                          window.App.toastShow('success', 'Sikeres művelet!',);
                          setLoading(false)
                          if (response && response.data && response.data[0]) {
                            response.data.map((item, index) => {
                              if (item.status === 'failed' && document.getElementById(item.id)) {
                                document.getElementById(item.id).parentElement.parentElement.classList.add('tr-error')
                              }
                              if (item.status === 'success' && document.getElementById(item.id)) {
                                document.getElementById(item.id).parentElement.parentElement.classList.add('tr-success')
                              }
                            })
                          }
                          loadLazyData();
                        })
                        .catch(error => {
                          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                          setLoading(false)
                        })

                    }}
                    className="p-button-info p-button-highlighted  p-ml-2"/>
          </>
          }
          {localStorage.getItem('roles').toString().includes('role_employee_export') &&
          <span id={"employee-export"}>
                <>
                  <Button type="button"
                          label="Kijelöltek exportálása"
                          onClick={() => {
                            document.getElementById('employee-export').children[0].children[1].click()
                          }}
                          icon={"pi pi-download"}
                          loading={loading}
                          onClick={() => {
                            setLoading(true)
                            let _selectedEmployees = []
                            Object.entries(selectedEmployees).map(function (employee, index) {
                              _selectedEmployees.push(employee[1].id)
                            })
                            EmployeeExportService('general', _selectedEmployees)
                              .then(response => {
                                download(response.data, localStorage.getItem('networkName')
                                  + '_'
                                  + 'munkavallalo-altalanos-export'
                                  + '_'
                                  + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
                                  + '.xlsx')
                                setLoading(false)
                              })
                              .catch(error => {
                                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                setLoading(false)
                              })
                          }}
                          className="p-button-info p-button-highlighted  p-ml-2"/>
                </>
          </span>
          }
        </div>
      </div>
    </>
  )
}
export default withTranslation('common')(EmployeeListComponent);
