import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";

import BankaccountFormService from '../../services/bankaccount/BankaccountFormService'
import {AutoComplete} from "primereact/autocomplete";
import {Dropdown} from "primereact/dropdown";
import CompanyListService from "../../services/company/CompanyListService";
import {InputSwitch} from "primereact/inputswitch";
import CurrencyListService from "../../services/currency/CurrencyListService";

const BankaccountFormComponent = (props) => {

  //form
  const [formData, setFormData] = useState({
    active: true,
    company: {
      id: Number(localStorage.getItem('defaultCompanyId'))
    }
  });
  const [loading, setLoading] = useState(false);
  const [currencies, setCurrencies] = useState([]);

  const validate = (data) => {
    let errors = {};
    // if (!data.name) {
    //   errors.name = 'Az megnevezés nem lehet üres';
    // }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if (props && props.initialValues) {
      BankaccountFormService(data, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({
            rerenderBankaccountList: true,
            sidebarBankaccountUpdate: false
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      BankaccountFormService(data, 'post')
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          window.App.setState({
            rerender: true,
            rerenderBankaccountList: true,
            sidebarBankaccountCreate: false
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // company autocomplete
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(localStorage.getItem('defaultCompanyName'));

  const searchCompany = (event) => {
    CompanyListService(JSON.stringify({filters: {name: {value: event.query}}}))
      .then(response => {
        setCompanies(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }
  useEffect(() => {
    CurrencyListService()
      .then(response => {
        setCurrencies(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    if (props && props.initialValues) {
      setFormData(
        props.initialValues
      )
    }
  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit, submitting}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="company" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="company"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Cég</label>
                  <span className="p-d-block">
                                        {localStorage.getItem('roles').toString().includes('role_company_browse')
                                        || localStorage.getItem('roles').toString().includes('role_company_read') ? (

                                          <AutoComplete value={selectedCompany}
                                                        id={"company"}
                                                        suggestions={companies}
                                                        forceSelection
                                                        completeMethod={searchCompany}
                                                        field={"name"}
                                                        delay="1000"
                                                        placeholder={"Keresés gépeléssel..."}
                                                        className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                                        onSelect={(e) => {
                                                          setFormData({
                                                            ...formData,
                                                            company: {id: e.value.id}
                                                          })
                                                          localStorage.setItem('defaultCompanyId', e.value.id)
                                                          localStorage.setItem('defaultCompanyName', e.value.name)
                                                        }}
                                                        onChange={(e) => {
                                                          setFormData({
                                                            ...formData,
                                                            company: {id: null}
                                                          })
                                                          setSelectedCompany(e.value)
                                                        }}
                                                        dropdown
                                                        onClear={(e) => {
                                                          setSelectedCompany('')
                                                        }}
                                          />
                                        ) : (
                                          <Dropdown disabled className={"disabled"} id={"company"}/>
                                        )}
                          </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-3"}>
              <Field name="active" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="active"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Aktív</label>
                  <span className="p-d-block">
                        <InputSwitch
                          className={"p-mt-1"}
                          checked={formData.active}
                          value={formData.active}
                          onChange={e => setFormData({...formData, active: e.target.value})}/>
                      </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            {/*<div className={"p-col-12 p-lg-3"}>*/}
            {/*  <Field name="default" render={({input, meta}) => (*/}
            {/*    <div className="p-field p-fluid">*/}
            {/*      <label htmlFor="active"*/}
            {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Alap bankszámla</label>*/}
            {/*      <span className="p-d-block">*/}
            {/*            <InputSwitch*/}
            {/*              className={"p-mt-1"}*/}
            {/*              checked={formData.default}*/}
            {/*              value={formData.default}*/}
            {/*              onChange={e => setFormData({...formData, default: e.target.value})}/>*/}
            {/*          </span>*/}
            {/*      {getFormErrorMessage(meta)}*/}
            {/*    </div>*/}
            {/*  )}/>*/}
            {/*</div>*/}
            <div className={"p-col-12 p-lg-6"}>
              <Field name="name" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="name"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Bank neve</label>
                  <span className="p-d-block">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="bankaccount" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="bankaccount"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Számlaszám</label>
                  <span className="p-d-block">
                      <InputText id="bankaccount" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     bankaccount: e.target.value
                                   })
                                 }}
                                 value={formData.bankaccount}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="swift" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="swift"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Swift</label>
                  <span className="p-d-block">
                      <InputText id="swift" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     swift: e.target.value
                                   })
                                 }}
                                 value={formData.swift}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="iban" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="iban"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>IBAN</label>
                  <span className="p-d-block">
                      <InputText id="iban" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     iban: e.target.value
                                   })
                                 }}
                                 value={formData.iban}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-xl-6"}>
              <Field name="currency" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="currency"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Pénznem</label>
                  <span className="p-d-block">
                    <Dropdown options={currencies}
                              optionLabel="code"
                              optionValue="code"
                              id={"currency"}
                              emptyMessage={"Még nincs pénznem..."}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  currency: e.value
                                })
                              }}
                              value={formData.currency}
                    />
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid p-mt-1"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              <Button type="submit" label="Mentés" icon="pi pi-check" loading={loading}
                      className="p-button-success"/>
            </div>
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(BankaccountFormComponent);
