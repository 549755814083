import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";

import FulfillmentListService from "../../services/fulfillment/FulfillmentListService";
import FulfillmentFormComponent from "./FullfillmentFormComponent";
import {Dialog} from "primereact/dialog";
import OfferitemPricelistitemListComponent from "../offer/OfferitemPricelistitemListComponent";
import OfferitemProductListComponent from "../offer/OfferitemProductListComponent";
import FulfillmentItemListComponent from "./FulfillmentItemListComponent";
import ReceiptgroupListService from "../../services/receiptgroup/ReceiptgroupListService";
import OrderCreateReceiptService from "../../services/order/OrderCreateReceiptService";
import {SplitButton} from "primereact/splitbutton";
import FulfillmentCreateReceiptService from "../../services/fulfillment/FulfillmentCreateReceiptService";

const FulfillmentListComponent = (props) => {

  const [fulfillments, setFulfillments] = useState(false);
  const [loading, setLoading] = useState(false);
  const [receiptGroups, setReceiptGroups] = useState([]);

  const dt = useRef(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(Number(process.env.REACT_APP_LIST_ROW_DEFAULT));
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1,
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      console.log(lazyParams)
      let _lazyParams = {
        ...lazyParams,
        filters: {
          ...lazyParams.filters,
          project: {
            value: props.projectId
          }
        }
      }
      window.App.setState({rerenderFulfillmentList: false})
      FulfillmentListService(JSON.stringify(_lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setFulfillments(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: Number(process.env.REACT_APP_LIST_ROW_OP1), value: Number(process.env.REACT_APP_LIST_ROW_OP1)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP2), value: Number(process.env.REACT_APP_LIST_ROW_OP2)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP3), value: Number(process.env.REACT_APP_LIST_ROW_OP3)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP4), value: Number(process.env.REACT_APP_LIST_ROW_OP4)},
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const actionBodyTemplate = (rowData) => {
    let _receiptGroups = []
    receiptGroups.map(function (item, index) {
      if (item) {
        _receiptGroups.push({
          label: item.name,
          icon: 'pi pi-plus',
          command: () => {
            setLoading(true)
            FulfillmentCreateReceiptService(rowData.id, item.id)
              .then(response => {
                setLoading(false)
                window.App.setState({
                  props: {
                    id: response.data.receiptId,
                    outgoing: item.outgoing,
                    external: item.external,
                    receiptType: item.type
                  },
                  sidebarReceiptUpdate: true,
                })
                setTimeout(() => {
                  window.App.setState({
                    rerenderOrderList: true,
                  })
                },1000)
              })
              .catch(error => {
                setTimeout(() => {
                  window.App.setState({
                    rerenderOrderList: true,
                  })
                },1000)
                setLoading(false)
                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
              })
          }
        })
      }
    })
    return (
      <React.Fragment>
        <Button icon="pi pi-pencil" className="  p-button-warning p-mr-2"
                tooltip="Módosítás"
                onClick={() => {
                  window.App.setState({
                    props: rowData,
                    dialogFullfillmentUpdate: true,
                  })
                }}/>
        {localStorage.getItem('roles').toString().includes('role_receipt_write') &&
        <SplitButton icon="pi pi-file" model={_receiptGroups} className={" p-button-info p-text-light"}/>
        }
        {/*<Button icon="pi pi-trash" className="  p-button-danger"*/}
        {/*        tooltip="Törlés"*/}
        {/*        onClick={() => {*/}
        {/*          if (window.confirm('A törlés megerősítésére van szükség!')) {*/}
        {/*            deleteFulfillment(rowData)*/}
        {/*          }*/}
        {/*        }}*/}
        {/*/>*/}
      </React.Fragment>
    );
  }

  const [expandedRows, setExpandedRows] = useState(null);
  const rowExpansionTemplate = (data) => {
    return (
      <>
        <div className="p-ml-3">
          <h3 className={"p-mt-0 p-pt-0"}>Teljesítés tételei</h3>
          <FulfillmentItemListComponent fulfillmentId={data.id}/>
        </div>
      </>
    );
  }

  useEffect(() => {
    ReceiptgroupListService(JSON.stringify({
      filters: {
        // receiptType: {
        //   value: 'invoice'
        // },
        outgoing: {
          value: true
        },
        external: {
          value: false
        }
      }
    }))
      .then(response => {
        setReceiptGroups(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderFulfillmentList === true])

  return (
    <>
      <DataTable size={localStorage.getItem('scale')}
        emptyMessage="Nincs találat."
        value={fulfillments} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
        paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
        autoLayout={true}
        filterDisplay="row"
        responsiveLayout="scroll"
        stripedRows
        ref={dt}
        dataKey="id"
        rowExpansionTemplate={rowExpansionTemplate}
        expandedRows={expandedRows}
        onRowExpand={(e) => {
          let id = e.data.id
          setExpandedRows({[id]: true})
          window.App.setState({
            fulfillmentItemRowData: e.data
          })
        }}
      >
        <Column field="name" header="Név" sortable filter filterPlaceholder="Név"></Column>
        <Column body={actionBodyTemplate} className={"p-py-0 p-text-right p-text-nowrap"}></Column>
        <Column expander style={{width: '3em'}}/>
      </DataTable>
      <Dialog visible={window.App.state.dialogFullfillmentUpdate}
              style={{width: '750px'}}
              header="Teljesítés módosítása"
              modal
              onHide={() => {
                window.App.setState({
                  dialogFullfillmentUpdate: false
                })
              }}>
        <FulfillmentFormComponent initialValues={window.App.state.props} />
      </Dialog>
    </>
  )
}
export default withTranslation('common')(FulfillmentListComponent);
