import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Tooltip} from "primereact/tooltip";

import PricelistitemListService from "../../services/pricelistitem/PricelistitemListService";
import currencyFormatter from "currency-formatter";
import moment from "moment";

const PricelistitemListComponent = (props) => {

  const [warehouse, setPricelistitem] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(Number(process.env.REACT_APP_LIST_ROW_DEFAULT));
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderPricelistitemList: false})
      PricelistitemListService(JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setPricelistitem(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: Number(process.env.REACT_APP_LIST_ROW_OP1), value: Number(process.env.REACT_APP_LIST_ROW_OP1)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP2), value: Number(process.env.REACT_APP_LIST_ROW_OP2)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP3), value: Number(process.env.REACT_APP_LIST_ROW_OP3)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP4), value: Number(process.env.REACT_APP_LIST_ROW_OP4)},
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {localStorage.getItem('roles').toString().includes('role_warehouse_write') &&
          <Button icon="pi pi-pencil" className="  p-button-warning p-mr-2"
                  tooltip="Módosítás"
                  onClick={() => {
                    window.App.setState({
                      sidebarPricelistitemUpdate: true,
                      props: rowData
                    })
                  }}/>
        }
        {/*<Button icon="pi pi-trash" className="  p-button-danger"*/}
        {/*        tooltip="Törlés"*/}
        {/*        onClick={() => {*/}
        {/*          if (window.confirm('A törlés megerősítésére van szükség!')) {*/}
        {/*            deletePricelistitem(rowData)*/}
        {/*          }*/}
        {/*        }}*/}
        {/*/>*/}
      </React.Fragment>
    );
  }

  useEffect(() => {
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderPricelistitemList === true])

  return (
    <>
      <>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-6 p-col-align-center p-text-center p-text-lg-left"}>

          </div>
          <div className={"p-col-12 p-lg-6 p-col-align-center p-text-center p-text-lg-right"}>
            {localStorage.getItem('roles').toString().includes('role_pricelist_item_write') &&
              <a onClick={() => window.App.setState({sidebarPricelistitemCreate: true})}
                 className={"p-button  p-button-success"}>
                <i className={"pi pi-plus"}></i>
                &nbsp;
                Új árlista
              </a>
            }
          </div>
        </div>
      </>
      <DataTable size={localStorage.getItem('scale')}
        emptyMessage="Nincs találat."
        value={warehouse} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
        paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
        autoLayout={true}
        filterDisplay="row"
        responsiveLayout="scroll"
        stripedRows
      >
        <Column field="name" header="Megnevezés" sortable filter filterPlaceholder="Megnevezés"></Column>
        {/*<Column field="machineName" header="Gép" sortable filter filterPlaceholder="Gép"></Column>*/}
        <Column field="technologyName" header="Technológia" sortable filter filterPlaceholder="technologyName"></Column>
        {/*<Column field="employeeName" header="Munkavállaló" sortable filter filterPlaceholder="employeeName"></Column>*/}
        <Column field="price" header="Érték" sortable className={"p-text-right p-text-nowrap"}
                alignHeader="right"
                body={(rowData) => {
                  let currency
                  if (rowData.currency === 'HUF') {
                    currency = {
                      precision: 2,
                      symbol: '',
                      decimal: ',',
                      thousand: ' ',
                    }
                  } else {
                    currency = {
                      precision: 2,
                      symbol: '',
                      decimal: '.',
                      thousand: ' ',
                    }
                  }
                  return (
                    <>
                      {currencyFormatter.format(
                        rowData.price, {
                          precision: currency.precision,
                          symbol: currency.symbol,
                          decimal: currency.decimal,
                          thousand: currency.thousand,
                        }
                      )}&nbsp;{rowData.currency}
                    </>
                  )
                }}></Column>
        <Column field="validFrom" header="Érvényesség kezdete" sortable className={"p-text-nowrap"} body={(rowData) => {
          if (rowData.validFrom !== null) {
            return (
              <>{moment.utc(rowData.validFrom).local().format('YYYY-MM-DD')}</>
            )
          }
        }}></Column>
        <Column field="validUntil" header="Érvényesség vége" sortable className={"p-text-nowrap"} body={(rowData) => {
          if (rowData.validUntil !== null) {
            return (
              <>{moment.utc(rowData.validUntil).local().format('YYYY-MM-DD')}</>
            )
          }
        }}></Column>
        <Column body={actionBodyTemplate} className={"p-py-0 p-text-right p-text-nowrap"}></Column>
      </DataTable>
    </>
  )
}
export default withTranslation('common')(PricelistitemListComponent);
