import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import moment from "moment";
import {Message} from "primereact/message";

import EmployeeShowService from "../../services/employee/EmployeeShowService";
import IdentitydocumenttemplateListService from "../../services/identitydocumenttype/IdentitydocumenttypeListService";
import SafetyTypeListService from "../../services/safety/SafetyTypeListService";
import SafetyemployeeByemployeeListService from "../../services/safetyemployee/SafetyemployeeByemployeeService";
import EmployeeInviteService from "../../services/employee/EmployeeInviteService";
import IdentifierByEmployeeListService from "../../services/identifier/IdentifierByEmployeeListService";
import EmployeedocumentListService from "../../services/employeedocument/EmployeedocumentListService";
import EmployeedocumentAddService from "../../services/employeedocument/EmployeedocumentAddService";
import DocumenttemplateListService from "../../services/documenttemplate/DocumenttemplateListService";
import OfferShowService from "../../services/offer/OfferShowService";
import OfferstatusListService from "../../services/offerstatus/OfferstatusListService";
import currencyFormatter from "currency-formatter";

const OfferShowComponent = (props) => {

  const [formData, setFormData] = useState({});

  // const [statuses, setStatuses] = useState([]);
  // const status = (rowData) => {
  //   if (statuses && statuses[0]) {
  //     let status = statuses.filter(item => item.id === rowData.status)
  //     if (status[0] && status[0].name) {
  //       return status[0].name
  //     }
  //   }
  // }

  const priceFormatter = (price) => {
    let currency
    if (formData.currency === 'HUF') {
      currency = {
        precision: 2,
        symbol: '',
        decimal: ',',
        thousand: ' ',
      }
    } else {
      currency = {
        precision: 2,
        symbol: '',
        decimal: '.',
        thousand: ' ',
      }
    }
    return (
      <>
        <span className={""}>
        {currencyFormatter.format(
          price, {
            precision: currency.precision,
            symbol: currency.symbol,
            decimal: currency.decimal,
            thousand: currency.thousand,
          }
        )
        }
          &nbsp;{formData.currency}
          </span>
      </>
    )
  }

  useEffect(() => {
    OfferShowService(window.App.state.props.id)
      .then(response => {
        setFormData(response.data)
        window.App.setState({rerenderOfferShow: false})
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    // OfferstatusListService()
    //   .then(response => {
    //     setStatuses(response.data);
    //   })
    //   .catch(error => {
    //     window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
    //   })
  }, [window.App.state.rerenderOfferShow === true])

  return (
    <>
      <div className={"p-grid"}>
        <div className={"p-col-12"}>
          <h3>
            <i className={"pi pi-list"}></i> Alapadatok
          </h3>
        </div>
      </div>
      <div className={"p-grid"}>
        {formData.companyName &&
        <div className={"p-col-12 p-lg-6"}>
          <label htmlFor="name">Vállalkozás</label>
          <p className={"p-mt-1 p-mb-0 p-text-bold"}>
            {formData.companyName}
          </p>
        </div>
        }
        {formData.companyContactName &&
        <div className={"p-col-12 p-lg-6"}>
          <label htmlFor="name">Vállalkozás kapcsolattartó</label>
          <p className={"p-mt-1 p-mb-0 p-text-bold"}>
            {formData.companyContactName}
          </p>
        </div>
        }
      </div>
      <div className={"p-grid p-mt-1"}>
        <div className={"p-col-12 p-lg-4"}>
          <label htmlFor="name">Nettó összesen</label>
          <p className={"p-mt-1 p-mb-0 p-text-bold"}>
            {priceFormatter(formData.netAmount)}
          </p>
        </div>
        <div className={"p-col-12 p-lg-4"}>
          <label htmlFor="name">ÁFA összesen</label>
          <p className={"p-mt-1 p-mb-0 p-text-bold"}>
            {priceFormatter(formData.vatAmount)}
          </p>
        </div>
        <div className={"p-col-12 p-lg-4"}>
          <label htmlFor="name">Bruttó összesen</label>
          <p className={"p-mt-1 p-mb-0 p-text-bold"}>
            {priceFormatter(formData.grossAmount)}
          </p>
        </div>
      </div>
      <div className={"p-grid p-mt-1"}>
        {formData.partnerName &&
        <div className={"p-col-12 p-lg-6"}>
          <label htmlFor="name">Partner</label>
          <p className={"p-mt-1 p-mb-0 p-text-bold"}>
            {formData.partnerName}
          </p>
        </div>
        }
        {formData.partnerContactName &&
        <div className={"p-col-12 p-lg-6"}>
          <label htmlFor="name">partner kapcsolattartó</label>
          <p className={"p-mt-1 p-mb-0 p-text-bold"}>
            {formData.partnerContactName}
          </p>
        </div>
        }
      </div>
      <div className={"p-grid p-mt-1"}>
        {/*{formData.status &&*/}
        {/*<div className={"p-col-12 p-lg-4"}>*/}
        {/*  <label htmlFor="name">Státusz</label>*/}
        {/*  <p className={"p-mt-1 p-mb-0 p-text-bold"}>*/}
        {/*    {status(formData)}*/}
        {/*  </p>*/}
        {/*</div>*/}
        {/*}*/}
        {formData.currency &&
        <div className={"p-col-12 p-lg-4"}>
          <label htmlFor="name">Pénznem</label>
          <p className={"p-mt-1 p-mb-0 p-text-bold"}>
            {formData.currency}
          </p>
        </div>
        }
        {formData.validUntil &&
        <div className={"p-col-12 p-lg-4"}>
          <label htmlFor="name">Érvényesség vége</label>
          <p className={"p-mt-1 p-mb-0 p-text-bold"}>
            {moment.utc(formData.validUntil).local().format('YYYY-MM-DD')}
          </p>
        </div>
        }
      </div>
      {formData.note &&
      <div className={"p-grid p-mt-1"}>
        <div className={"p-col-12"}>
          <label htmlFor="note">Megjegyzés</label>
          <p dangerouslySetInnerHTML={{__html: formData.note}}></p>
        </div>
      </div>
      }
      <div className={"p-grid p-mt-1"}>
        <div className={"p-col-12 p-lg-6"}>

        </div>
        <div className={"p-col-12 p-lg-6 p-text-right"}>
          <Button type="button" label="Módosít" icon={"pi pi-pencil"}
                  onClick={() => {
                    window.App.setState({
                      modeOfferView: 'edit',
                    })
                  }}
                  className="p-button p-button-warning"/>
        </div>
      </div>

    </>
  )
}
export default withTranslation('common')(OfferShowComponent);
