import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";

import OfferFormService from '../../services/offer/OfferFormService'
import {AutoComplete} from "primereact/autocomplete";
import EmployeeListService from "../../services/employee/EmployeeListService";
import {Dropdown} from "primereact/dropdown";
import CurrencyListService from "../../services/currency/CurrencyListService";
import {Calendar} from "primereact/calendar";
import moment from "moment";
import {addLocale} from "primereact/api";
import PartnerListService from "../../services/partner/PartnerListService";
import PartnercontactListService from "../../services/partnercontact/PartnercontactListService";
import OfferstatusListService from "../../services/offerstatus/OfferstatusListService";
import {InputMask} from "primereact/inputmask";
import CompanyListService from "../../services/company/CompanyListService";
import OfferShowService from "../../services/offer/OfferShowService";
import ProjecttypeListService from "../../services/projecttype/ProjecttypeListService";
import {InputNumber} from "primereact/inputnumber";
import ReactQuill from "react-quill";

const OfferFormComponent = (props) => {

  //form
  const [formData, setFormData] = useState({
    currency: "HUF",
    status: "DRAFT",
  });
  const [loading, setLoading] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  // const [offerStatuses, setOfferStatuses] = useState([]);

  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    data = {
      ...data,
      note: noteContent
    }
    setFormData({
      ...formData,
      data
    });
    if (props && props.initialValues) {
      OfferFormService(data, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          if (props.closeForm === false) {
            window.App.setState({
              rerenderOfferList: true,
            })
          } else {
            window.App.setState({
              rerenderOfferList: true,
              sidebarOfferView: false,
            })
          }
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      OfferFormService(data, 'post')
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          if (props.closeForm === false) {
            window.App.setState({
              rerenderOfferList: true,
            })

          } else {
            window.App.setState({
              rerenderOfferList: true,
              sidebarOfferCreate: false,
            })
          }
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // company autocomplete
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();

  const searchCompany = (event) => {
    CompanyListService(JSON.stringify({filters: {name: {value: event.query}}}))
      .then(response => {
        setCompanies(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // employees autocomplete
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState('');

  const searchEmployees = (event) => {
    EmployeeListService(JSON.stringify({filters: {name: {value: event.query}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          let _items = []
          Object.entries(response.data.items).map(function (item, index) {
            _items.push({
              name: item[1].name + " (" + item[1].companyName + ")",
              id: item[1].id
            })
          })
          setEmployees(_items)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // partner autocomplete
  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState('');

  const searchPartner = (event) => {
    setSelectedPartner(event.query.replace('Nincs találat', '').replace(': ', ''))
    PartnerListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setPartners(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedPartner(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // partner ontact autocomplete
  const [partnerContacts, setPartnerContacts] = useState([]);
  const [selectedPartnerContact, setSelectedPartnerContact] = useState('');

  const searchPartnerContact = (event) => {
    PartnercontactListService(JSON.stringify({filters: {name: {value: event.query}}}), formData.partner.id)
      .then(response => {
        setPartnerContacts(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // projectType autocomplete
  const [projectTypes, setProjectTypes] = useState([]);
  const [selectedProjectType, setSelectedProjectType] = useState('');

  const searchProjectType = (event) => {
    ProjecttypeListService(JSON.stringify({filters: {name: {value: event.query}}}))
      .then(response => {
        setProjectTypes(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  addLocale('hu', {
    firstDayOfWeek: 1,
    dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    dayNamesShort: ['vas', 'hé', 'ke', 'sze', 'csü', 'pé', 'szo'],
    dayNamesMin: ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'],
    monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember',
      'október', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'mar', 'ápr', 'máj', 'jun', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
    today: 'Ma',
    clear: 'Töröl'
  });

  //editor
  const quill = React.useRef(false)
  const [noteContent, setNoteContent] = useState(process.env.REACT_APP_OFFER_TEMPLATE);

  useEffect(() => {
    CurrencyListService()
      .then(response => {
        setCurrencies(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    // OfferstatusListService()
    //   .then(response => {
    //     setOfferStatuses(response.data)
    //   })
    //   .catch(error => {
    //     window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
    //   })
    if (props && props.initialValues) {
      OfferShowService(props.initialValues.id)
        .then(response => {
          setFormData(response.data)
          setNoteContent(response.data.note)
          setSelectedCompany(response.data.companyName)
          setSelectedEmployee(response.data.companyContactName)
          setSelectedPartner(response.data.partnerName)
          setSelectedPartnerContact(response.data.partnerContactName)
          setSelectedProjectType(response.data.projectTypeName)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })

    }
  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="name" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="name"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Ajánlat tárgya</label>
                  <span className="p-d-block">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="projectType" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="projectType"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Típus</label>
                  <span className="p-d-block">
                        <AutoComplete value={selectedProjectType}
                                      id={"projectType"}
                                      suggestions={projectTypes}
                                      forceSelection
                                      completeMethod={searchProjectType}
                                      field={"name"}
                                      delay="1000"
                                      placeholder={"Keresés gépeléssel..."}
                                      className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                      onSelect={(e) => {
                                        setFormData({
                                          ...formData,
                                          projectType: {id: e.value.id}
                                        })
                                      }}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          projectType: {id: null}
                                        })
                                        setSelectedProjectType(e.value)
                                      }}
                                      dropdown
                                      onClear={(e) => {
                                        setSelectedProjectType('')
                                      }}
                        />
                      </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="company" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="company"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Vállalkozás</label>
                  <span className="p-d-block">
                    <AutoComplete value={selectedCompany}
                                  id={"company"}
                                  suggestions={companies}
                                  forceSelection
                                  completeMethod={searchCompany}
                                  field={"name"}
                                  delay="1000"
                                  placeholder={"Keresés gépeléssel..."}
                                  className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                  onSelect={(e) => {
                                    setFormData({
                                      ...formData,
                                      company: {id: e.value.id}
                                    })
                                  }}
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      company: {id: null}
                                    })
                                    setSelectedCompany(e.value)
                                  }}
                                  dropdown
                                  onClear={(e) => {
                                    setSelectedCompany('')
                                  }}
                    />
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="companyContact" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="companyContact"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Vállalkozás kapcsolattartó</label>
                  <span className="p-d-block">
                              <AutoComplete value={selectedEmployee}
                                            id={"companyContact"}
                                            suggestions={employees}
                                            forceSelection
                                            completeMethod={searchEmployees}
                                            field={"name"}
                                            delay="1000"
                                            dropdown
                                            placeholder={"Keresés gépeléssel..."}
                                            className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                            onSelect={(e) => {
                                              setFormData({
                                                ...formData,
                                                companyContact: {id: e.value.id}
                                              })
                                            }}
                                            onChange={(e) => {
                                              setFormData({
                                                ...formData,
                                                companyContact: {id: null}
                                              })
                                              setSelectedEmployee(e.value)
                                            }}
                                            onClear={(e) => {
                                              setSelectedEmployee('')
                                            }}
                              />
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="partner" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="partner"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Partner</label>
                  <span className="p-d-block">
                    <AutoComplete value={selectedPartner}
                                  id={"partner"}
                                  forceSelection
                                  suggestions={partners}
                                  completeMethod={searchPartner}
                                  field={"name"}
                                  delay="1000"
                                  placeholder={"Keresés gépeléssel..."}
                                  className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                  onSelect={(e) => {
                                    setFormData({
                                      ...formData,
                                      partner: {id: e.value.id},
                                      partnerContact: null,
                                    })
                                    setSelectedPartnerContact(null)
                                  }}
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      partner: {id: null},
                                      partnerContact: null,
                                    })
                                    setSelectedPartner(e.value)
                                    setSelectedPartnerContact(null)
                                  }}
                                  dropdown
                                  onClear={(e) => {
                                    setSelectedPartner('')
                                    setSelectedPartnerContact(null)
                                  }}
                    />
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="partnerContact" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="partnerContact"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Partner kapcsolattartó</label>
                  <span className="p-d-block">
                                {formData && formData.partner && formData.partner.id ? (
                                  <AutoComplete value={selectedPartnerContact}
                                                id={"partnerContact"}
                                                suggestions={partnerContacts}
                                                completeMethod={searchPartnerContact}
                                                field={"name"}
                                                forceSelection
                                                delay="1000"
                                                placeholder={"Keresés gépeléssel..."}
                                                className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                                onSelect={(e) => {
                                                  setFormData({
                                                    ...formData,
                                                    partnerContact: {id: e.value.id},
                                                  })
                                                }}
                                                onChange={(e) => {
                                                  setFormData({
                                                    ...formData,
                                                    partnerContact: {id: null},
                                                  })
                                                  setSelectedPartnerContact(e.value)
                                                }}
                                                dropdown
                                                onClear={(e) => {
                                                  setSelectedPartnerContact('')
                                                }}
                                  />
                                ) : (
                                  <AutoComplete dropdown
                                                id={"partnerContact"}
                                                disabled
                                                placeholder={"Partner választása kötelező!"}
                                  />
                                )}
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            {/*<div className={"p-col-12 p-lg-4"}>*/}
            {/*  <Field name="status" render={({input, meta}) => (*/}
            {/*    <div className="p-field p-fluid">*/}
            {/*      <label htmlFor="status"*/}
            {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Státusz</label>*/}
            {/*      <span className={"p-d-block"}>*/}
            {/*        <Dropdown options={offerStatuses}*/}
            {/*                  optionLabel="name"*/}
            {/*                  optionValue="id"*/}
            {/*                  id={"status"}*/}
            {/*                  emptyMessage={"Még nincs státusz..."}*/}
            {/*                  onChange={(e) => {*/}
            {/*                    setFormData({*/}
            {/*                      ...formData,*/}
            {/*                      status: e.value*/}
            {/*                    })*/}
            {/*                  }}*/}
            {/*                  value={formData.status}*/}
            {/*        />*/}
            {/*      </span>*/}
            {/*      {getFormErrorMessage(meta)}*/}
            {/*    </div>*/}
            {/*  )}/>*/}
            {/*</div>*/}
            {/*<div className={"p-col-12 p-lg-4"}>*/}
            {/*  <Field name="netAmount" render={({input, meta}) => (*/}
            {/*    <div className="p-field p-fluid">*/}
            {/*      <label htmlFor="netAmount"*/}
            {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Nettó összesen</label>*/}
            {/*      <span className="p-d-block">*/}
            {/*                  <InputNumber id="netAmount" {...input}*/}
            {/*                               locale="hu-HU"*/}
            {/*                               minFractionDigits={0}*/}
            {/*                               maxFractionDigits={9}*/}
            {/*                               disabled*/}
            {/*                    // onValueChange={(e) => {*/}
            {/*                    //   setFormData({*/}
            {/*                    //     ...formData,*/}
            {/*                    //     netAmount: e.value*/}
            {/*                    //   })*/}
            {/*                    // }}*/}
            {/*                               value={formData.netAmount}*/}
            {/*                               className={classNames({'p-invalid': isFormFieldValid(meta)})}/>*/}
            {/*                </span>*/}
            {/*      {getFormErrorMessage(meta)}*/}
            {/*    </div>*/}
            {/*  )}/>*/}
            {/*</div>*/}
            <div className={"p-col-12 p-lg-6"}>
              <Field name="currency" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="currency"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Pénznem</label>
                  <span className="p-d-block">
                    <Dropdown options={currencies}
                              optionLabel="code"
                              optionValue="code"
                              id={"currency"}
                              emptyMessage={"Még nincs pénznem..."}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  currency: e.value
                                })
                              }}
                              value={formData.currency}
                    />
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="validUntil" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="validUntil"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Érvényesség vége</label>
                  <span className="p-d-block">
                    <InputMask id={"validUntil"}
                               mask={"9999-99-99"}
                               placeholder={"ÉÉÉÉ-HH-NN"}
                               value={formData.validUntil}
                               onChange={(e) => {
                                 if (e.value.indexOf("_") === -1) {
                                   if (e.value === '') {
                                     e.value = null
                                   }
                                   setFormData({
                                     ...formData,
                                     validUntil: e.value
                                   })
                                 }
                               }}
                    />
                      </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12"}>
              <Field name="note" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="note"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Megjegyzés</label>
                  <div id={"editor-container"}>
                    <ReactQuill value={noteContent}
                                id={"note"}
                                ref={quill}
                                onChange={(e) => {
                                  setNoteContent(e)
                                }}
                    />
                  </div>
                </div>
              )}/>
            </div>
          </div>

          <div className={"p-grid p-mt-1"}>
            <div className={"p-col-12 p-lg-6"}>
              {formData.id &&
              <Button type="button" label="Megtekintés" icon={"pi pi-eye"}
                      onClick={() => {
                        window.App.setState({
                          modeOfferView: 'show',
                        })
                      }}
                      className="p-button"/>
              }
            </div>
            <div className={"p-col-12 p-lg-6 p-text-right"}>
              <Button type="submit" label="Mentés és bezár" icon={"pi pi-check"} loading={loading}
                      onClick={() => {
                        props = {...props, closeForm: true}
                      }}
                      className="p-button-success"/>
              <Button type="submit" label="Mentés" icon={"pi pi-check"} loading={loading}
                      onClick={() => {
                        props = {...props, closeForm: false}
                      }}
                      className="p-button-success p-ml-2"/>


            </div>
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(OfferFormComponent);
