import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import moment from "moment";
import {Dialog} from "primereact/dialog";
import {classNames} from "primereact/utils";
import {AutoComplete} from "primereact/autocomplete";
import {FileUpload} from "primereact/fileupload";
import {TabView, TabPanel} from "primereact/tabview";
import {Field, Form} from "react-final-form";
import {Dropdown} from "primereact/dropdown";
import {InputMask} from "primereact/inputmask";

import EmployeeShowService from "../../services/employee/EmployeeShowService";
import IdentitydocumenttemplateListService from "../../services/identitydocumenttype/IdentitydocumenttypeListService";
import SafetyTypeListService from "../../services/safety/SafetyTypeListService";
import SafetyemployeeByemployeeListService from "../../services/safetyemployee/SafetyemployeeByemployeeService";
import IdentifierByEmployeeListService from "../../services/identifier/IdentifierByEmployeeListService";
import EmployeedocumentListService from "../../services/employeedocument/EmployeedocumentListService";
import EmployeedocumentAddService from "../../services/employeedocument/EmployeedocumentAddService";
import DocumenttemplateListService from "../../services/documenttemplate/DocumenttemplateListService";
import EmployeedocumentWildcardsService from "../../services/employeedocument/EmployeedocumentWildcardsService";
import EmployeedocumentGenerateService from "../../services/employeedocument/EmployeedocumentGenerateService";
import SafetyemployeeFormService from "../../services/safetyemployee/SafetyemployeeFormService";
import SafetyemployeeDeleteService from "../../services/safetyemployee/SafetyemployeeDeleteService";
import SafetyWithoutEducationListService from "../../services/safety/SafetyWithoutEducationListService";
import EmployeeFormComponent from "./EmployeeFormComponent";
import EmployeeShowComponent from "./EmployeeShowComponent";

const EmployeeViewComponent = (props) => {

  const [formData, setFormData] = useState({});
  const [identitydocumenttypes, setIdentitydocumenttypes] = useState({});
  const [safeties, setSafeties] = useState([]);
  const [identifiers, setIdentifiers] = useState([]);
  const [safetytypes, setSafetytypes] = useState([]);
  const [documentImgDialog, setDocumentImgDialog] = useState(false);
  const [documents, setDocuments] = useState([]);

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  const [wildcards, setWildcards] = useState([]);
  const [wildcardsAdded, setWildcardsAdded] = useState([]);
  const [wildcardsDialog, setWildcardsDialog] = useState(false);

  const identityDocumentType = (id) => {
    if (identitydocumenttypes && identitydocumenttypes[0]) {
      let identitydocumenttype = identitydocumenttypes.filter(item => item.id === id)
      if (identitydocumenttype && identitydocumenttype[0] && identitydocumenttype[0].name) {
        return identitydocumenttype[0].name
      }
    }
  }

  // safites
  const [safetyDialog, setSafetyDialog] = useState(false);
  const [safetyEditDialog, setSafetyEditDialog] = useState(false);
  const [safetyFormData, setSafetyFormData] = useState({}); // set on dialog open!
  const [safetiesLoading, setSafetiesLoading] = useState(false);
  const [occSafeties, setOccSafeties] = useState({});

  const safetyValidate = (data) => {
    let errors = {};
    // if (!data.name) {
    //   errors.name = 'Kötelező mező';
    // }
    // if (!data.validUntil) {
    //   errors.validUntil = 'Kötelező mező';
    // }
    // if (!data.occupationalSafety) {
    //   errors.occupationalSafety = 'Kötelező mező';
    // }
    return errors;
  };

  const safetyActionBody = (rowData, row) => {
    return (
      <React.Fragment>
        <Button type="button" icon="pi pi-pencil" className="  p-button-warning p-mr-2"
                onClick={() => {
                  setSafetyDialog(true)
                  setSafetyFormData({...rowData, rowIndex: row.rowIndex, method: 'put', employee: {id: window.App.state.props.id}})
                }}
        />
        <Button type="button" icon="pi pi-trash" className="  p-button-danger"
                onClick={() => {
                  if (window.confirm('A törlés megerősítésére van szükség!')) {
                    safetyDelete(rowData, row)
                  }
                }}
        />
      </React.Fragment>
    );
  }

  const safetyOnSubmit = (data, form) => {
    setSafetiesLoading(true)
    let method = 'post'
    if (safetyFormData.method === 'put') {
      method = 'put'
    }
    SafetyemployeeFormService(data, method, window.App.state.props.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres mentés!');
        SafetyemployeeByemployeeListService(window.App.state.props.id)
          .then(response => {
            setSafeties(response.data.items)
          })
          .catch(error => {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          })
        setSafetyFormData({})
        setSafetyDialog(false)
        setSafetiesLoading(false)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setSafetiesLoading(false)
      })
  }

  const safetyDelete = (data, row) => {
    SafetyemployeeDeleteService(data.id)
      .then(response => {
        SafetyemployeeByemployeeListService(props.initialValues.id)
          .then(response => {
            setSafeties(response.data.items)
          })
          .catch(error => {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          })
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  const safetyType = (id) => {
    if (safetytypes && safetytypes[0]) {
      let safetytype = safetytypes.filter(item => item.id === Number(id))
      if (safetytype && safetytype[0] && safetytype[0].name) {
        return safetytype[0].name
      }
    }
  }

  // document upload
  const documentUploader = useRef(null);
  const documentUpload = (files) => {
    let filesToUpload = []
    files.files.map(function (file, index) {
      var reader = new FileReader();
      let name = file.name
      reader.onloadend = () => {
        var base64data = reader.result;
        filesToUpload.push({
          name: name,
          originalFileName: name,
          file: base64data
        })
      }
      reader.readAsDataURL(file)
      if (files.files.length == index + 1) {
        setTimeout(function () {
          EmployeedocumentAddService(formData.id, filesToUpload)
            .then(response => {
              window.App.toastShow('success', 'Sikeres feltöltés!');
              //documentUploader.clear()
              EmployeedocumentListService('', props.initialValues.id)
                .then(response => {
                  setDocuments(response.data.items)
                })
                .catch(error => {
                  window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                })
            })
            .catch(error => {
              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
            })
        }, 500)
      }
    })
  }

  // documenttemplate autocomplete
  const [documenttemplates, setDocumenttemplates] = useState([]);
  const [selectedDocumenttemplate, setSelectedDocumenttemplate] = useState();
  const [selectedDocumenttemplateId, setSelectedDocumenttemplateId] = useState();

  const searchDocumenttemplate = (event) => {
    DocumenttemplateListService(JSON.stringify({
      filters: {
        name: {
          value: event.query
        }
      }
    }))
      .then(response => {
        setDocumenttemplates(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  useEffect(() => {
    if (localStorage.getItem('roles').toString().includes('role_employee_document_read')) {
      EmployeedocumentListService('', props.initialValues.id)
        .then(response => {
          setDocuments(response.data.items)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }
    SafetyWithoutEducationListService('', props.initialValues.id)
      .then(response => {
        let _occSafeties = []
        Object.entries(response.data.items).map(function (safety, index) {
          _occSafeties.push({
            name: safety[1].name + " - " + safety[1].workTypeName,
            id: safety[1].id
          })
        })
        setOccSafeties(_occSafeties)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    SafetyTypeListService('')
      .then(response => {
        setSafetytypes(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    EmployeeShowService(props.initialValues.id)
      .then(response => {
        setFormData(response.data)
        SafetyemployeeByemployeeListService(props.initialValues.id)
          .then(response => {
            setSafeties(response.data.items)
          })
          .catch(error => {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          })
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    IdentitydocumenttemplateListService('')
      .then(response => {
        setIdentitydocumenttypes(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    IdentifierByEmployeeListService('', props.initialValues.id)
      .then(response => {
        setIdentifiers(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [window.App.state.rerenderEmployeeView === true])

  return (
    <>
      <h2 className={"text-primary p-mb-0"}>{formData.name}</h2>
      <div className={"p-grid"}>
        {formData.companyName &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            Cég
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            <>{formData.companyName}</>
          </p>
        </div>
        }
        {formData.email &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            Email
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            <>{formData.email}</>
          </p>
        </div>
        }
        {formData.birthDay &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            Született
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            <>{moment.utc(formData.birthDay).local().format('YYYY-MM-DD')}</>
          </p>
        </div>
        }
        {/*{localStorage.getItem('roles').toString().includes('role_employee_write') &&*/}
        {/*<div className={"p-col p-text-right"}>*/}
        {/*  <Button type="button" label="Módosítás" icon="pi pi-pencil" className="p-button-warning"*/}
        {/*          onClick={() => {*/}
        {/*            window.App.setState({*/}
        {/*              sidebarEmployeeShow: false,*/}
        {/*              sidebarEmployeeUpdate: true,*/}
        {/*              props: formData*/}
        {/*            })*/}
        {/*          }}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*}*/}
      </div>
      <hr/>
      <TabView className="tabview-header-icon menu" renderActiveOnly="true"
               activeIndex={window.App.state.activeindexEmloyeeView}>
        <TabPanel header="Alap adatok" leftIcon="pi pi-info-circle">
          <Panel>
            {window.App.state.modeEmployeeView === 'show' &&
            <>
              <EmployeeShowComponent initialValues={window.App.state.props}/>
            </>
            }
            {window.App.state.modeEmployeeView === 'edit' &&
            <>
              <EmployeeFormComponent initialValues={window.App.state.props}/>
            </>
            }
          </Panel>
        </TabPanel>
        {localStorage.getItem('roles').toString().includes('role_occ_safety_emp_read') &&
        <TabPanel header="Munkavédelmi bejegyzések" leftIcon="pi pi-list">
          <Panel>
            <DataTable size={localStorage.getItem('scale')} value={safeties}
                       emptyMessage="Nincs találat."
                       autoLayout={true}
                       filterDisplay="row"
                       responsiveLayout="scroll"
                       stripedRows
            >
              <Column body={safetyActionBody} className={"p-py-0 p-text-nowrap"}></Column>
              <Column field="occupationalSafetyName" header="Munkavédelmi előírás"></Column>
              <Column field="validUntil" header="Érvényes" body={(rowData) => {
                if (rowData.validUntil) {
                  return (
                    <>{moment.utc(rowData.validUntil).local().format('YYYY-MM-DD')}</>
                  )
                } else {
                  return (
                    <>Korlátlan</>
                  )
                }
              }}></Column>
              <Column header="Típus" body={(rowData) => {
                return (
                  <>{safetyType(rowData.type)}</>
                )
              }}></Column>
            </DataTable>
            <div className={"p-grid p-mt-1"}>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-col-align-center"}>

              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                {localStorage.getItem('roles').toString().includes('role_occ_safety_emp_write') &&
                <Button type="button" label="Új bejegyzés hozzáadása" icon="pi pi-plus" className="p-button-success "
                        onClick={() => {
                          setSafetyDialog(true)
                          setSafetyFormData({
                            method: 'post',
                            employee: {id: window.App.state.props.id}
                          })
                        }}
                />
                }
                <Dialog visible={safetyDialog}
                        style={{width: '750px'}}
                        header="Munkavédelmi bejegyzés"
                        modal
                        onHide={() => {
                          setSafetyDialog(false)
                        }}>
                  <Form onSubmit={safetyOnSubmit} initialValues={safetyFormData}
                        validate={safetyValidate} render={({handleSubmit, submitting}) => (
                    <form onSubmit={handleSubmit} className="">
                      <div className={"p-grid"}>
                        {/*<div className={"p-col-12 p-md-12 p-lg-12"}>*/}
                        {/*  <Field name="name" render={({input, meta}) => (*/}
                        {/*    <div className="p-field p-fluid">*/}
                        {/*      <label htmlFor="name"*/}
                        {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Megnevezés</label>*/}
                        {/*      <span className={"p-d-block"}>*/}
                        {/*        <InputText id="name" {...input}*/}
                        {/*                   onChange={(e) => {*/}
                        {/*                     setSafetyFormData({*/}
                        {/*                       ...safetyFormData,*/}
                        {/*                       name: e.target.value*/}
                        {/*                     })*/}
                        {/*                   }}*/}
                        {/*                   value={safetyFormData.name}*/}
                        {/*                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>*/}
                        {/*      </span>*/}
                        {/*      {getFormErrorMessage(meta)}*/}
                        {/*    </div>*/}
                        {/*  )}/>*/}
                        {/*</div>*/}
                        <div className={"p-col-12 p-md-6 p-lg-6"}>
                          <Field name="occupationalSafety" render={({input, meta}) => (
                            <div className="p-field p-fluid">
                              <label htmlFor="occupationalSafety"
                                     className={classNames({'p-error': isFormFieldValid(meta)})}>Munkavédelmi
                                előírás</label>
                              <span className={"p-d-block"}>
                      {safetyFormData.occupationalSafety && safetyFormData.occupationalSafety.id ? (
                        <Dropdown options={occSafeties}
                                  optionLabel="name"
                                  optionValue="id"
                                  id={"occupationalSafety"}
                                  emptyMessage={"Még nincs előírás..."}
                                  onChange={(e) => {
                                    setSafetyFormData({
                                      ...safetyFormData,
                                      occupationalSafety: {id: e.value}
                                    })
                                  }}
                                  value={Number(safetyFormData.occupationalSafety.id)}
                        />
                      ) : (
                        <Dropdown options={occSafeties}
                                  optionLabel="name"
                                  optionValue="id"
                                  id={"occupationalSafety"}
                                  emptyMessage={"Még nincs előírás..."}
                                  onChange={(e) => {
                                    setSafetyFormData({
                                      ...safetyFormData,
                                      occupationalSafety: {id: e.value}
                                    })
                                  }}
                        />
                      )}
                    </span>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}/>
                        </div>
                        <div className={"p-col-12 p-md-6 p-lg-6"}>
                          <Field name="validUntil" render={({input, meta}) => (
                            <div className="p-field p-fluid">
                              <label htmlFor="validUntil"
                                     className={classNames({'p-error': isFormFieldValid(meta)})}>Érvényesség
                                vége</label>
                              <span className={"p-d-block"}>
                                <InputMask id={"validUntil"}
                                           mask={"9999-99-99"}
                                           value={safetyFormData.validUntil}
                                           onChange={(e) => {
                                             if (e.value.indexOf("_") === -1) {
                                               if (e.value === '') {
                                                 e.value = null
                                               }
                                               setSafetyFormData({
                                                 ...safetyFormData,
                                                 validUntil: e.value
                                               })
                                             }
                                           }}
                                />
                      </span>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}/>
                        </div>
                      </div>
                      <div className={"p-grid p-mt-1"}>
                        <div className={"p-col-12 p-lg-6 p-xl-6"}>

                        </div>
                        <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                          <Button type="submit" label="Mentés" icon="pi pi-check"
                                  loading={safetiesLoading}/>
                        </div>
                      </div>
                    </form>
                  )}/>
                </Dialog>
              </div>
            </div>
          </Panel>
        </TabPanel>
        }
        {localStorage.getItem('roles').toString().includes('role_employee_document_read') ? (
          <TabPanel header="Dokumentumok" leftIcon="pi pi-list">
            <Panel>
              <DataTable size={localStorage.getItem('scale')} value={documents}
                         emptyMessage="Nincs találat."
                         autoLayout={true}
                         filterDisplay="row"
                         responsiveLayout="scroll"
                         stripedRows
              >
                <Column field="name" header="Megnevezés"></Column>
                <Column field="note" header="Megjegyzés"></Column>
                <Column field="validUntil" header="Érvényes" body={(rowData) => {
                  if (rowData.validUntil) {
                    return (
                      <>{moment.utc(rowData.validUntil).local().format('YYYY-MM-DD')}</>
                    )
                  } else {
                    return (
                      <>Korlátlan</>
                    )
                  }
                }}></Column>
                <Column field="filePath" header="Fájl" body={(rowData) => {
                  return (
                    <>
                      <a
                        href={process.env.REACT_APP_API_HOST + "/employeeDocument/" + formData.id + "/download/" + rowData.id
                        + "?token=" + localStorage.getItem('userToken') + "."
                        + localStorage.getItem("networkId")}
                        target="_blank"
                      >
                        <i className={"pi pi-file"}></i> {rowData.originalFileName}
                      </a>
                      {/*<img*/}
                      {/*  src={process.env.REACT_APP_API_HOST + "/employeeDocument/" + formData.id + "/download/" + rowData.id*/}
                      {/*  + "?token=" + localStorage.getItem('userToken') + "."*/}
                      {/*  + localStorage.getItem("networkId") + '&date=' + new Date()} className={"w-100"}*/}
                      {/*  onClick={() => {*/}
                      {/*    setDocumentImgDialog(true)*/}
                      {/*    window.App.setState({*/}
                      {/*      props: {*/}
                      {/*        employeeId: formData.id,*/}
                      {/*        imageId: rowData.id*/}
                      {/*      }*/}
                      {/*    })*/}
                      {/*  }}*/}
                      {/*  style={{width: '80px'}}*/}
                      {/*/>*/}
                      {/*<Dialog visible={documentImgDialog}*/}
                      {/*        style={{width: '750px'}}*/}
                      {/*        header="Dokumentum megtekintése"*/}
                      {/*        modal*/}
                      {/*        onHide={() => {*/}
                      {/*          setDocumentImgDialog(false)*/}
                      {/*        }}>*/}
                      {/*  <img src={process.env.REACT_APP_API_HOST + "/employeeDocument/" + window.App.state.props.employeeId*/}
                      {/*  + "/download/" + window.App.state.props.imageId*/}
                      {/*  + "?token=" + localStorage.getItem("userToken") + "." + localStorage.getItem('networkId')}*/}
                      {/*       style={{'width': '700px', 'height': 'auto'}}/>*/}
                      {/*</Dialog>*/}
                    </>
                  )
                }}></Column>
              </DataTable>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-6"}>
                  {localStorage.getItem('roles').toString().includes('role_employee_document_write') &&
                  <>
                    <h4>
                      <i className={"pi pi-upload"}></i> Felöltés
                    </h4>
                    <FileUpload //mode="basic"
                      name="file"
                      id={"file"}
                      //auto="true"
                      multiple
                      ref={documentUploader}
                      customUpload
                      uploadHandler={documentUpload}
                      chooseLabel={"Fájlok"}
                      uploadLabel={"Feltöltés"}
                      cancelLabel={"Mégse"}
                    />
                  </>
                  }
                </div>
                <div className={"p-col-12 p-lg-6"}>
                  {localStorage.getItem('roles').toString().includes('role_employee_document_generate') &&
                  <>
                    <h4>
                      <i className={"pi pi-file"}></i> Generálás sablonból
                    </h4>
                    <AutoComplete value={selectedDocumenttemplate}
                                  id={"type"}
                                  suggestions={documenttemplates}
                                  forceSelection
                                  completeMethod={searchDocumenttemplate}
                                  field={"name"}
                                  delay="1000"
                                  className={"w-100"}
                                  placeholder={"Keresés gépeléssel..."}
                                  onSelect={(e) => {
                                    EmployeedocumentWildcardsService(formData.id, e.value.id)
                                      .then(response => {
                                        if (response && response.data && response.data && response.data.extrafields && response.data.extrafields[0]) {
                                          setWildcards(response.data.extrafields)
                                          setWildcardsDialog(true)
                                        }
                                      })
                                      .catch(error => {
                                        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                      })
                                    setSelectedDocumenttemplateId(e.value.id)
                                  }}
                                  onChange={(e) => {
                                    setSelectedDocumenttemplate(e.value)
                                    setSelectedDocumenttemplateId(null)
                                  }}
                                  dropdown
                                  onClear={(e) => {
                                    setSelectedDocumenttemplate('')
                                  }}
                    />
                    <Dialog header="Változók meghatározása" visible={wildcardsDialog} style={{width: '480px'}} modal
                            onHide={() => {
                              setWildcardsDialog(false)
                            }}>
                      <div className="grid p-fluid">
                        {wildcards && wildcards[0] &&
                        <>
                          {wildcards.map((wildcard, index) => {
                            return (
                              <>
                                <div className={"p-col-12"}>
                                  <div className="p-field p-fluid">
                                    <label>{wildcard.label}</label>
                                    <span className="p-inputgroup">
                              {wildcard.type === 'date' &&
                              <>
                                <InputMask id={"identityDocumentValidUntil"}
                                           mask={"9999-99-99"}
                                           onChange={(e) => {
                                             if (e.value.indexOf("_") === -1) {
                                               if (e.value === '') {
                                                 e.value = null
                                               }
                                               setWildcardsAdded([
                                                 ...wildcardsAdded,
                                                 {
                                                   key: wildcard.key,
                                                   value: e.value
                                                 }
                                               ])
                                             }
                                           }}
                                />
                              </>
                              }
                            </span>
                                  </div>
                                </div>
                              </>
                            )
                          })}
                        </>
                        }
                      </div>
                      <div className={"p-grid p-mt-1"}>
                        <div className={"p-col-12 p-lg-6 p-xl-6"}>
                        </div>
                        <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                          <Button type="button" label="Mentés" icon="pi pi-check"
                                  onClick={() => {
                                    EmployeedocumentGenerateService(formData.id, selectedDocumenttemplateId, wildcardsAdded)
                                      .then(response => {
                                        window.App.toastShow('success', 'Sikeres generálás!');
                                        setWildcardsDialog(false)
                                        EmployeedocumentListService('', props.initialValues.id)
                                          .then(response => {
                                            setDocuments(response.data.items)
                                          })
                                          .catch(error => {
                                            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                          })
                                      })
                                      .catch(error => {
                                        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                      })

                                  }}
                          />
                        </div>
                      </div>
                    </Dialog>
                  </>
                  }
                </div>
              </div>
            </Panel>
          </TabPanel>
        ) : (
          <TabPanel disabled/>
        )}
        <TabPanel header="Azonosítók" leftIcon="pi pi-list">
          {props.initialValues.readOnly !== true &&
          <Panel>
            <div className={"p-grid"}>
              {identifiers && identifiers[0] ? (
                [identifiers.map((identifier, index) => {
                  return (
                    <>
                      <div className={"p-col p-fluid p-text-center"} style={{maxWidth: '200px'}}>
                        <img src={process.env.REACT_APP_API_HOST + "/identifier/qr/" + identifier.id
                        + "/?token=" + localStorage.getItem("userToken")
                        } className={"w-100"}/>
                        {identifier.serial ? (
                          <h3>{identifier.serial}</h3>
                        ) : (
                          <h3>{identifier.identifier}</h3>
                        )}
                      </div>
                    </>
                  )
                })]
              ) : (
                <div className={"p-col-12"}>
                  Még nincs hozzárendelve azonosító vagy nincs jogosultság az azonosító megtekintéséhez.
                </div>
              )}
            </div>
          </Panel>
          }
        </TabPanel>
      </TabView>
    </>
  )
}
export default withTranslation('common')(EmployeeViewComponent);
