import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {FileUpload} from "primereact/fileupload";
import {Message} from "primereact/message";
import ImportqueueListComponent from "../importqueue/ImportqueueListComponent";
import PurchaseorderrequestImportService from "../../services/purchaseorderrequest/PurchaseorderrequestImportService";

const PurchaseorderrequestImportComponent = (props) => {

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    PurchaseorderrequestImportService(formData, props.initialValues.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres importálás!', response.data.message);
        setFormData({...formData, xls: null})
        window.App.setState({
          //rerenderPurchaseorderrequestList: true,
          //sidebarPurchaseorderrequestImport: false,
          rerenderImportqueueList: true,
        })
        setLoading(false)
      })
      .catch(function (error) {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setLoading(false)
      })
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  const fileUpload = (files) => {
    var reader = new FileReader();
    let name = files.files[0].name
    reader.onloadend = () => {
      let base64data = reader.result;
      setFormData({
        ...formData,
        file: base64data.toString().replace(/^data:(.*,)?/, ''),
        originalFileName: name
      })
    }
    reader.readAsDataURL(files.files[0])
  }

  useEffect(() => {

  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">

        <Panel className={""}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-12 p-xl-12  p-col-align-center"}>
              <h3>
                <i className={"pi pi-download"}></i> Fájl letöltése
              </h3>
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-md-6 p-lg-6"}>
              Kérjük töltse ki a mellékelt minta XLS fájlt.
            </div>
            <div className={"p-col-12 p-md-6 p-lg-6 p-text-right"}>
              <a href="/download/beszerzesi_megrendeles_igeny_import_minta.xlsx"
                 download
                 target="_blank"
                 className={"p-button p-text-light"}>
                <i className={"pi pi-download"}></i>&nbsp;Minta XLS letöltése
              </a>
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-12 p-xl-12  p-col-align-center"}>
              <h3>
                <i className={"pi pi-upload"}></i> Fájl feltöltése
              </h3>
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-12 p-xl-12"}>
              <Field name="file" render={({input, meta}) => (
                <div className="p-field">
                  {!formData.file ? (
                    <>
                      <label htmlFor="image"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>XLS kiválasztása</label>
                      <span className="">
                         <FileUpload mode="basic"
                                     name="file"
                                     id={"file"}
                                     accept=""
                                     auto="true"
                                     customUpload
                                     uploadHandler={fileUpload}
                                     chooseLabel={"Fájl kiválasztása"}
                                     uploadLabel={"Feltöltés"}
                         />
                      </span>
                    </>
                  ) : (
                    <>
                      <label htmlFor="image"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Feltöltött XLS
                        törlése</label>
                      <span className="p-d-block">
                        <div className={"p-grid"}>
                          <div className={"p-col-12 p-lg-6 p-xl-6"}>
                            <p className={"p-m-0 p-pt-2"}>
                              <i className={"pi pi-file"}></i> {formData.originalFileName}
                            </p>
                          </div>
                          <div className={"p-col-12 p-lg-6 p-xl-6"}>
                            <Button type="button"
                                    label={"Törlés"}
                                    icon="pi pi-trash"
                                    className="p-button p-button-danger"
                                    onClick={() => setFormData({...formData, file: null})}
                            />
                          </div>
                        </div>
                      </span>
                    </>
                  )}
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12"}>
              <Message severity="warn" text="
                A termék import csak a letölthető mintának megfelelő formátumú xlsx fájlokkal használható.
              "></Message>
            </div>
          </div>
          <div className={"p-grid p-mt-1"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              {formData.file ? (
                <Button type="submit" label="Import indítása" icon={"pi pi-check"} loading={loading}
                        className="p-button-success"/>
              ) : (
                <Button type="button" label="Import indítása"  icon={"pi pi-check"}
                        className="p-button-success"
                        onClick={() => {
                          alert('A feltöltendő fájl XML formátum kell legyen!')
                        }}
                />
              )}
            </div>
          </div>
        </Panel>
        <Panel className={"p-mt-3"}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-12 p-xl-12 p-col-align-center"}>
              <h3>
                <i className={"pi pi-upload"}></i> Import folyamatok
              </h3>
            </div>
          </div>
          <ImportqueueListComponent />
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(PurchaseorderrequestImportComponent);
