import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {PickList} from 'primereact/picklist';
import {Panel} from "primereact/panel";
import {Dropdown} from "primereact/dropdown";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {AutoComplete} from "primereact/autocomplete";
import {InputNumber} from "primereact/inputnumber";
import {FileUpload} from "primereact/fileupload";

import PurchaseorderRequestItemsAddedService from "../../services/purchaseorder/PurchaseorderRequestItemsAddedService";
import PurchaseorderRequestItemsUpdateService
  from "../../services/purchaseorder/PurchaseorderRequestItemsUpdateService";
import PurchaseorderTerplateDownloadService from "../../services/purchaseorder/PurchaseorderTemplateDownloadService";
import {Dialog} from "primereact/dialog";
import PurchaseorderOfferUploadService from "../../services/purchaseorder/PurchaseorderOfferUploadService";
import PartnerListService from "../../services/partner/PartnerListService";
import download from "js-file-download";
import moment from "moment";
import VatListService from "../../services/vat/VatListService";
import PurchaseorderStatusChangeOrderedService
  from "../../services/purchaseorder/PurchaseorderStatusChangeOrderedService";
import PurchaseorderShowService from "../../services/purchaseorder/PurchaseorderShowService";

const PurchaseorderPricingComponent = (props) => {

  const {t} = props;
  const [file, setFile] = useState(false);
  const [fileUploadDialog, setFileUploadDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [defaultPartner, setDefaultPartner] = useState([]);
  const [vats, setVats] = useState([]);

  const [requestItemsSource, setRequestItemsSource] = useState([]);
  const [loadingRequestItemsSource, setLoadingRequestItemsSource] = useState(false);
  const [totalRecordsRequestItemsSource, setTotalRecordsRequestItemsSource] = useState(0);
  const [rowsRequestItemsSource, setRowsRequestItemsSource] = useState(10);
  const [lazyParamsRequestItemsSource, setLazyParamsRequestItemsSource] = useState({
    first: 0,
    rows: rowsRequestItemsSource,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeoutRequestItemsSource = null;
  const loadLazyDataRequestItemsSource = (orderId) => {
    setLoadingRequestItemsSource(true);
    if (loadLazyTimeoutRequestItemsSource) {
      clearTimeout(loadLazyTimeoutRequestItemsSource);
    }
    loadLazyTimeoutRequestItemsSource = setTimeout(() => {
      PurchaseorderRequestItemsAddedService(orderId, JSON.stringify(lazyParamsRequestItemsSource))
        .then(response => {
          setTotalRecordsRequestItemsSource(response.data.total_item_count);
          setRequestItemsSource(response.data.items);
          setLoadingRequestItemsSource(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPageRequestItemsSource = (event) => {
    let _lazyParams = {...lazyParamsRequestItemsSource, ...event};
    setLazyParamsRequestItemsSource(_lazyParams);
  }
  const onSortRequestItemsSource = (event) => {
    let _lazyParams = {...lazyParamsRequestItemsSource, ...event};
    setLazyParamsRequestItemsSource(_lazyParams);
  }
  const onFilterRequestItemsSource = (event) => {
    let _lazyParams = {...lazyParamsRequestItemsSource, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParamsRequestItemsSource(_lazyParams);
  }
  const pagerRequestItemsSource = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: Number(process.env.REACT_APP_LIST_ROW_OP1), value: Number(process.env.REACT_APP_LIST_ROW_OP1)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP2), value: Number(process.env.REACT_APP_LIST_ROW_OP2)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP3), value: Number(process.env.REACT_APP_LIST_ROW_OP3)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP4), value: Number(process.env.REACT_APP_LIST_ROW_OP4)},
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions}
                    onChange={(e) => setRowsRequestItemsSource(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  const fileUpload = (files) => {
    var reader = new FileReader();
    reader.onloadend = () => {
      let base64data = reader.result;
      setFile(base64data.toString().replace(/^data:(.*,)?/, ''))
    }
    reader.readAsDataURL(files.files[0])
  }

  // partner autocomplete
  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState('');

  const searchPartner = (event) => {
    PartnerListService(JSON.stringify({filters: {name: {value: event.query}}}))
      .then(response => {
        setPartners(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  useEffect(() => {
    PartnerListService()
      .then(response => {
        setPartners(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    VatListService()
      .then(response => {
        setVats(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    if (props && props.initialValues) {
      loadLazyDataRequestItemsSource(props.initialValues.id);
    }
  }, [lazyParamsRequestItemsSource, window.App.state.rerenderPurchaseorderPricingList === true])

  return (
    <Panel>
      <DataTable size={localStorage.getItem('scale')}
        emptyMessage="Nincs találat."
        value={requestItemsSource} paginator first={lazyParamsRequestItemsSource.first}
        totalRecords={totalRecordsRequestItemsSource} onPage={onPageRequestItemsSource}
        onSort={onSortRequestItemsSource} sortField={lazyParamsRequestItemsSource.sortField}
        sortOrder={lazyParamsRequestItemsSource.sortOrder}
        onFilter={onFilterRequestItemsSource} filters={lazyParamsRequestItemsSource.filters}
        loading={loadingRequestItemsSource} lazy
        paginatorTemplate={pagerRequestItemsSource} rows={rowsRequestItemsSource}
        emptyMessage="Nincs találat."
        autoLayout={true}
        filterDisplay="row"
        responsiveLayout="scroll"
        stripedRows
      >
        <Column field="projectName" header={t('project')} sortable filter filterPlaceholder={t('project')}></Column>
        <Column field="productName" header="Termék" sortable filter filterPlaceholder="Termék"></Column>
        <Column field="articleNumber" header="Cikkszám" sortable filter filterPlaceholder="Cikkszám"></Column>
        <Column field="quantity" header="Mennyiség" sortable
                className={"p-text-right"} alignHeader="right"></Column>
        <Column field="unitName" header="M.e." sortable className={"p-text-right"}></Column>
        {props.initialValues.status === 'draft' || props.initialValues.status === 'tender' ? (
          <Column header="Beszállító" body={(rowData, row) => {
            return (
              <>
                <AutoComplete value={rowData.partnerName}
                              id={"partner" + rowData.id}
                              suggestions={partners}
                              completeMethod={searchPartner}
                              field={"name"}
                              delay="1000"
                              placeholder={"Keresés gépeléssel..."}
                              forceSelection
                              onSelect={(e) => {
                                let partner = partners.filter((item => item.id == e.value.id))
                                let _items = requestItemsSource
                                let itemIndex = _items.findIndex((item => item.id == rowData.id))
                                _items[itemIndex].partner = {id: e.value.id}
                                _items[itemIndex].partnerName = partner[0].name
                                setRequestItemsSource(_items)
                                window.App.forceUpdate()
                              }}
                              dropdown
                />
              </>
            )
          }}></Column>
        ) : (
          <Column header="Beszállító" field="partnerName"></Column>
        )}
        {props.initialValues.status === 'draft' || props.initialValues.status === 'tender' ? (
          <Column header="Nettó ajánlati egységár" body={(rowData) => {
            return (
              <>
                <InputNumber value={rowData.unitPrice}
                             id={"unitPrice" + rowData.id}
                             locale="hu-HU"
                             minFractionDigits={0}
                             maxFractionDigits={9}
                             onChange={(e) => {
                               let _items = requestItemsSource
                               let itemIndex = _items.findIndex((item => item.id == rowData.id))
                               _items[itemIndex].unitPrice = e.value
                               setRequestItemsSource(_items)
                             }}
                />
              </>
            )
          }}></Column>
        ) : (
          <Column header="Nettó ajánlati egységár" field="unitPrice"></Column>
        )}
        {props.initialValues.status === 'draft' || props.initialValues.status === 'tender' ? (
          <Column header="ÁFA" body={(rowData) => {
            return (
              <>
                <Dropdown
                  onChange={(e) => {
                    let _items = requestItemsSource
                    let itemIndex = _items.findIndex((item => item.id == rowData.id))
                    _items[itemIndex].vat = e.value
                    setRequestItemsSource(_items)
                    window.App.forceUpdate()
                  }}
                  value={rowData.vat}
                  options={vats}
                  optionLabel="name"
                  optionValue="id"
                  id={"vat" + rowData.id}
                  emptyMessage={"Még nincs ÁFA..."}
                  className={"w-100"}
                />
              </>
            )
          }}></Column>
        ) : (
          <Column header="Nettó ajánlati egységár" field="unitPrice"></Column>
        )}
      </DataTable>
      <div className={"p-grid p-mt-1"}>
        {props.initialValues.status === 'draft' || props.initialValues.status === 'tender' ? (
          <>
            <div className={"p-col p-col-align-center"}>
              Beszállító beállítása összes tételhez: &nbsp;
              <Dropdown options={partners}
                        optionLabel="name"
                        optionValue="id"
                        id={"defaultPartner"}
                        emptyMessage={"Még nincs partner..."}
                        value={defaultPartner}
                        onChange={(e) => {
                          if (window.confirm('Figyelem! Az összes tétel beszállítóját felülírja?')) {
                            let _items = []
                            Object.entries(requestItemsSource).map(function (item, index) {
                              _items.push({
                                ...item[1],
                                partner: {
                                  id: e.value
                                }
                              })
                            })
                            setRequestItemsSource(_items)
                            setDefaultPartner(e.value)
                            PurchaseorderRequestItemsUpdateService(_items, props.initialValues.id)
                              .then(response => {
                                PurchaseorderRequestItemsAddedService(props.initialValues.id, JSON.stringify(lazyParamsRequestItemsSource))
                                  .then(response => {
                                    setTotalRecordsRequestItemsSource(response.data.total_item_count);
                                    setRequestItemsSource(response.data.items);
                                    setLoadingRequestItemsSource(false);
                                  })
                                  .catch(error => {
                                    window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                  })
                              })
                              .catch(error => {
                                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                              })
                          }
                          window.App.setState({rerenderPurchaseorderPricingList: true})
                        }}
                        value={defaultPartner}
              />
            </div>
          </>
        ) : (
          <div className={"p-col-4 p-text-right p-col-align-center"}>

          </div>
        )}
        <div className={"p-col-12 p-lg-8 p-xl-8 p-text-right"}>
          {props.initialValues.status !== 'arrived' &&
          <>
            <Button type="button" label="Ajánlat feltöltése" icon={"pi pi-upload"}
                    onClick={() => {
                      setFileUploadDialog(true)
                    }}
                    className="p-button-secondary p-ml-2"/>
            <Dialog visible={fileUploadDialog}
                    style={{width: '350px'}}
                    header="File feltöltése"
                    modal
                    onHide={() => {
                      setFileUploadDialog(false)
                    }}>
              <div className={"p-grid"}>
                <div className={"p-col"}>
                  <div className="p-field">
                    {!file ? (
                      <>
                        <label htmlFor="image">XLS kiválasztása</label>
                        <span className="">
                               <FileUpload mode="basic"
                                           name="file"
                                           id={"file"}
                                           accept=""
                                           auto="true"
                                           maxFileSize={1000000}
                                           customUpload
                                           uploadHandler={fileUpload}
                                           chooseLabel={"Fájl kiválasztása"}
                                           uploadLabel={"Feltöltés"}
                               />
                            </span>
                      </>
                    ) : (
                      <>
                        <label htmlFor="image">Feltöltött XLS
                          törlése</label>
                        <span className="p-d-block">
                              <Button type="button"
                                      className="p-button p-button-danger"
                                      onClick={() => setFile(null)}
                              >Törlés</Button>
                            </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className={"p-grid p-mt-1"}>
                <div className={"p-col-12 p-lg-4 p-xl-4"}>

                </div>
                <div className={"p-col-12 p-lg-8 p-xl-8 p-text-right"}>
                  <Button type="submit" label="Feltöltés" icon={"pi pi-check"}
                          onClick={() => {
                            PurchaseorderOfferUploadService(file)
                              .then(response => {
                                window.App.toastShow('success', 'Sikeres feltöltés!');
                                window.App.setState({
                                  rerenderPurchaseorderList: true,
                                  sidebarPurchaseorderPricing: false,
                                })
                              })
                              .catch(error => {
                                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                              })
                          }}
                          className="p-button-success p-ml-2"/>
                </div>
              </div>
            </Dialog>
            <Button type="button" label="Megrendelés sablon letöltése" icon={"pi pi-download"}
                    onClick={() => {
                      PurchaseorderTerplateDownloadService(props.initialValues.id)
                        .then(response => {
                          window.App.toastShow('success', 'Sikeres letöltés!');
                          download(response.data, localStorage.getItem('networkName')
                            + '_'
                            + 'megrendeles_sablon'
                            + '_'
                            + props.initialValues.id
                            + '_'
                            + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
                            + '.xlsx')
                        })
                        .catch(error => {
                          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                        })
                    }}
                    className="p-button-secondary p-ml-2"/>
            {props.initialValues.status === 'tender' &&
            <Button type="button" label="Megrendelve" className="p-button-warning p-ml-2" icon={"pi pi-check"}
                    onClick={() => {
                      PurchaseorderStatusChangeOrderedService(props.initialValues.id)
                        .then(response => {
                          window.App.toastShow('success', 'Sekeres művelet!', 'A megrendelés megrendelve.');
                          PurchaseorderShowService(props.initialValues.id)
                            .then(response => {
                              window.App.setState({
                                props: response.data,
                                rerenderPurchaseorderShow: true
                              })
                            })
                            .catch(error => {
                              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                            })
                        })
                        .catch(error => {
                          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                        })
                    }}
            />
            }
            <Button type="submit" label="Mentés" icon={"pi pi-check"}
                    onClick={() => {
                      PurchaseorderRequestItemsUpdateService(requestItemsSource, props.initialValues.id)
                        .then(response => {
                          window.App.toastShow('success', 'Sikeres mentés!');
                        })
                        .catch(error => {
                          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                        })
                    }}
                    className="p-button-success p-ml-2"/>
          </>
          }
        </div>
      </div>
    </Panel>
  )
}
export default withTranslation('common')(PurchaseorderPricingComponent);
