import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import axios from "axios";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Dropdown} from "primereact/dropdown";
import {InputMask} from "primereact/inputmask";
import moment from "moment";
import {Panel} from "primereact/panel";
import currencyFormatter from "currency-formatter";

const ManufacturingemployeeShowComponent = (props) => {

  const [summary, setSummary] = useState([]);
  const [dateFromFilterSelectedFrom, setDateFromFilterSelectedFrom] = useState('');
  const [dateFromFilterSelectedUntil, setDateFromFilterSelectedUntil] = useState('');
  const [totEstimatedTime, setTotEstimatedTime] = useState(0);
  const [totMainTime, setTotMainTime] = useState(0);
  const [totAuxiliaryTime, setTotAuxiliaryTime] = useState(0);
  const [totProducedTime, setTotProducedTime] = useState(0);

  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(Number(process.env.REACT_APP_LIST_ROW_DEFAULT));
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  const dt = useRef(null);

  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_HOST + "/manufacturing/employee/" + props.initialValues.id + "/summary?"
          + JSON.stringify(lazyParams).replace('"multiSortMeta":[],', ''),
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          'App-Network': localStorage.getItem("networkId")
        }
      })
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setSummary(response.data.items);
          setLoading(false);
          let totEstimatedTime = 0
          let totMainTime = 0
          let totAuxiliaryTime = 0
          let totProducedTime = 0
          response.data.items.map((item) => {
            totEstimatedTime = totEstimatedTime + item.estimatedTime
            totMainTime = totMainTime + item.mainTime
            totAuxiliaryTime = totAuxiliaryTime + item.auxiliaryTime
            totProducedTime = totProducedTime + item.producedTime
          })
          setTotEstimatedTime(totEstimatedTime)
          setTotMainTime(totMainTime)
          setTotAuxiliaryTime(totAuxiliaryTime)
          setTotProducedTime(totProducedTime)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: Number(process.env.REACT_APP_LIST_ROW_OP1), value: Number(process.env.REACT_APP_LIST_ROW_OP1)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP2), value: Number(process.env.REACT_APP_LIST_ROW_OP2)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP3), value: Number(process.env.REACT_APP_LIST_ROW_OP3)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP4), value: Number(process.env.REACT_APP_LIST_ROW_OP4)},
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  useEffect(() => {
    loadLazyData()
  }, [lazyParams])

  return (
    <>
      <Panel>
        <DataTable size={localStorage.getItem('scale')}
          emptyMessage="Nincs találat."
          value={summary} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
          onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
          onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
          paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
          autoLayout={true}
          stripedRows
          dataKey="id"
          filterDisplay="row"
          responsiveLayout="scroll"
          ref={dt}
        >
          <Column field="date" header="Dátum" sortable filter className={"p-text-nowrap"}
                  filterElement={() => {
                    return (
                      <>
                        <InputMask id={"dateFrom"}
                                   value={dateFromFilterSelectedFrom}
                                   mask={"9999-99-99"}
                                   placeholder={"ÉÉÉÉ-HH-NN"}
                                   onChange={(e) => {
                                     if (e.value.indexOf("_") === -1) {
                                       if (e.value === '') {
                                         e.value = null
                                       }
                                       setDateFromFilterSelectedFrom(e.value)
                                       clearTimeout(window.searchTimeout)
                                       window.searchTimeout = setTimeout(function () {
                                         dt.current.filter(e.value, 'dateFrom', '');
                                       }, 500)
                                     }
                                   }}
                        />
                        &nbsp;-&nbsp;
                        <InputMask id={"dateUntil"}
                                   value={dateFromFilterSelectedUntil}
                                   mask={"9999-99-99"}
                                   placeholder={"ÉÉÉÉ-HH-NN"}
                                   onChange={(e) => {
                                     if (e.value.indexOf("_") === -1) {
                                       if (e.value === '') {
                                         e.value = null
                                       }
                                       setDateFromFilterSelectedUntil(e.value)
                                       clearTimeout(window.searchTimeout)
                                       window.searchTimeout = setTimeout(function () {
                                         dt.current.filter(e.value, 'dateUntil', '');
                                       }, 500)
                                     }
                                   }}
                        />
                      </>
                    )
                  }}
                  body={(rowData) => {
                    return (
                      <>{moment.utc(rowData.date).local().format('YYYY-MM-DD')}</>
                    )
                  }}></Column>
          <Column field="estimatedTime" header="Becsült munkaidő" sortable></Column>
          <Column field="mainTime" header="Főidő" sortable></Column>
          <Column field="auxiliaryTime" header="Mellékidő" sortable></Column>
          <Column field="producedTime" header="Termelési idő" sortable></Column>
        </DataTable>
        <div className={"p-grid p-mt-1"}>
          <div className={"p-col-12 p-lg-6"}>

          </div>
          <div className={"p-col-12 p-lg-6"}>
            {loading !== true &&
            <>
              <hr className={"p-mb-3"}/>
              <div className={"p-grid"}>
                <div className={"p-col-8 p-text-right"}>
                  Becsült munkaidő összesen:
                </div>
                <div className={"p-col p-text-right"}>
                  {totEstimatedTime} perc
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-8 p-text-right"}>
                  Főidő összesen:
                </div>
                <div className={"p-col p-text-right"}>
                  {totMainTime} perc
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-8 p-text-right"}>
                  Mellékidő összesen:
                </div>
                <div className={"p-col p-text-right"}>
                  {totAuxiliaryTime} perc
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-8 p-text-right"}>
                  Termelési összesen:
                </div>
                <div className={"p-col p-text-right"}>
                  {totProducedTime} perc
                </div>
              </div>
              <hr />
            </>
            }
          </div>
        </div>
      </Panel>
    </>
  )
}
export default withTranslation('common')(ManufacturingemployeeShowComponent);
