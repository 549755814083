import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";

import UnasFormService from '../../services/unas/UnasFormService'
import {AutoComplete} from "primereact/autocomplete";
import {Dropdown} from "primereact/dropdown";
import CompanyListService from "../../services/company/CompanyListService";
import VatListService from "../../services/vat/VatListService";
import BankaccountListService from "../../services/bankaccount/BankaccountListService";
import {InputNumber} from "primereact/inputnumber";
import {InputSwitch} from "primereact/inputswitch";
import UnasShowService from "../../services/unas/UnasShowService";
import ReceiptgroupListService from "../../services/receiptgroup/ReceiptgroupListService";

const UnasFormComponent = (props) => {

  //form
  const [formData, setFormData] = useState({
    company: {
      id: Number(localStorage.getItem('defaultCompanyId'))
    },
    orderConfirmedByDefault: true,
    active: true,
  });
  const [vats, setVats] = useState([]);
  const [receiptgroups, setReceiptgroups] = useState([]);
  const [loading, setLoading] = useState(false);

  const validate = (data) => {
    let errors = {};
    // if (!data.name) {
    //   errors.name = 'Az megnevezés nem lehet üres';
    // }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if (props && props.initialValues) {
      UnasFormService(data, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({
            rerenderUnasList: true,
            sidebarUnasUpdate: false
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      UnasFormService(data, 'post')
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          window.App.setState({
            rerenderUnasList: true,
            sidebarUnasCreate: false
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // company autocomplete
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(localStorage.getItem('defaultCompanyName'));

  const searchCompany = (event) => {
    CompanyListService(JSON.stringify({filters: {name: {value: event.query}}}))
      .then(response => {
        setCompanies(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  useEffect(() => {
    if (props && props.initialValues) {
      UnasShowService(props.initialValues.id)
        .then(response => {
          setFormData(response.data)
          setSelectedCompany(response.data.companyName)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }
    ReceiptgroupListService(JSON.stringify({
      filters: {
        receiptType: {
          value: 'invoice'
        },
        outgoing: {
          value: true
        },
        external: {
          value: false
        }
      }
    }))
      .then(response => {
        setReceiptgroups(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    VatListService(JSON.stringify({
      filters: {
        percentage: {
          value: 0
        }
      }
    }))
      .then(response => {
        setVats(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })

  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit, submitting}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="name" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="name"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Webáruház neve</label>
                  <span className="p-d-block">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="company" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="company"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Cég</label>
                  <span className="p-d-block">
                                        {localStorage.getItem('roles').toString().includes('role_company_browse')
                                        || localStorage.getItem('roles').toString().includes('role_company_read') ? (
                                          <AutoComplete value={selectedCompany}
                                                        id={"company"}
                                                        suggestions={companies}
                                                        forceSelection
                                                        completeMethod={searchCompany}
                                                        field={"name"}
                                                        delay="1000"
                                                        placeholder={"Keresés gépeléssel..."}
                                                        className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                                        onSelect={(e) => {
                                                          setFormData({
                                                            ...formData,
                                                            company: {id: e.value.id}
                                                          })
                                                          localStorage.setItem('defaultCompanyId', e.value.id)
                                                          localStorage.setItem('defaultCompanyName', e.value.name)
                                                        }}
                                                        onChange={(e) => {
                                                          setFormData({
                                                            ...formData,
                                                            company: {id: null}
                                                          })
                                                          setSelectedCompany(e.value)
                                                        }}
                                                        dropdown
                                                        onClear={(e) => {
                                                          setSelectedCompany('')
                                                        }}
                                          />
                                        ) : (
                                          <Dropdown disabled className={"disabled"} id={"company"}/>
                                        )}
                          </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="apiKey" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="apiKey"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>API kulcs</label>
                  <span className="p-d-block">
                      <InputText id="apiKey" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     apiKey: e.target.value
                                   })
                                 }}
                                 value={formData.apiKey}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="vat" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="vat"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>0% ÁFA megfeleltetése</label>
                  <span className="p-d-block">
                                <Dropdown {...input}
                                          onChange={(e) => {
                                            setFormData({
                                              ...formData,
                                              zeroVatPercentName: Number(e.target.value)
                                            })
                                          }}
                                          value={Number(formData.zeroVatPercentName)}
                                          options={vats}
                                          optionLabel="name"
                                          optionValue="id"
                                          id={"vat"}
                                          emptyMessage={"Még nincs ÁFA..."}
                                          className={classNames({'p-error': isFormFieldValid(meta)})}
                                />
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="receiptGroup" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="receiptGroup"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Számlatömb</label>
                  <span className="p-d-block">
                  {formData.receiptGroup && formData.receiptGroup.id ? (
                    <Dropdown {...input}
                              value={formData.receiptGroup.id}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  receiptGroup: {id: e.value}
                                })
                              }}
                              options={receiptgroups}
                              optionLabel="name"
                              optionValue="id"
                              id={"receiptGroup"}
                              emptyMessage={"Még nincs szállítólevél típusú számlatömb..."}
                              className={classNames({'p-error': isFormFieldValid(meta)})}
                              showClear
                    />
                  ) : (
                    <Dropdown {...input}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  receiptGroup: {id: e.value}
                                })
                              }}
                              options={receiptgroups}
                              optionLabel="name"
                              optionValue="id"
                              id={"receiptGroup"}
                              emptyMessage={"Még nincs szállítólevél típusú számlatömb..."}
                              className={classNames({'p-error': isFormFieldValid(meta)})}
                              showClear
                    />
                  )}
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-3"}>
              <Field name="paymentDateGap" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="paymentDateGap"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Fizetési határidő</label>
                  <span className="p-d-block">
                      <InputNumber {...input} id="paymentDateGap"
                                   locale="hu-HU"
                                   minFractionDigits={0}
                                   maxFractionDigits={9}
                                   value={formData.paymentDateGap}
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       paymentDateGap: e.value
                                     })
                                   }}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-3"}>
              <Field name="deliveryDateGap" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="deliveryDateGap"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Szállítási határidő</label>
                  <span className="p-d-block">
                      <InputNumber {...input} id="deliveryDateGap"
                                   locale="hu-HU"
                                   minFractionDigits={0}
                                   maxFractionDigits={9}
                                   value={formData.deliveryDateGap}
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       deliveryDateGap: e.value
                                     })
                                   }}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="autoReceipt" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="autoReceipt"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Automata számlázás</label>
                  <span className="p-d-block">
                    <InputSwitch
                      className={"p-mt-1"}
                      checked={formData.autoReceipt}
                      value={formData.autoReceipt}
                      onChange={e => setFormData({...formData, autoReceipt: e.target.value})}/>
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-2"}>
              <Field name="active" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="orderConfirmedByDefault"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Aktív</label>
                  <span className="p-d-block">
                    <InputSwitch
                      className={"p-mt-1"}
                      checked={formData.active}
                      value={formData.active}
                      onChange={e => setFormData({...formData, active: e.target.value})}/>
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid p-mt-1"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              <Button type="submit" label="Mentés" icon={"pi pi-check"} loading={loading}
                      className="p-button-success"/>
            </div>
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(UnasFormComponent);
