import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";

import PartnercontactListService from "../../services/partnercontact/PartnercontactListService";
import {Field, Form} from "react-final-form";
import {classNames} from "primereact/utils";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import PartnercontactFormService from "../../services/partnercontact/PartnercontactFormService";
import PartnercontactDeleteService from "../../services/partnercontact/PartnercontactDeleteService";

const PartnerContactsComponent = (props) => {

  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);

  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(Number(process.env.REACT_APP_LIST_ROW_DEFAULT));
  const dt = useRef(null);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'name',
    sortOrder: -1,
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderPartnerContacts: false})
      PartnercontactListService(JSON.stringify(lazyParams), props.initialValues.id)
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setContacts(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: Number(process.env.REACT_APP_LIST_ROW_OP1), value: Number(process.env.REACT_APP_LIST_ROW_OP1)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP2), value: Number(process.env.REACT_APP_LIST_ROW_OP2)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP3), value: Number(process.env.REACT_APP_LIST_ROW_OP3)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP4), value: Number(process.env.REACT_APP_LIST_ROW_OP4)},
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const deleteContacts = (rowData) => {
    PartnercontactDeleteService(rowData.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres törlés!',);
        loadLazyData();
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {localStorage.getItem('roles').toString().includes('role_partner_contact_write') &&
          <>
            <Button type="button"
                    icon="pi pi-pencil" className="  p-button-warning p-mr-2"
                    tooltip="Módosítás"
                    onClick={() => {
                      setContactDialog(true)
                      setContactFormData(rowData)
                      setContactFormMethod('put')
                    }}/>
            {/*<Button icon="pi pi-trash" className="  p-button-danger"*/}
            {/*        tooltip="Törlés"*/}
            {/*        onClick={() => {*/}
            {/*          if (window.confirm('A törlés megerősítésére van szükség!')) {*/}
            {/*            deleteContacts(rowData)*/}
            {/*          }*/}
            {/*        }}*/}
            {/*/>*/}
          </>
        }
      </React.Fragment>
    );
  }

  // dialog
  const contactValidate = (data) => {
    let errors = {};
    if (!data.name) {
      errors.name = 'Kötelező mező';
    }
    return errors;
  };

  const [contactDialog, setContactDialog] = useState(false);
  const [contactFormData, setContactFormData] = useState({}); // set on dialog open!
  const [contactFormMethod, setContactFormMethod] = useState('post');

  const contactOnSubmit = (data, form) => {
    setLoading(true)
    PartnercontactFormService(data, contactFormMethod, props.initialValues.id, contactFormData.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres mentés!');
        PartnercontactListService(JSON.stringify({
          first: 0,
          rows: 9999,
          page: 0,
        }), props.initialValues.id)
          .then(response => {
            setContacts(response.data.items)
          })
          .catch(error => {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          })
        setContactDialog(false)
        setLoading(false)
        setContactFormData({})
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setLoading(false)
      })
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  useEffect(() => {
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderPartnerContacts === true])

  return (

    <>
      <DataTable size={localStorage.getItem('scale')}
        emptyMessage="Nincs találat."
        value={contacts} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
        paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
        autoLayout={true}
        filterDisplay="row"
        responsiveLayout="scroll"
        stripedRows
        ref={dt}
      >
        <Column field="name" header="Név"></Column>
        <Column field="position" header="Beosztás" filter filterPlaceholder={"Beosztás"} sortable></Column>
        <Column field="email" header="Email" filter filterPlaceholder={"Email"} sortable></Column>
        <Column field="phone" header="Telefon" filter filterPlaceholder={"Telefon"} sortable></Column>
        {localStorage.getItem('roles').toString().includes('role_partner_contact_write') &&
          <Column body={actionBodyTemplate} className={"p-text-nowrap"}></Column>
        }
      </DataTable>
      {localStorage.getItem('roles').toString().includes('role_partner_contact_write') &&
      <>
        <div className={"p-grid p-mt-1"}>
          <div className={"p-col-12 p-lg-6 p-xl-6 p-col-align-center"}>

          </div>
          <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
            <Button type="button" label="Új kapcsolat hozzáadása" icon="pi pi-plus" className="p-button-success "
                    onClick={() => {
                      setContactDialog(true)
                      setContactFormMethod('post')
                    }}
            />
          </div>
        </div>
        <Dialog visible={contactDialog}
                style={{width: '750px'}}
                header="Kapcsolat hozzáadása"
                modal
                onHide={() => {
                  setContactDialog(false)
                }}>
          <Form onSubmit={contactOnSubmit}
                initialValues={contactFormData}
                validate={contactValidate}
                render={({handleSubmit, submitting}) => (
                  <form onSubmit={handleSubmit} className="">
                    <div className={"p-grid"}>
                      <div className={"p-col-12 p-lg-6"}>
                        <Field name="name" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="name"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>Név</label>
                            <span className={"p-d-block"}>
                              <InputText id="name" {...input}
                                         onChange={(e) => {
                                           setContactFormData({
                                             ...contactFormData,
                                             name: e.target.value
                                           })
                                         }}
                                         value={contactFormData.name}
                                         className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                      <div className={"p-col-12 p-lg-6"}>
                        <Field name="position" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="position"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>Beosztás</label>
                            <span className={"p-d-block"}>
                              <InputText id="position" {...input}
                                         onChange={(e) => {
                                           setContactFormData({
                                             ...contactFormData,
                                             position: e.target.value
                                           })
                                         }}
                                         value={contactFormData.position}
                                         className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                      <div className={"p-col-12 p-lg-6"}>
                        <Field name="email" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="email"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>Email</label>
                            <span className={"p-d-block"}>
                              <InputText id="email" {...input}
                                         onChange={(e) => {
                                           setContactFormData({
                                             ...contactFormData,
                                             email: e.target.value
                                           })
                                         }}
                                         value={contactFormData.email}
                                         className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                      <div className={"p-col-12 p-lg-6"}>
                        <Field name="phone" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="phone"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>Telefon</label>
                            <span className={"p-d-block"}>
                              <InputText id="phone" {...input}
                                         onChange={(e) => {
                                           setContactFormData({
                                             ...contactFormData,
                                             phone: e.target.value
                                           })
                                         }}
                                         value={contactFormData.phone}
                                         className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                    </div>
                    <div className={"p-grid p-mt-1"}>
                      <div className={"p-col-12 p-lg-6 p-xl-6"}>
                      </div>
                      <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                        <Button type="submit" label="Mentés" icon="pi pi-check"
                                loading={loading}/>
                      </div>
                    </div>
                  </form>
                )}/>
        </Dialog>
      </>
      }
    </>
  )
}
export default withTranslation('common')(PartnerContactsComponent);
