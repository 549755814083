import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {InputNumber} from "primereact/inputnumber";
import {Dropdown} from "primereact/dropdown";

import ProjectproductFormService from '../../services/project/ProjectproductFormService'
import UnitListService from "../../services/unit/UnitListService";
import ProductListService from "../../services/product/ProductListService";
import {AutoComplete} from "primereact/autocomplete";

const ProjectproductFormComponent = (props) => {

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [units, setUnits] = useState([]);

  const validate = (data) => {
    let errors = {};
    // if (!data.projectActionName) {
    //   errors.projectActionName = 'Az megnevezés nem lehet üres';
    // }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if (window.App.state.projectproductFormMethod && window.App.state.projectproductFormMethod === 'update') {
      ProjectproductFormService(data, 'put', props.projectId)
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({
            rerenderProjectproductList: true,
            sidebarProjectproductUpdate: false,
            dialogProjectproduct: false,
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      ProjectproductFormService(data, 'post', props.projectId)
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          window.App.setState({
            rerenderProjectproductList: true,
            sidebarProjectproductCreate: false,
            dialogProjectproduct: false,
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // product autocomplete
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();

  const searchProduct = (event) => {
    //setSelectedProduct(event.query.replace('Nincs találat', '').replace(': ', ''))
    //setSelectedProduct(event.query)
    ProductListService(JSON.stringify({filters: {name: {
      //value: event.query.replace('Nincs találat', '').replace(': ', '')
      value: event.query
    }}}))
      .then(response => {
        setProducts(response.data.items)
        // if (response.data && response.data.items && response.data.items[0]) {
        //   setProducts(response.data.items)
        // } else {
        //   let string
        //   if (event.query) {
        //     string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
        //   } else {
        //     string = 'Nincs találat'
        //   }
        //   setSelectedProduct(string)
        // }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  useEffect(() => {
    UnitListService()
      .then(response => {
        setUnits(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    if (props && props.initialValues) {
      setFormData(
        props.initialValues
      )
    }
    if(window.App.state.projectproductFormMethod && window.App.state.projectproductFormMethod === 'create') {
      setFormData({projectItem: {id: props.projectItemId}})
    }
  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="name" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="name"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Megnevezés</label>
                  <span className="p-d-block">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="product" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="product"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Termék</label>
                  <span className="p-d-block">
                        <AutoComplete value={selectedProduct}
                                      id={"product"}
                                      suggestions={products}
                                      forceSelection
                                      completeMethod={searchProduct}
                                      field={"name"}
                                      delay="1000"
                                      placeholder={"Keresés gépeléssel..."}
                                      className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                      onSelect={(e) => {
                                        setFormData({
                                          ...formData,
                                          product: {id: e.value.id},
                                          name: e.value.name,
                                          unitPrice: e.value.retailNetAmount,
                                        })
                                      }}
                                      onChange={(e) => {
                                        setSelectedProduct(e.value)
                                        setFormData({
                                          ...formData,
                                          product: null,
                                          name: null,
                                          unitPrice: null,
                                        })
                                      }}
                                      dropdown
                                      onClear={(e) => {
                                        setSelectedProduct('')
                                      }}
                        />
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
            <div className={"p-grid p-mt-1"}>
              <div className={"p-col-12 p-lg-6"}>
                <Field name="quantity" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="quantity"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Mennyiség</label>
                    <span className={"p-d-block"}>
                              <InputNumber id="quantity" {...input}
                                           minFractionDigits={0}
                                           maxFractionDigits={9}
                                           locale="hu-HU"
                                           onValueChange={(e) => {
                                             setFormData({
                                               ...formData,
                                               quantity: e.value
                                             })
                                           }}
                                           value={formData.quantity}
                                           className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              {/*<div className={"p-col-12 p-lg-4"}>*/}
              {/*  <Field name="unit" render={({input, meta}) => (*/}
              {/*    <div className="p-field p-fluid">*/}
              {/*      <label htmlFor="unit"*/}
              {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Egység</label>*/}
              {/*      <span>*/}
              {/*                {formData && formData.unit && formData.unit.id ? (*/}
              {/*                  <Dropdown {...input}*/}
              {/*                            onChange={(e) => {*/}
              {/*                              setFormData({*/}
              {/*                                ...formData,*/}
              {/*                                unit: {id: e.target.value}*/}
              {/*                              })*/}
              {/*                              localStorage.setItem("defaultUnitId", e.target.value)*/}
              {/*                            }}*/}
              {/*                            value={formData.unit.id}*/}
              {/*                            options={units}*/}
              {/*                            optionLabel="name"*/}
              {/*                            optionValue="id"*/}
              {/*                            id={"unit"}*/}
              {/*                            showClear*/}
              {/*                            emptyMessage={"Még nincs egység..."}*/}
              {/*                            className={classNames({'p-error': isFormFieldValid(meta)})}*/}
              {/*                  />*/}
              {/*                ) : (*/}
              {/*                  <Dropdown {...input}*/}
              {/*                            onChange={(e) => {*/}
              {/*                              setFormData({*/}
              {/*                                ...formData,*/}
              {/*                                unit: {id: e.target.value}*/}
              {/*                              })*/}
              {/*                              localStorage.setItem("defaultUnitId", e.target.value)*/}
              {/*                            }}*/}
              {/*                            options={units}*/}
              {/*                            optionLabel="name"*/}
              {/*                            optionValue="id"*/}
              {/*                            id={"unit"}*/}
              {/*                            showClear*/}
              {/*                            emptyMessage={"Még nincs egység..."}*/}
              {/*                            className={classNames({'p-error': isFormFieldValid(meta)})}*/}
              {/*                  />)}*/}
              {/*              </span>*/}
              {/*      {getFormErrorMessage(meta)}*/}
              {/*    </div>*/}
              {/*  )}/>*/}
              {/*</div>*/}
              <div className={"p-col-12 p-lg-6"}>
                <Field name="netAmount" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="netAmount"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Nettó ár</label>
                    <span className={"p-d-block"}>
                              <InputNumber id="netAmount" {...input}
                                           locale="hu-HU"
                                           minFractionDigits={0}
                                           maxFractionDigits={9}
                                           onValueChange={(e) => {
                                             setFormData({
                                               ...formData,
                                               netAmount: e.value
                                             })
                                           }}
                                           value={formData.netAmount}
                                           className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
            </div>
          <div className={"p-grid p-mt-1"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              <Button type="submit" label="Mentés" icon={"pi pi-check"} loading={loading}
                      className="p-button-success"/>
            </div>
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(ProjectproductFormComponent);
