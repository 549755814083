import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Tooltip} from "primereact/tooltip";

import BankaccountListService from "../../services/bankaccount/BankaccountListService";
import BankaccountDeleteService from "../../services/bankaccount/BankaccountDeleteService";

const BankaccountListComponent = (props) => {

  const [bankaccount, setBankaccount] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(Number(process.env.REACT_APP_LIST_ROW_DEFAULT));
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderBankaccountList: false})
      BankaccountListService(JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setBankaccount(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: Number(process.env.REACT_APP_LIST_ROW_OP1), value: Number(process.env.REACT_APP_LIST_ROW_OP1)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP2), value: Number(process.env.REACT_APP_LIST_ROW_OP2)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP3), value: Number(process.env.REACT_APP_LIST_ROW_OP3)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP4), value: Number(process.env.REACT_APP_LIST_ROW_OP4)},
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const deleteBankaccount = (rowData) => {
    BankaccountDeleteService(rowData.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres törlés!',);
        loadLazyData();
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {localStorage.getItem('roles').toString().includes('role_bankaccount_write') &&
        <Button icon="pi pi-pencil" className="  p-button-warning p-mr-2"
                tooltip="Módosítás"
                onClick={() => {
                  window.App.setState({
                    sidebarBankaccountUpdate: true,
                    props: rowData
                  })
                }}/>
        }
        {localStorage.getItem('roles').toString().includes('role_bankaccount_write') &&
        <Button icon="pi pi-trash" className="  p-button-danger"
                tooltip="Törlés"
                onClick={() => {
                  if (window.confirm('A törlés megerősítésére van szükség!')) {
                    deleteBankaccount(rowData)
                  }
                }}
        />
        }
      </React.Fragment>
    );
  }

  useEffect(() => {
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderBankaccountList === true])

  return (
    <>
      {props && props.view === 'simple' &&
        <>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6 p-col-align-center p-text-center p-text-lg-left"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-col-align-center p-text-center p-text-lg-right"}>
              {localStorage.getItem('roles').toString().includes('role_bankaccount_write') &&
                <a onClick={() => window.App.setState({sidebarBankaccountCreate: true})}
                   className={"p-button  p-button-success"}>
                  <i className={"pi pi-plus"}></i>
                  &nbsp;
                  Új banszámla
                </a>
              }
            </div>
          </div>
        </>
      }
      <DataTable size={localStorage.getItem('scale')}
        emptyMessage="Nincs találat."
        value={bankaccount} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
        paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
        autoLayout={true}
        filterDisplay="row"
        responsiveLayout="scroll"
        stripedRows
      >
        <Column field="name" header="Név" sortable filter filterPlaceholder="Név"></Column>
        <Column field="companyName" header="Vállalkozás"></Column>
        <Column field="bankaccount" header="Számlaszám" sortable filter filterPlaceholder="Számlaszám"></Column>
        <Column field="swift" header="Swift" sortable filter filterPlaceholder="Swift"></Column>
        <Column field="iban" header="IBAN" sortable filter filterPlaceholder="IBAN"></Column>
        <Column field="currency" header="Pénznem"></Column>
        <Column field="active" header="Státusz" sortable className={"p-text-center"} body={(rowData) => {
          return (
            <>
              {rowData.active === true ? (
                <i className={"pi pi-check text-success"}></i>
              ) : (
                <i className={"pi pi-times text-danger"}></i>
              )}
            </>
          )
        }}
        ></Column>
        {/*<Column field="default" header="Alapértelmezett" sortable className={"p-text-center"} body={(rowData) => {*/}
        {/*  return (*/}
        {/*    <>*/}
        {/*      {rowData.default === true ? (*/}
        {/*        <i className={"pi pi-check text-success"}></i>*/}
        {/*      ) : (*/}
        {/*        <i className={"pi pi-times text-danger"}></i>*/}
        {/*      )}*/}
        {/*    </>*/}
        {/*  )*/}
        {/*}}*/}
        {/*></Column>*/}
        <Column body={actionBodyTemplate} className={"p-py-0 p-text-right p-text-nowrap"}></Column>
      </DataTable>
    </>
  )
}
export default withTranslation('common')(BankaccountListComponent);
