import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {Chart} from 'primereact/chart';
import {Sidebar} from "primereact/sidebar";
import {Link} from "react-router-dom";
import {TabView, TabPanel} from 'primereact/tabview';

import ReceiptFormComponent from "../../components/receipt/ReceiptFormComponent";
import ReceiptListComponent from "../../components/receipt/ReceiptListComponent";
import ReceiptCostImportComponent from "../../components/receipt/ReceiptCostImportComponent";
import ReceiptgroupListService from "../../services/receiptgroup/ReceiptgroupListService";
import ReceiptShowComponent from "../../components/receipt/ReceiptShowComponent";
import ReceiptSzamlazzhuImportComponent from "../../components/receipt/ReceiptSzamlazzhuImportComponent";
import ReceiptSendComponent from "../../components/receipt/ReceiptSendComponent";
import {Dialog} from "primereact/dialog";

export const FinanceDashboardPage = () => {

  const [showHelpBottom, setShowHelpBottom] = useState(true);
  const [receiptgroups, setReceiptgroups] = useState([]);

  useEffect(() => {
    window.App.pageHeight()
    setTimeout(function () {
      if (document.getElementsByClassName('activeMenu') && document.getElementsByClassName('activeMenu')[0]) {
        document.getElementsByClassName('activeMenu')[0].classList.remove("activeMenu")
      }
      if (document.getElementsByClassName('finance') && document.getElementsByClassName('finance')[0]) {
        document.getElementsByClassName('finance')[0].classList.add("activeMenu")
      }
    }, 1000)
    if (
      document.getElementById('tabScroll')
      && document.getElementById('tabScroll').children
      && document.getElementById('tabScroll').children[0]
      && document.getElementById('tabScroll').children[0].querySelector("li:not(.p-disabled)").children[0]
    ) {
      document.getElementById('tabScroll').children[0].querySelector("li:not(.p-disabled)").children[0].click()
    }
    document.title = 'Pénzügy' + ' - ' + process.env.REACT_APP_TITLE;
    ReceiptgroupListService(
      JSON.stringify({
        first: 0,
        rows: 9999,
        page: 0,
      })
    )
      .then(response => {
        setReceiptgroups(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, []);

  return (
    <div className="ModuleDashboardPage DashboardPage p-component p-p-3 ">
      {localStorage.getItem('module_finance') ? (
        <>
          <div className={"p-grid p-d-flex p-flex-wrap"}>
            <div className={"p-col-12 p-lg-12 p-xl-12 p-p-0 left p-position-relative"}>
              {localStorage.getItem('roles').toString().includes('role_receipt_read') ? (
                <>
                  <TabView renderActiveOnly="true" className={"nav"} scrollable={window.App.state.tabScroll}
                           id="tabScroll">
                    <TabPanel header="Összes bizonylat listája">
                      <div className={"p-px-2 p-pb-2"}>
                        <ReceiptListComponent view="simple"/>
                      </div>
                    </TabPanel>
                    {receiptgroups.map((receiptgroup, index) => {
                      if (receiptgroup.active && receiptgroup.active === true) {
                        let typeName = 'bizonylatok'
                        if (receiptgroup.receiptType === 'invoice') {
                          typeName = 'számlák'
                        }
                        if (receiptgroup.receiptType === 'fee_request') {
                          typeName = 'díjbekérők'
                        }
                        if (receiptgroup.receiptType === 'delivery_note') {
                          typeName = 'szállítólevelek'
                        }
                        return (
                          <TabPanel header={receiptgroup.name + " bizonylatok"}>
                            <div className={"p-px-2 p-pb-2"}>
                              <ReceiptListComponent receiptGroupName={receiptgroup.name} receiptGroup={receiptgroup.id}
                                                    receiptType={receiptgroup.receiptType} view="simple"/>
                            </div>
                          </TabPanel>
                        )
                      } else {
                        return (
                          <TabPanel disabled></TabPanel>
                        )
                      }
                    })}
                  </TabView>
                </>
              ) : (
                <>
                  <p className={"p-text-center p-my-5 p-py-5"}>
                    <i className={"pi pi-exclamation-circle p-d-block p-mb-5"} style={{'fontSize': '5em'}}></i>
                    <span
                      className={"p-text-bold p-d-block "}>Ehhez a listához sajnos nem rendelkezel hozzáféréssel.</span>
                    Amennyiben további információra van szükséged írj emailt
                    a {process.env.REACT_APP_DEVOPS_CONTACT} címre.
                    {process.env.REACT_APP_SUBSCRIPTIONS === 'enabled' &&
                    <span className={"p-text-bold p-d-block p-mt-5"}>
                       <a className={"p-button p-button-warning"}
                          onClick={() => {
                            window.location.replace('/#/subscriptions');
                            window.App.forceUpdate()
                          }}
                       >
                         <i className={"pi pi-building"}></i>&nbsp;Előfizetői fiók áttekintése
                       </a>
                   </span>
                    }
                  </p>
                </>
              )}
            </div>
          </div>
          <Sidebar position="left" visible={window.App.state.sidebarReceiptList} closeOnEscape={true}
                   style={{width: '100%'}}
                   onHide={() => window.App.setState({
                     sidebarReceiptList: false,
                     lazyparamsReceiptList: null,
                   })}>
            <ReceiptListComponent view="advanced"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarReceiptCostCreate} closeOnEscape={true}
                   style={{width: '98%'}}
                   onHide={() => window.App.setState({
                     rerenderReceiptList: true,
                     sidebarReceiptCostCreate: false,
                     newPartnerId: null,
                     newPartnerName: null
                   })}>
            <ReceiptFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarReceiptUpdate} closeOnEscape={true}
                   style={{width: '98%'}}
                   onHide={() => window.App.setState({
                     rerenderReceiptList: true,
                     sidebarReceiptUpdate: false,
                     newPartnerId: null,
                     newPartnerName: null
                   })}>
            <ReceiptFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarReceiptInvoiceCreate} closeOnEscape={true}
                   style={{width: '98%'}}
                   onHide={() => {
                     window.App.setState({
                       rerenderReceiptList: true,
                       sidebarReceiptInvoiceCreate: false,
                       newPartnerId: null,
                       newPartnerName: null
                     })
                   }}>
            <ReceiptFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarReceiptDeliveryNoteCreate} closeOnEscape={true}
                   style={{width: '98%'}}
                   onHide={() => {
                     window.App.setState({
                       rerenderReceiptList: true,
                       sidebarReceiptDeliveryNoteCreate: false,
                       newPartnerId: null,
                       newPartnerName: null
                     })
                   }}>
            <ReceiptFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarReceiptFeeRequestCreate} closeOnEscape={true}
                   style={{width: '98%'}}
                   onHide={() => {
                     window.App.setState({
                       rerenderReceiptList: true,
                       sidebarReceiptFeeRequestCreate: false,
                       newPartnerId: null,
                       newPartnerName: null
                     })
                   }}>
            <ReceiptFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarReceiptCostImport} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({
                       sidebarReceiptCostImport: false,
                       newPartnerId: null,
                       newPartnerName: null
                     })
                   }}>
            <h2 className={"text-primary"}>Költségszámlák importálása</h2>
            <ReceiptCostImportComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarReceiptSzamlazzhuImport} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({
                       sidebarReceiptSzamlazzhuImport: false,
                       newPartnerId: null,
                       newPartnerName: null
                     })
                   }}>
            <h2 className={"text-primary"}>Számlázz.hu importálás</h2>
            <ReceiptSzamlazzhuImportComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarReceiptShow} closeOnEscape={true}
                   style={{width: '980px'}}
                   onHide={() => window.App.setState({
                     rerenderReceiptList: true,
                     sidebarReceiptShow: false,
                     newPartnerId: null,
                     newPartnerName: null
                   })}>
            <ReceiptShowComponent initialValues={window.App.state.props}/>
          </Sidebar>
          <Dialog visible={window.App.state.dialogReceiptSend}
                  style={{width: '450px'}}
                  header="Számla kézbesítése emailben"
                  modal
                  onHide={() => {
                    window.App.setState({dialogReceiptSend: false})
                  }}>
            <ReceiptSendComponent/>
          </Dialog>
          {process.env.REACT_APP_BRAND === 'trackie' && (localStorage.getItem('helpBottom') === 'true' || !localStorage.getItem('helpBottom')) &&
          <Sidebar visible={showHelpBottom} position="bottom" modal={false} onHide={() => setShowHelpBottom(false)}
                   id={"helpBottom"}>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-2 p-d-none p-d-lg-block p-col-align-center"}>
                <img src={require('../../assets/img/undraw_air_support_re_nybl.svg').default}
                     className="p-d-block p-mx-auto w-50"/>
              </div>
              <div className={"p-col p-col-align-center"}>
                <h3 className={"p-mt-0"}>
                  <i className={"pi pi-question-circle"}></i> Gyors segítség a megrendelések modul használatához
                </h3>
                <p>
                  <strong className={"p-text-uppercase p-d-block text-dark"}>Bizonylat kiállítás</strong>
                  A bizonylatok szerkesztéséhez bevezetésre került az előnézeti funkció. A bizonylatok
                  léterhozás közben vázlatként jönnek létre a rendszerben, szerkesztésük a <i
                  className={"pi pi-check"}></i> Mentés gomb megnyomásával brámikor félbehagyható. A
                  számlák kiállításához a szerkesztési felületen a <i className={"pi pi-check"}></i> Mentés és
                  bizonylat kiálltása funkcióval véglegesíthetők, melyet követően sorszámot kap. A művelet befejezését
                  követően a bizonylat igény szerint kézbesíthető emailben a felugró ablakból, automatikusan nem
                  kerülnek kézbesítésre. A léterjött bizonylatok esetleges NAV lejelentésével kapcsolatos információk és
                  funkciók megtalálhatók a <i className={"pi pi-eye"}></i> Megtekintés nézeten. A létrejött bizonylatok
                  bármikor <i className={"pi pi-download"}></i> Letölthetők akár már vázlatként is.
                </p>
                <Button icon="pi pi-times" className="p-button p-button-sm p-mt-2 p-mr-2"
                        label={"Ne jelenjen meg többet ez az üzenet"}
                        onClick={() => {
                          if (window.confirm('Bezárást követően nem jelenik meg többé ez az üzenet!')) {
                            localStorage.setItem('helpBottom', false);
                            window.App.forceUpdate()
                          }
                        }}
                />
                <Button icon="pi pi-download" className="p-button-secondary p-button-sm p-mt-2 disabled p-mr-2"
                        label={"Felhasználói kézikönyv letöltése"}
                />
                <a href={"mailto:hello@trackie.io"} className={"p-button p-button-outlined"} style={{border: 0}}>További
                  segítségre van szükséged? hello@trackie.io</a>
              </div>
            </div>
          </Sidebar>
          }
        </>
      ) : (
        <p className={"p-text-center p-my-5 p-py-5"}>
          <i className={"pi pi-exclamation-circle p-d-block p-mb-5"} style={{'fontSize': '5em'}}></i>
          <span className={"p-text-bold p-d-block "}>Ehhez a modulhoz sajnos nem rendelkezel hozzáféréssel.</span>
          Amennyiben további információra van szükséged írj emailt a {process.env.REACT_APP_DEVOPS_CONTACT} címre.
          {process.env.REACT_APP_SUBSCRIPTIONS === 'enabled' &&
          <span className={"p-text-bold p-d-block p-mt-5"}>
                       <a className={"p-button p-button-warning"}
                          onClick={() => {
                            window.location.replace('/#/subscriptions');
                            window.App.forceUpdate()
                          }}
                       >
                         <i className={"pi pi-building"}></i>&nbsp;Előfizetői fiók áttekintése
                       </a>
                   </span>
          }
        </p>
      )}
    </div>
  )
}
export default withTranslation('common')(FinanceDashboardPage);
