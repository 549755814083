import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {Chart} from 'primereact/chart';
import {Sidebar} from "primereact/sidebar";
import {Link} from "react-router-dom";
import {Panel} from "primereact/panel";
import {TabView, TabPanel} from 'primereact/tabview';

import ProjectListComponent from "../../components/project/ProjectListComponent";
import ProjectFormComponent from "../../components/project/ProjectFormComponent";
import ProjectShowComponent from "../../components/project/ProjectShowComponent";
import ProductionitemFormComponent from "../../components/productionitem/ProductionitemFormComponent";
// import ProductionitemShowComponent from "../../components/productionitemscalendar/ProductionitemscalendarShowComponent";
import OfferListComponent from "../../components/offer/OfferListComponent";
import OfferFormComponent from "../../components/offer/OfferFormComponent";
import EmployeeViewComponent from "../../components/employee/EmployeeViewComponent";
import OfferViewComponent from "../../components/offer/OfferViewComponent";
import FullfillmentItemFormComponent from "../../components/fulfillment/FullfillmentItemFormComponent";
import ReceiptFormComponent from "../../components/receipt/ReceiptFormComponent";
import ProjectitemJobsComponent from "../../components/project/ProjectitemJobsListComponent";
import ProjectitemJobsListComponent from "../../components/project/ProjectitemJobsListComponent";
import WorkflowListComponent from "../../components/workflow/WorkflowListComponent";
import WorkflowFormComponent from "../../components/workflow/WorkflowFormComponent";
import ProjecttypeListService from "../../services/projecttype/ProjecttypeListService";
import WorkitemListComponent from "../../components/workitem/WorkitemListComponent";

export const ProjectDashboardPage = (props) => {

  const {t} = props
  const [projectTypes, setProjectTypes] = useState([]);

  useEffect(() => {
    window.App.pageHeight()
    document.title = t('projects') + ' - ' + process.env.REACT_APP_TITLE;
    setTimeout(function () {
      if (document.getElementsByClassName('activeMenu')[0]) {
        document.getElementsByClassName('activeMenu')[0].classList.remove("activeMenu")
      }
      if (document.getElementsByClassName('project')[0]) {
        document.getElementsByClassName('project')[0].classList.add("activeMenu")
      }
    }, 1000)
    if (
      document.getElementById('tabScroll')
      && document.getElementById('tabScroll').children
      && document.getElementById('tabScroll').children[0]
      && document.getElementById('tabScroll').children[0].querySelector("li:not(.p-disabled)").children[0]
    ) {
      document.getElementById('tabScroll').children[0].querySelector("li:not(.p-disabled)").children[0].click()
    }
    ProjecttypeListService(JSON.stringify({
      first: 0,
      rows: 9999,
      page: 0,
    }))
      .then(response => {
        setProjectTypes(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, []);

  return (
    <div className="ModuleDashboardPage DashboardPage p-component p-p-3 ">
      {localStorage.getItem('module_project') ? (
        <>
          <div className={"p-grid p-d-flex p-flex-wrap"}>
            <div className={"p-col-12 p-lg-12 p-xl-12 p-p-0 left p-position-relative"}>
              <TabView renderActiveOnly="true" className={"nav"} scrollable={window.App.state.tabScroll} id="tabScroll">
                {localStorage.getItem('roles').toString().includes('role_project_read') ? (
                  <TabPanel header={t('projects')}>
                    <div className={"p-px-2 p-pb-2"}>
                      <ProjectListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_offer_read') ? (
                  <TabPanel header="Árajánlatok">
                    <div className={"p-px-2 p-pb-2"}>
                      <OfferListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_project_read') ? (
                  <TabPanel header="Munkafolyamatok">
                    <div className={"p-px-2 p-pb-2"}>
                      <WorkflowListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_project_read') ? (
                  <TabPanel header="Feladatok">
                    <div className={"p-p-5"}>
                      <WorkitemListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
              </TabView>
            </div>
          </div>
          {/*<Sidebar position="left" visible={window.App.state.sidebarContractCreate} closeOnEscape={true}*/}
          {/*         style={{width: '780px'}}*/}
          {/*         onHide={() => {*/}
          {/*           window.App.setState({sidebarContractCreate: false})*/}
          {/*         }}>*/}
          {/*  <h2 className={"text-primary"}>Új szerződés</h2>*/}
          {/*  <ContractFormComponent method="post"/>*/}
          {/*</Sidebar>*/}
          {/*<Sidebar position="left" visible={window.App.state.sidebarContractUpdate} closeOnEscape={true}*/}
          {/*         style={{width: '780px'}}*/}
          {/*         onHide={() => {*/}
          {/*           window.App.setState({sidebarContractUpdate: false})*/}
          {/*         }}>*/}
          {/*  <h2 className={"text-primary"}>Szerződés módosítása</h2>*/}
          {/*  <ContractFormComponent method="put" initialValues={window.App.state.props}/>*/}
          {/*</Sidebar>*/}
          <Sidebar position="left" visible={window.App.state.sidebarProjectList} closeOnEscape={true}
                   style={{width: '100%'}}
                   onHide={() => window.App.setState({sidebarProjectList: false})}>
            <ProjectListComponent view="advanced"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarProjectCreate} closeOnEscape={true}
                   style={{width: '980px'}}
                   onHide={() => {
                     window.App.setState({
                       sidebarProjectCreate: false,
                       newPartnerId: null,
                       newPartnerName: null
                     })
                   }}>
            <h2 className={"text-primary"}>Új {t('project').toLowerCase()}</h2>
            <ProjectFormComponent method="post" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarProjectUpdate} closeOnEscape={true}
                   style={{width: '980px'}}
                   onHide={() => {
                     window.App.setState({
                       sidebarProjectUpdate: false,
                       newPartnerId: null,
                       newPartnerName: null
                     })
                   }}>
            <h2 className={"text-primary"}>{t('project')} módosítása</h2>
            <ProjectFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarProjectShow} closeOnEscape={true}
                   style={{width: '98%'}}
                   onHide={() => window.App.setState({sidebarProjectShow: false})}>
            {/*<h2 className={"text-primary"}>{t('project')} megtekintése</h2>*/}
            <ProjectShowComponent initialValues={window.App.state.props}/>
          </Sidebar>
          {/*<Sidebar position="left" visible={window.App.state.sidebarProductionitemShow} closeOnEscape={true}*/}
          {/*         style={{width: '750px'}}*/}
          {/*         onHide={() => {*/}
          {/*           window.App.setState({sidebarProductionitemShow: false})*/}
          {/*         }}>*/}
          {/*  <h2 className={"text-primary"}>Feladat megtekintése</h2>*/}
          {/*  <ProductionitemShowComponent initialValues={window.App.state.productionitemRowData} />*/}
          {/*</Sidebar>*/}
          <Sidebar position="left" visible={window.App.state.sidebarProjectitemJobsList} closeOnEscape={true}
                   style={{width: '980px'}}
                   onHide={() => {
                     window.App.setState({
                       sidebarProjectitemJobsList: false,
                     })
                   }}>
            <ProjectitemJobsListComponent/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarOfferCreate} closeOnEscape={true}
                   style={{width: '980px'}}
                   onHide={() => {
                     window.App.setState({
                       sidebarOfferCreate: false,
                     })
                   }}>
            <h2 className={"text-primary"}>Új árajánlat</h2>
            <OfferFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarWorkflowCreate} closeOnEscape={true}
                   style={{width: '980px'}}
                   onHide={() => {
                     window.App.setState({
                       sidebarWorkflowCreate: false,
                     })
                   }}>
            <h2 className={"text-primary"}>Új munkafolyamat</h2>
            <WorkflowFormComponent/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarWorkflowUpdate} closeOnEscape={true}
                   style={{width: '980px'}}
                   onHide={() => {
                     window.App.setState({
                       sidebarWorkflowUpdate: false,
                     })
                   }}>
            <h2 className={"text-primary"}>Munkafolyamat módosítása</h2>
            <WorkflowFormComponent/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarOfferView} closeOnEscape={true}
                   style={{width: '98%'}}
                   onHide={() => {
                     window.App.setState({sidebarOfferView: false})
                   }}>
            <OfferViewComponent initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarReceiptUpdate} closeOnEscape={true}
                   style={{width: '98%'}}
                   onHide={() => window.App.setState({
                     rerenderReceiptList: true,
                     sidebarReceiptUpdate: false,
                     newPartnerId: null,
                     newPartnerName: null
                   })}>
            <ReceiptFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          {/*<Sidebar position="left" visible={window.App.state.sidebarFulfillmentItemCreate} closeOnEscape={true}*/}
          {/*         style={{width: '980px'}}*/}
          {/*         onHide={() => {*/}
          {/*           window.App.setState({*/}
          {/*             sidebarFulfillmentItemCreate: false,*/}
          {/*           })*/}
          {/*         }}>*/}
          {/*  <h2 className={"text-primary"}>Új teljesítés tétel</h2>*/}
          {/*  <FullfillmentItemFormComponent method="post"/>*/}
          {/*</Sidebar>*/}
        </>
      ) : (
        <p className={"p-text-center p-my-5 p-py-5"}>
          <i className={"pi pi-exclamation-circle p-d-block p-mb-5"} style={{'fontSize': '5em'}}></i>
          <span className={"p-text-bold p-d-block "}>Ehhez a modulhoz sajnos nem rendelkezel hozzáféréssel.</span>
          Amennyiben további információra van szükséged írj emailt a {process.env.REACT_APP_DEVOPS_CONTACT} címre.
          {process.env.REACT_APP_SUBSCRIPTIONS === 'enabled' &&
          <span className={"p-text-bold p-d-block p-mt-5"}>
                       <a className={"p-button p-button-warning"}
                          onClick={() => {
                            window.location.replace('/#/subscriptions');
                            window.App.forceUpdate()
                          }}
                       >
                         <i className={"pi pi-building"}></i>&nbsp;Előfizetői fiók áttekintése
                       </a>
                   </span>
          }
        </p>
      )}
    </div>
  )
}
export default withTranslation('common')(ProjectDashboardPage);
