import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Tooltip} from "primereact/tooltip";

import PurchaseorderListService from "../../services/purchaseorder/PurchaseorderListService";
import moment from "moment";
import PurchaseorderStatusListService from "../../services/purchaseorder/PurchaseorderStatusListService";
import PurchaseorderDeleteService from "../../services/purchaseorder/PurchaseorderDeleteService";

const PurchaseorderListComponent = (props) => {

  const {t} = props;
  const [purchaseorder, setPurchaseorder] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [loading, setLoading] = useState(false);
  const dt = useRef(null);

  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(Number(process.env.REACT_APP_LIST_ROW_DEFAULT));
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: 1
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderPurchaseorderList: false})
      PurchaseorderListService(JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setPurchaseorder(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: Number(process.env.REACT_APP_LIST_ROW_OP1), value: Number(process.env.REACT_APP_LIST_ROW_OP1)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP2), value: Number(process.env.REACT_APP_LIST_ROW_OP2)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP3), value: Number(process.env.REACT_APP_LIST_ROW_OP3)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP4), value: Number(process.env.REACT_APP_LIST_ROW_OP4)},
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const deletePurchaseorder = (rowData) => {
    PurchaseorderDeleteService(rowData.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres törlés!',);
        loadLazyData();
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {localStorage.getItem('roles').toString().includes('role_purchase_order_write') &&
        <>
          {rowData.status === 'draft' ? (
            <>
              <Button icon="pi pi-pencil" className="  p-button-warning p-mr-2"
                      tooltip="Módosítás"
                      onClick={() => {
                        window.App.setState({
                          sidebarPurchaseorderUpdate: true,
                          props: rowData
                        })
                      }}/>
            </>
          ) : (
            <>
              <Button icon="pi pi-pencil" className=" disabled p-button-warning p-mr-2"
                      tooltip="Módosítás"/>
            </>
          )}
        </>
        }
        {localStorage.getItem('roles').toString().includes('role_purchase_order_read') &&
        <Button icon="pi pi-eye" className=" p-button p-mr-2"
                tooltip="Megtekintés"
                onClick={() => {
                  window.App.setState({
                    sidebarPurchaseorderShow: true,
                    props: rowData
                  })
                }}/>
        }
        {/*<Button icon="pi pi-euro" className="  p-mr-2"*/}
        {/*        tooltip="Árazás"*/}
        {/*        onClick={() => {*/}
        {/*          window.App.setState({*/}
        {/*            sidebarPurchaseorderPricing: true,*/}
        {/*            props: rowData*/}
        {/*          })*/}
        {/*        }}/>*/}
        {/*<Button icon="pi pi-shopping-cart" className="  p-mr-2"*/}
        {/*        tooltip="Bevételezés"*/}
        {/*        onClick={() => {*/}
        {/*          window.App.setState({*/}
        {/*            sidebarPurchaseorderStock: true,*/}
        {/*            props: rowData*/}
        {/*          })*/}
        {/*        }}/>*/}
        {localStorage.getItem('roles').toString().includes('role_purchase_order_write') &&
        <>
          {rowData.status === 'draft' ? (
            <Button icon="pi pi-trash" className=" p-button-danger p-mr-2"
                    tooltip="Törlés"
                    onClick={() => {
                      if (window.confirm('A törlés megerősítésére van szükség!')) {
                        deletePurchaseorder(rowData)
                      }
                    }}
            />
          ) : (
            <>
              <Button icon="pi pi-trash" className="  p-button-danger disabled  p-mr-2"
                      tooltip="Törlés"
              />
            </>
          )}
        </>
        }
      </React.Fragment>
    );
  }

  useEffect(() => {
    PurchaseorderStatusListService()
      .then(response => {
        setStatuses(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderPurchaseorderList === true])

  return (
    <>
      {props && props.view === 'simple' &&
        <>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6 p-col-align-center p-text-center p-text-lg-left"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-col-align-center p-text-center p-text-lg-right"}>
              {localStorage.getItem('roles').toString().includes('role_purchase_order_write') &&
                <a onClick={() => window.App.setState({sidebarPurchaseorderCreate: true})}
                   className={"p-button  p-button-success"}>
                  <i className={"pi pi-plus"}></i>
                  &nbsp;
                  Új beszerzési megrendelés
                </a>
              }
            </div>
          </div>
        </>
      }
      <DataTable size={localStorage.getItem('scale')}
        emptyMessage="Nincs találat."
        value={purchaseorder} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
        paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
        autoLayout={true}
        filterDisplay="row"
        responsiveLayout="scroll"
        ref={dt}
        stripedRows
      >
        <Column field="statusName" header="Státusz" sortable filter filterPlaceholder="Státusz"
                filterElement={() => {
                  return(
                    <>
                      {lazyParams && lazyParams.filters && lazyParams.filters.status ? (
                        <Dropdown options={statuses}
                                  id={"status"}
                                  optionValue={"id"}
                                  optionLabel={"name"}
                                  showClear
                                  emptyMessage={"Még nincs státusz..."}
                                  onChange={(e) => {
                                    dt.current.filter(e.value, 'status', '');
                                  }}
                                  value={lazyParams.filters.status.value}
                        />
                      ):(
                        <Dropdown options={statuses}
                                  id={"status"}
                                  optionValue={"id"}
                                  optionLabel={"name"}
                                  showClear
                                  emptyMessage={"Még nincs státusz..."}
                                  onChange={(e) => {
                                    dt.current.filter(e.value, 'status', '');
                                  }}
                        />
                      )}
                    </>
                  )
                }}
        ></Column>
        <Column field="orderNumber" header="Rendelés szám" sortable filter filterPlaceholder="Rendelés szám"></Column>
        <Column field="projectNames" header={t('projects')} sortable filter filterPlaceholder={t('projects')}></Column>
        <Column field="createdAt" header="Létrehozva" sortable body={(rowData) => {
          return (
            <>{moment.utc(rowData.createdAt).local().format('YYYY-MM-DD HH:mm:ss')}</>
          )
        }}></Column>
        <Column field="contactName" header="Kapcsolat" sortable filter filterPlaceholder="Kapcsolat"></Column>
        <Column body={actionBodyTemplate} className={"p-py-0 p-text-right p-text-nowrap"}></Column>
      </DataTable>
    </>
  )
}
export default withTranslation('common')(PurchaseorderListComponent);
