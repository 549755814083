import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";

import ProjecttypeFormService from '../../services/projecttype/ProjecttypeFormService'
import {InputSwitch} from "primereact/inputswitch";
import ProjecttypeListService from "../../services/projecttype/ProjecttypeListService";
import ProjecttypeShowService from "../../services/projecttype/ProjecttypeShowService";

const ProjecttypeFormComponent = (props) => {

  //form
  const [formData, setFormData] = useState({});
  const [fields, setFields] = useState({});
  const [loading, setLoading] = useState(false);
  const validate = (data) => {
    let errors = {};
    // if (!data.name) {
    //   errors.name = 'Az megnevezés nem lehet üres';
    // }
    return errors;
  };
  const onSubmit = (data, form) => {
    let _data = {
      ...data,
      fields: fields
    }
    setLoading(true)
    setFormData({
      ...formData,
      _data
    });
    if (props && props.initialValues) {
      ProjecttypeFormService(_data, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({
            rerenderProjecttypeList: true,
            sidebarProjecttypeUpdate: false
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      ProjecttypeFormService(_data, 'post')
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          window.App.setState({
            rerenderProjecttypeList: true,
            sidebarProjecttypeCreate: false
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  useEffect(() => {
    if(props && props.initialValues) {
      setLoading(true)
      ProjecttypeShowService(props.initialValues.id)
        .then(response => {
          setFormData(response.data)
          if(response && response.data && response.data.fields) {
            setFields(response.data.fields)
          }
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-12 p-xl-12"}>
              <Field name="name" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="name"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Megnevezés</label>
                  <span className="p-d-block">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid p-mt-2"}>
            <div className={"p-col-12"}>
              <h3 className={"p-text-secondary"}>
                <i className={"pi pi-file"}></i> Mezők
              </h3>
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="location" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="location"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Lokáció</label>
                  <span className="p-d-block">
                        <InputSwitch
                          className={"p-mt-1"}
                          checked={fields.location}
                          value={fields.location}
                          onChange={e => setFields({...fields, location: e.target.value})}/>
                      </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="startedAt" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="startedAt"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Munkakezdés ideje</label>
                  <span className="p-d-block">
                        <InputSwitch
                          className={"p-mt-1"}
                          checked={fields.startedAt}
                          value={fields.startedAt}
                          onChange={e => setFields({...fields, startedAt: e.target.value})}/>
                      </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="guaranteeEndAt" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="guaranteeEndAt"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Garancia lejárat</label>
                  <span className="p-d-block">
                        <InputSwitch
                          className={"p-mt-1"}
                          checked={fields.guaranteeEndAt}
                          value={fields.guaranteeEndAt}
                          onChange={e => setFields({...fields, guaranteeEndAt: e.target.value})}/>
                      </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="deadline" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="deadline"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Határidő</label>
                  <span className="p-d-block">
                        <InputSwitch
                          className={"p-mt-1"}
                          checked={fields.deadline}
                          value={fields.deadline}
                          onChange={e => setFields({...fields, deadline: e.target.value})}/>
                      </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="netAmount" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="netAmount"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Nettó ár</label>
                  <span className="p-d-block">
                        <InputSwitch
                          className={"p-mt-1"}
                          checked={fields.netAmount}
                          value={fields.netAmount}
                          onChange={e => setFields({...fields, netAmount: e.target.value})}/>
                      </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="warehouse" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="warehouse"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Alepértelmezett raktár</label>
                  <span className="p-d-block">
                        <InputSwitch
                          className={"p-mt-1"}
                          checked={fields.warehouse}
                          value={fields.warehouse}
                          onChange={e => setFields({...fields, warehouse: e.target.value})}/>
                      </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="contractNumber" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="contractNumber"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Szerződésszám</label>
                  <span className="p-d-block">
                        <InputSwitch
                          className={"p-mt-1"}
                          checked={fields.contractNumber}
                          value={fields.contractNumber}
                          onChange={e => setFields({...fields, contractNumber: e.target.value})}/>
                      </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
        </Panel>
        <div className={"p-grid p-mt-1"}>
          <div className={"p-col-12 p-lg-6 p-xl-6"}>

          </div>
          <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
            <Button type="submit" label="Mentés" icon={"pi pi-check"} loading={loading}
                    className="p-button-success"/>
          </div>
        </div>
      </form>
    )}/>
  )
}
export default withTranslation('common')(ProjecttypeFormComponent);
