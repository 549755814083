import React, {useState, useEffect} from 'react';
import axios from "axios";
import {withTranslation} from 'react-i18next';
import moment from "moment";
import {Chart} from 'primereact/chart';
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";

const TracklogListComponent = (props) => {

  const {t} = props;
  const [measures, setMeasures] = useState([]);
  const [tracklogs, setTracklogs] = useState([]);
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(Number(process.env.REACT_APP_LIST_ROW_DEFAULT));
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderTracklogList: false})
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_HOST + "/track_log/" + props.metricsId + "/?" + JSON.stringify(lazyParams).replace('"multiSortMeta":[],', ''),
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          'App-Network': localStorage.getItem("networkId")
        }
      })
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setTracklogs(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: Number(process.env.REACT_APP_LIST_ROW_OP1), value: Number(process.env.REACT_APP_LIST_ROW_OP1)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP2), value: Number(process.env.REACT_APP_LIST_ROW_OP2)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP3), value: Number(process.env.REACT_APP_LIST_ROW_OP3)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP4), value: Number(process.env.REACT_APP_LIST_ROW_OP4)},
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  useEffect(() => {
    loadLazyData();
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_HOST + "/metrics/" + props.metricsId,
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        'App-Network': localStorage.getItem("networkId")
      }
    })
      .then(response => {
        setMeasures(response.data.measures);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [lazyParams, window.App.state.rerenderTracklogList === true])

  return (
    <>
      <>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-6 p-col-align-center p-text-center p-text-lg-left"}>

          </div>
          <div className={"p-col-12 p-lg-6 p-col-align-center p-text-center p-text-lg-right"}>
            <a onClick={() => {
              window.App.setState({
                sidebarTracklogImport: true,
                propsTracklog: {id: props.metricsId}
              })
            }}
               className={"p-button  p-button-success"}>
              <i className={"pi pi-upload"}></i>
              &nbsp;
              Adatok importálása
            </a>
          </div>
        </div>
      </>
      <DataTable size={localStorage.getItem('scale')}
        emptyMessage="Nincs találat."
        value={tracklogs}
        paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} lazy
        paginatorTemplate={pager} rows={rows}
        loading={loading}
        emptyMessage="Nincs találat."
        autoLayout={true}
        filterDisplay="row"
        responsiveLayout="scroll"
        stripedRows
        dataKey="id"
      >
        <Column field="projectName" header={t('project')}></Column>
        <Column field="createdAt" sortable header="Létrehozva"
                className={"p-text-nowrap"}
                body={(rowData) => {
                  if (rowData.createdAt) {
                    return (
                      <>{moment.utc(rowData.createdAt).local().format('YYYY-MM-DD HH:mm:ss')}</>
                    )
                  }
                }}
        ></Column>
        {measures.map((measureItem, index) => {
          return (
            <Column header={measureItem.name} body={(data) => {
              let value = data.measures.filter(item => item.id === measureItem.id)
              if (value[0] && value[0] != undefined) {
                return (
                  <>
                    {value[0].value}
                  </>
                )
              } else {
                return (
                  <>
                    &nbsp;
                  </>
                )
              }
            }}></Column>
          )
        })}
      </DataTable>
    </>
  )
}
export default withTranslation('common')(TracklogListComponent);
