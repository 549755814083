import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Tooltip} from "primereact/tooltip";

import PartnerListService from "../../services/partner/PartnerListService";
import PartnerDeleteService from "../../services/partner/PartnerDeleteService";

const PartnerListComponent = (props) => {

  const [partner, setPartner] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterType, setFilterType] = useState();

  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(Number(process.env.REACT_APP_LIST_ROW_DEFAULT));
  const dt = useRef(null);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'name',
    sortOrder: -1,
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderPartnerList: false})
      PartnerListService(JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setPartner(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: Number(process.env.REACT_APP_LIST_ROW_OP1), value: Number(process.env.REACT_APP_LIST_ROW_OP1)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP2), value: Number(process.env.REACT_APP_LIST_ROW_OP2)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP3), value: Number(process.env.REACT_APP_LIST_ROW_OP3)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP4), value: Number(process.env.REACT_APP_LIST_ROW_OP4)},
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const deletePartner = (rowData) => {
    PartnerDeleteService(rowData.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres törlés!',);
        loadLazyData();
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {localStorage.getItem('roles').toString().includes('role_partner_write') &&
          <Button icon="pi pi-pencil" className="  p-button-warning p-mr-2"
                  tooltip="Módosítás"
                  onClick={() => {
                    window.App.setState({
                      sidebarPartnerUpdate: true,
                      props: rowData
                    })
                  }}/>
        }
        {/*{localStorage.getItem('roles').toString().includes('role_partner_write') &&*/}
        {/*<Button icon="pi pi-trash" className="  p-button-danger"*/}
        {/*        tooltip="Törlés"*/}
        {/*        onClick={() => {*/}
        {/*          if (window.confirm('A törlés megerősítésére van szükség!')) {*/}
        {/*            deletePartner(rowData)*/}
        {/*          }*/}
        {/*        }}*/}
        {/*/>*/}
        {/*}*/}
      </React.Fragment>
    );
  }

  useEffect(() => {
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderPartnerList === true])

  return (
    <>
      <div className={"p-grid"}>
        <div className={"p-col-12 p-lg-6 p-col-align-center p-text-center p-text-lg-left"}>

        </div>
        <div className={"p-col-12 p-lg-6 p-col-align-center p-text-center p-text-lg-right"}>
          {localStorage.getItem('roles').toString().includes('role_partner_write') &&
            <a onClick={() => window.App.setState({sidebarPartnerCreate: true})}
               className={"p-button  p-button-success"}>
              <i className={"pi pi-plus"}></i>
              &nbsp;
              Új partner
            </a>
          }
        </div>
      </div>
      <DataTable size={localStorage.getItem('scale')}
        emptyMessage="Nincs találat."
        value={partner} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
        paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
        autoLayout={true}
        filterDisplay="row"
        responsiveLayout="scroll"
        stripedRows
        ref={dt}
      >
        <Column field="name" header="Név" sortable filter filterPlaceholder="Név"></Column>
        <Column field="vatNumber" header="Adószám" sortable filter filterPlaceholder="Adószám"></Column>
        <Column field="costomer" header="Vevő" sortable className={"p-text-center"} body={(rowData) => {
          return (
            <>
              {rowData.customer === true ? (
                <i className={"pi pi-check text-success"}></i>
              ) : (
                <i className={"pi pi-times text-danger"}></i>
              )}
            </>
          )
        }}
                filter
                filterElement={() => {
                  return (
                    <>
                      {lazyParams && lazyParams.filters && lazyParams.filters.customer ? (
                        <Dropdown options={[
                          {
                            value: true,
                            name: "Igen"
                          },
                          {
                            value: false,
                            name: "Nem"
                          },
                        ]}
                                  id={"custmer"}
                                  optionValue={"value"}
                                  optionLabel={"name"}
                                  showClear
                                  onChange={(e) => {
                                    dt.current.filter(e.value, 'customer', '');
                                  }}
                                  value={lazyParams.filters.customer.value}
                        />
                      ) : (
                        <Dropdown options={[
                          {
                            value: true,
                            name: "Igen"
                          },
                          {
                            value: false,
                            name: "Nem"
                          },
                        ]}
                                  id={"custmer"}
                                  optionValue={"value"}
                                  optionLabel={"name"}
                                  showClear
                                  onChange={(e) => {
                                    dt.current.filter(e.value, 'customer', '');
                                  }}
                        />
                      )}
                    </>
                  )
                }}
        ></Column>
        <Column field="type" header="Típus" sortable className={"p-text-center"} body={(rowData) => {
          return (
            <>
              {rowData.type === "person" &&
                <>
                  Személy
                </>
              }
              {rowData.type === "organisation" &&
                <>
                  Cég
                </>
              }
              {rowData.type === "other" &&
                <>
                  Egyéb
                </>
              }
            </>
          )
        }}
                filter
                filterElement={() => {
                  return (
                    <>
                      {lazyParams && lazyParams.filters && lazyParams.filters.type ? (
                        <Dropdown options={[
                          {
                            name: "Személy",
                            id: "person"
                          },
                          {
                            name: "Cég",
                            id: "organisation"
                          },
                          {
                            name: "Egyéb",
                            id: "other"
                          },
                        ]}
                                  id={"type"}
                                  optionValue={"id"}
                                  optionLabel={"name"}
                                  showClear
                                  onChange={(e) => {
                                    dt.current.filter(e.value, 'type', '');
                                  }}
                                  value={lazyParams.filters.type.value}
                        />
                      ) : (
                        <Dropdown options={[
                          {
                            name: "Személy",
                            id: "person"
                          },
                          {
                            name: "Cég",
                            id: "organisation"
                          },
                          {
                            name: "Egyéb",
                            id: "other"
                          },
                        ]}
                                  id={"type"}
                                  optionValue={"id"}
                                  optionLabel={"name"}
                                  showClear
                                  onChange={(e) => {
                                    dt.current.filter(e.value, 'type', '');
                                  }}
                        />
                      )}
                    </>
                  )
                }}
        ></Column>
        <Column field="vendor" header="Szállító" sortable className={"p-text-center"} body={(rowData) => {
          return (
            <>
              {rowData.vendor === true ? (
                <i className={"pi pi-check text-success"}></i>
              ) : (
                <i className={"pi pi-times text-danger"}></i>
              )}
            </>
          )
        }}
                filter
                filterElement={() => {
                  return (
                    <>
                      {lazyParams && lazyParams.filters && lazyParams.filters.vendor ? (
                        <Dropdown options={[
                          {
                            value: true,
                            name: "Igen"
                          },
                          {
                            value: false,
                            name: "Nem"
                          },
                        ]}
                                  id={"vendor"}
                                  optionValue={"value"}
                                  optionLabel={"name"}
                                  showClear
                                  onChange={(e) => {
                                    dt.current.filter(e.value, 'vendor', '');
                                  }}
                                  value={lazyParams.filters.vendor.value}
                        />
                      ) : (
                        <Dropdown options={[
                          {
                            value: true,
                            name: "Igen"
                          },
                          {
                            value: false,
                            name: "Nem"
                          },
                        ]}
                                  id={"vendor"}
                                  optionValue={"value"}
                                  optionLabel={"name"}
                                  showClear
                                  onChange={(e) => {
                                    dt.current.filter(e.value, 'vendor', '');
                                  }}
                        />
                      )}
                    </>
                  )
                }}
        ></Column>
        <Column field="active" header="Státusz" sortable className={"p-text-center"} body={(rowData) => {
          return (
            <>
              {rowData.active === true ? (
                <i className={"pi pi-check text-success"}></i>
              ) : (
                <i className={"pi pi-times text-danger"}></i>
              )}
            </>
          )
        }}
                filter
                filterElement={() => {
                  return (
                    <>
                      {lazyParams && lazyParams.filters && lazyParams.filters.active ? (
                        <Dropdown options={[
                          {
                            value: true,
                            name: "Aktív"
                          },
                          {
                            value: false,
                            name: "Inaktív"
                          },
                        ]}
                                  id={"active"}
                                  optionValue={"value"}
                                  optionLabel={"name"}
                                  showClear
                                  emptyMessage={"Még nincs státusz..."}
                                  onChange={(e) => {
                                    dt.current.filter(e.value, 'active', '');
                                  }}
                                  value={lazyParams.filters.active.value}
                        />
                      ) : (
                        <Dropdown options={[
                          {
                            value: true,
                            name: "Aktív"
                          },
                          {
                            value: false,
                            name: "Inaktív"
                          },
                        ]}
                                  id={"active"}
                                  optionValue={"value"}
                                  optionLabel={"name"}
                                  showClear
                                  emptyMessage={"Még nincs státusz..."}
                                  onChange={(e) => {
                                    dt.current.filter(e.value, 'active', '');
                                  }}
                        />
                      )}
                    </>
                  )
                }}
        ></Column>
        {props.view === 'advanced' &&
          <Column field="addressCountryCode" header="Nemzetiség" sortable></Column>
        }
        {props.view === 'advanced' &&
          <Column field="addressPostalCode" header="IRSZ" sortable></Column>
        }
        {props.view === 'advanced' &&
          <Column field="addressCity" header="Város" sortable></Column>
        }
        {props.view === 'advanced' &&
          <Column field="addressDetail" header="Cím" sortable></Column>
        }
        <Column body={actionBodyTemplate} className={"p-py-0 p-text-right p-text-nowrap"}></Column>
      </DataTable>
    </>
  )
}
export default withTranslation('common')(PartnerListComponent);
