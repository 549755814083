import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Card} from "primereact/card";
import {Sidebar} from "primereact/sidebar";
import {TabView, TabPanel} from 'primereact/tabview';

import EmployeeFormComponent from "../../components/employee/EmployeeFormComponent";
import EmployeeListComponent from "../../components/employee/EmployeeListComponent";
import EmployeeViewComponent from "../../components/employee/EmployeeViewComponent";
import EmployeeImportComponent from "../../components/employee/EmployeeImportComponent";
import EmployeepositionListComponent from "../../components/employeeposition/EmployeepositionListComponent";
import EmployeepositionFormComponent from "../../components/employeeposition/EmployeepositionFormComponent";
import HrcalendarListComponent from "../../components/hrcalendar/HrcalendarListComponent";
import HrcalendarFormComponent from "../../components/hrcalendar/HrcalendarFormComponent";
import SchedulegroupListComponent from "../../components/schedulegroup/SchedulegroupListComponent";
import SchedulegroupFormComponent from "../../components/schedulegroup/SchedulegroupFormComponent";
import ScheduleFormComponent from "../../components/schedule/ScheduleFormComponent";
import SchedulegroupShowComponent from "../../components/schedulegroup/SchedulegroupShowComponent";
import ScheduleShowComponent from "../../components/schedule/ScheduleShowComponent";

export const HrDashboardPage = () => {

  useEffect(() => {
    window.App.pageHeight()
    document.title = 'HR' + ' - ' + process.env.REACT_APP_TITLE;
    setTimeout(function () {
      if (document.getElementsByClassName('activeMenu')[0]) {
        document.getElementsByClassName('activeMenu')[0].classList.remove("activeMenu")
      }
      if (document.getElementsByClassName('hr')[0]) {
        document.getElementsByClassName('hr')[0].classList.add("activeMenu")
      }
    }, 1000)
     if(
      document.getElementById('tabScroll')
      && document.getElementById('tabScroll').children
      && document.getElementById('tabScroll').children[0]
      && document.getElementById('tabScroll').children[0].querySelector("li:not(.p-disabled)").children[0]
    ) {
      document.getElementById('tabScroll').children[0].querySelector("li:not(.p-disabled)").children[0].click()
    }
  }, []);

  return (
    <div className="ModuleDashboardPage DashboardPage p-component p-p-3 ">
      {localStorage.getItem('module_hr') ? (
        <>
          <div className={"p-grid p-d-flex p-flex-wrap"}>
            <div className={"p-col-12 p-lg-12 p-xl-12 p-p-0 left p-position-relative"}>
              <TabView renderActiveOnly="true" className={"nav"} scrollable={window.App.state.tabScroll} id="tabScroll">
                {localStorage.getItem('roles').toString().includes('role_employee_read') ? (
                  <TabPanel header="Munkavállalók">
                    <div className={"p-px-2 p-pb-2"}>
                      <EmployeeListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_employee_position_read') ? (
                  <TabPanel header="Beosztások">
                    <div className={"p-px-2 p-pb-2"}>
                      <EmployeepositionListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_hr_calendar_read') ? (
                  <TabPanel header="HR naptár">
                    <div className={"p-px-2 p-pb-2"}>
                      <HrcalendarListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_schedule_read') ? (
                  <TabPanel header="Műszakok">
                    <div className={"p-px-2 p-pb-2"}>
                      <SchedulegroupListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
              </TabView>
            </div>
          </div>
          <Sidebar position="left" visible={window.App.state.sidebarEmployeeCreate} closeOnEscape={true}
                   style={{width: '980px'}}
                   onHide={() => {
                     window.App.setState({sidebarEmployeeCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új munkavállaló</h2>
            <EmployeeFormComponent method="post"/>
          </Sidebar>
          {/*<Sidebar position="left" visible={window.App.state.sidebarEmployeeUpdate} closeOnEscape={true}*/}
          {/*         style={{width: '980px'}}*/}
          {/*         onHide={() => {*/}
          {/*           window.App.setState({sidebarEmployeeUpdate: false})*/}
          {/*         }}>*/}
          {/*  <h2 className={"text-primary"}>Munkavállaló módosítása</h2>*/}
          {/*  <EmployeeFormComponent method="put" initialValues={window.App.state.props}/>*/}
          {/*</Sidebar>*/}
          <Sidebar position="left" visible={window.App.state.sidebarEmployeeView} closeOnEscape={true}
                   style={{width: '980px'}}
                   onHide={() => {
                     window.App.setState({sidebarEmployeeView: false})
                   }}>
            <EmployeeViewComponent initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarEmployeeImport} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarEmployeeImport: false})
                   }}>
            <h2 className={"text-primary"}>Munkavállalók importálása</h2>
            <EmployeeImportComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarEmployeepositionCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarEmployeepositionCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új beosztás</h2>
            <EmployeepositionFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarEmployeepositionUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarEmployeepositionUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Beosztás módosítása</h2>
            <EmployeepositionFormComponent method="post" initialValues={window.App.state.props}/>
          </Sidebar>

          <Sidebar position="left" visible={window.App.state.sidebarHrcalendarCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarHrcalendarCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új esemény</h2>
            <HrcalendarFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarHrcalendarUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarHrcalendarUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Esemény módosítása</h2>
            <HrcalendarFormComponent method="post" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarSchedulegroupCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarSchedulegroupCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új műszak</h2>
            <SchedulegroupFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarSchedulegroupUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarSchedulegroupUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Műszak módosítása</h2>
            <SchedulegroupFormComponent method="post" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarScheduleCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarScheduleCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új műszak</h2>
            <ScheduleFormComponent initialValues={window.App.state.props} method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarSchedulegroupShow} closeOnEscape={true}
                   style={{width: '98%'}}
                   onHide={() => {
                     window.App.setState({sidebarSchedulegroupShow: false})
                   }}>
            <h2 className={"text-primary"}>Műszak megtekintése</h2>
            <SchedulegroupShowComponent initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarScheduleShow} closeOnEscape={true}
                   style={{width: '98%'}}
                   onHide={() => {
                     window.App.setState({sidebarScheduleShow: false})
                   }}>
            <h2 className={"text-primary"}>Műszak megtekintése</h2>
            <ScheduleShowComponent initialValues={window.App.state.props}/>
          </Sidebar>
        </>
      ) : (
        <p className={"p-text-center p-my-5 p-py-5"}>
          <i className={"pi pi-exclamation-circle p-d-block p-mb-5"} style={{'fontSize': '5em'}}></i>
          <span className={"p-text-bold p-d-block "}>Ehhez a modulhoz sajnos nem rendelkezel hozzáféréssel.</span>
          Amennyiben további információra van szükséged írj emailt a {process.env.REACT_APP_DEVOPS_CONTACT} címre.
          {process.env.REACT_APP_SUBSCRIPTIONS === 'enabled' &&
          <span className={"p-text-bold p-d-block p-mt-5"}>
                       <a className={"p-button p-button-warning"}
                          onClick={() => {
                            window.location.replace('/#/subscriptions');
                            window.App.forceUpdate()
                          }}
                       >
                         <i className={"pi pi-building"}></i>&nbsp;Előfizetői fiók áttekintése
                       </a>
                   </span>
          }
        </p>
      )}
    </div>
  )
}
export default withTranslation('common')(HrDashboardPage);
