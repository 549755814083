import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {PickList} from 'primereact/picklist';
import {Panel} from "primereact/panel";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {FileUpload} from "primereact/fileupload";
import {Dialog} from "primereact/dialog";
import {Dropdown} from "primereact/dropdown";

import CompanyFormService from '../../services/company/CompanyFormService'
import CountryListService from "../../services/country/CountryListService";
import CompanydocumentFormService from "../../services/companydocument/CompanydocumentFormService";
import CompanydocumentListService from "../../services/companydocument/CompanydocumentListService";
import CompanycontactListService from "../../services/companycontact/CompanycontactListService";
import CompanycontactFormService from "../../services/companycontact/CompanycontactFormService";
import {AutoComplete} from "primereact/autocomplete";
import {Message} from "primereact/message";
import CompanyLogoUploadService from "../../services/company/CompanyLogoUploadService";
import CompanyShowService from "../../services/company/CompanyShowService";
import CompanyLogoDeleteService from "../../services/company/CompanyLogoDeleteService";
import CompanyNavAddService from "../../services/company/CompanyNavAddService";
import {InputSwitch} from "primereact/inputswitch";
import CompanytypeListService from "../../services/companytype/CompanytypeListService";

const CompanyFormComponent = (props) => {

  const [formData, setFormData] = useState({
    addressCountryCode: "HU",
  });
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);

  const validate = (data) => {
    let errors = {};
    // if (!data.name) {
    //   errors.name = 'Az megnevezés nem lehet üres';
    // }
    // if (!data.vatNumber) {
    //   errors.vatNumber = 'Az adószám nem lehet üres';
    // }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if (props && props.initialValues) {
      CompanyFormService(data, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({
            rerenderCompanyList: true,
            sidebarCompanyUpdate: false
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      CompanyFormService(data, 'post')
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          window.App.setState({
            rerenderCompanyList: true,
            sidebarCompanyCreate: false
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // documents
  const [documents, setDocuments] = useState([]);
  const [documentsLoading, setDocumentsLoading] = useState(false);

  const documentValidate = (data) => {
    let errors = {};
    if (!data.name) {
      errors.name = 'Kötelező mező';
    }
    if (documentFormMethod === 'post') {
      if (!data.file) {
        errors.file = 'Kötelező mező';
      }
    }
    return errors;
  };

  const documentActionBody = (rowData, row) => {
    return (
      <React.Fragment>
        <Button type="button" icon="pi pi-pencil" className="  p-button-warning p-mr-2"
                tooltip="Módosítás"
                onClick={() => {
                  setDocumentDialog(true)
                  setDocumentFormData({...rowData, rowIndex: row.rowIndex})
                  setDocumentFormMethod('put')
                  window.App.setState({props: rowData.id})
                }}
        />
        {/*<Button type="button" icon="pi pi-trash" className="  p-button-danger"*/}
        {/*        tooltip="Törlés"*/}
        {/*        onClick={() => {*/}
        {/*          if (window.confirm('A törlés megerősítésére van szükség!')) {*/}
        {/*            documentDelete(rowData, row)*/}
        {/*          }*/}
        {/*        }}*/}
        {/*/>*/}
      </React.Fragment>
    );
  }
  const [documentDialog, setDocumentDialog] = useState(false);
  const [documentFormData, setDocumentFormData] = useState({}); // set on dialog open!
  const [documentFormMethod, setDocumentFormMethod] = useState('post');

  const documentOnSubmit = (data, form) => {
    setDocumentsLoading(true)
    CompanydocumentFormService(data, documentFormMethod, formData.id, documentFormData.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres mentés!');
        CompanydocumentListService('', formData.id)
          .then(response => {
            setDocuments(response.data.items)
          })
          .catch(error => {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          })
        setDocumentDialog(false)
        setDocumentsLoading(false)
        setDocumentFormData({})
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setDocumentsLoading(false)
      })
  }

  const documentDelete = (data, row) => {

  }


  const [documentImgDialog, setDocumentImgDialog] = useState(false);

  // image for document
  const documentUpload = (files) => {
    var reader = new FileReader();
    let name = files.files[0].name
    reader.onloadend = () => {
      var base64data = reader.result;
      setDocumentFormData({
        ...documentFormData,
        file: base64data,
        originalFileName: name
      })
    }
    let image = reader.readAsDataURL(files.files[0]);
  }

  // contacts
  const [contacts, setContacts] = useState([]);
  const [contactsLoading, setContactsLoading] = useState(false);

  const contactValidate = (data) => {
    let errors = {};
    if (!data.name) {
      errors.name = 'Kötelező mező';
    }
    return errors;
  };

  const contactActionBody = (rowData, row) => {
    return (
      <React.Fragment>
        <Button type="button" icon="pi pi-pencil" className="  p-button-warning p-mr-2"
                tooltip="Módosítás"
                onClick={() => {
                  setContactDialog(true)
                  setContactFormData({...rowData, rowIndex: row.rowIndex})
                  setContactFormMethod('put')
                  window.App.setState({props: rowData.id})
                }}
        />
        {/*<Button type="button" icon="pi pi-trash" className="  p-button-danger"*/}
        {/*        tooltip="Törlés"*/}
        {/*        onClick={() => {*/}
        {/*          if (window.confirm('A törlés megerősítésére van szükség!')) {*/}
        {/*            contactDelete(rowData, row)*/}
        {/*          }*/}
        {/*        }}*/}
        {/*/>*/}
      </React.Fragment>
    );
  }
  const [contactDialog, setContactDialog] = useState(false);
  const [contactFormData, setContactFormData] = useState({}); // set on dialog open!
  const [contactFormMethod, setContactFormMethod] = useState('post');

  const contactOnSubmit = (data, form) => {
    setContactsLoading(true)
    CompanycontactFormService(data, contactFormMethod, formData.id, contactFormData.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres mentés!');
        CompanycontactListService('', formData.id)
          .then(response => {
            setContacts(response.data.items)
          })
          .catch(error => {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          })
        setContactDialog(false)
        setContactsLoading(false)
        setContactFormData({})
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setContactsLoading(false)
      })
  }

  const contactDelete = (data, row) => {

  }

  // image
  const imageUpload = (files) => {
    var reader = new FileReader();
    reader.onloadend = () => {
      let base64data = reader.result;
      // setFormData({
      //   ...formData,
      //   logo: base64data.toString()//.replace(/^data:(.*,)?/, '')
      // })
      setTimeout(function () {
        CompanyLogoUploadService(formData.id, base64data.toString().replace(/^data:(.*,)?/, ''))
          .then(response => {
            window.App.toastShow('success', 'Sikeres feltöltés');
            CompanyShowService(props.initialValues.id)
              .then(response => {
                setFormData(response.data)
                window.App.forceUpdate()
              })
              .catch(error => {
                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
              })
          })
          .catch(error => {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          })
      }, 500)
    }
    reader.readAsDataURL(files.files[0])
  }

  // company types
  const [companyTypes, setCompanyTypes] = useState([]);

  useEffect(() => {
    CompanytypeListService()
      .then(response => {
        setCompanyTypes(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    CountryListService(JSON.stringify({
      first: 0,
      rows: 9999,
      page: 0,
    }))
      .then(response => {
        setCountries(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    if ((props.initialValues && props.initialValues.id) || formData.id) {
      let id
      if (formData.id) {
        id = formData.id
      }
      if (props.initialValues.id) {
        id = props.initialValues.id
      }
      CompanydocumentListService('', id)
        .then(response => {
          setDocuments(response.data.items)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
      CompanycontactListService('', id)
        .then(response => {
          setContacts(response.data.items)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }
    if (props && props.initialValues) {
      CompanyShowService(props.initialValues.id)
        .then(response => {
          setFormData(response.data)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }
  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit, submitting}) => (
      <form onSubmit={handleSubmit} className="">
        {localStorage.getItem('roles').toString().includes('role_company_update') &&
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-8"}>
              <Field name="name" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="name"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Megnevezés</label>
                  <span className="p-d-block">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="type" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="code"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Cégforma</label>
                  <span className="p-d-block">
                      <Dropdown options={companyTypes}
                                {...input}
                                optionLabel="name"
                                optionValue="id"
                                id={"type"}
                                emptyMessage={"Még nincs cégforma..."}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    type: e.value
                                  })
                                }}
                                value={formData.type}
                                className={classNames({'p-invalid': isFormFieldValid(meta)})}
                      />
                      </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="vatNumber" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="vatNumber"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Adószám</label>
                  <span className="p-d-block">
                      <InputText id="vatNumber" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     vatNumber: e.target.value
                                   })
                                 }}
                                 value={formData.vatNumber}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="communityVatNumber" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="communityVatNumber"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Közösségi adószám</label>
                  <span className="p-d-block">
                      <InputText id="communityVatNumber"
                                 {...input}
                                 value={formData.communityVatNumber}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     communityVatNumber: e.target.value
                                   })
                                 }}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="registrationNumber" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="registrationNumber"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Cégjegyzékszám</label>
                  <span className="p-d-block">
                      <InputText id="registrationNumber"
                                 {...input}
                                 value={formData.registrationNumber}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     registrationNumber: e.target.value
                                   })
                                 }}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="email" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="email"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Központi email</label>
                  <span className="p-d-block">
                      <InputText id="email" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     email: e.target.value
                                   })
                                 }}
                                 value={formData.email}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="receiptOtherData" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="receiptOtherData"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Számlán feltűntetedő adat</label>
                  <span className="p-d-block">
                      <InputText id="receiptOtherData" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     receiptOtherData: e.target.value
                                   })
                                 }}
                                 value={formData.receiptOtherData}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="taxFree" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="taxFree"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Alanyi adómentes</label>
                  <span className="p-d-block">
                        <InputSwitch
                          className={"p-mt-1"}
                          checked={formData.taxFree}
                          value={formData.taxFree}
                          onChange={e => setFormData({...formData, taxFree: e.target.value})}/>
                      </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid p-mt-1"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-map-marker"}></i> Székhely
              </h3>
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-md-3 p-lg-3"}>
              <Field name="addressCountryCode" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="addressCountryCode"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Ország</label>
                  <span className="p-d-block">
                    <Dropdown options={countries}
                              optionLabel="name"
                              optionValue="code"
                              id={"addressCountryCode"}
                              emptyMessage={"Még nincs ország..."}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  addressCountryCode: e.value
                                })
                              }}
                              value={formData.addressCountryCode}
                    />
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-md-2 p-lg-2"}>
              <Field name="addressPostalCode" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="addressPostalCode"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>IRSZ</label>
                  <span className="p-d-block">
                      <InputText id="addressPostalCode" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     addressPostalCode: e.target.value
                                   })
                                 }}
                                 value={formData.addressPostalCode}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-md-3 p-lg-3"}>
              <Field name="addressCity" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="addressCity"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Város</label>
                  <span className="p-d-block">
                      <InputText id="addressCity" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     addressCity: e.target.value
                                   })
                                 }}
                                 value={formData.addressCity}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-md-4 p-lg-4"}>
              <Field name="addressDetail" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="addressDetail"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Cím</label>
                  <span className="p-d-block">
                      <InputText id="addressDetail" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     addressDetail: e.target.value
                                   })
                                 }}
                                 value={formData.addressDetail}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid p-mt-1"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              <Button type="submit" label="Mentés" icon="pi pi-check" loading={loading}
                      className="p-button-success"/>
            </div>
          </div>
        </Panel>
        }
        {formData.id && process.env.REACT_APP_COMPANY_NAV_INTERFACE === 'true' &&
        <>
          <Panel headerTemplate={
            <div className={"p-grid"}>
              <div className={"p-col-12"}>
                <h3>
                  <i className={"pi pi-sync"}></i> NAV Interfész
                </h3>
              </div>
            </div>
          } className={"p-mt-3"}>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-6"}>
                <Field name="navUserName" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="navUserName"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>NAV felhasználónév</label>
                    <span className={"p-d-block"}>
                      <InputText id="navUserName" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     navUserName: e.target.value
                                   })
                                 }}
                                 value={formData.navUserName}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col-12 p-lg-6"}>
                <Field name="navUserPass" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="navUserPass"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>NAV jelszó</label>
                    <span className={"p-d-block"}>
                      <InputText id="navUserPass" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     navUserPass: e.target.value
                                   })
                                 }}
                                 value={formData.navUserPass}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col-12 p-lg-6"}>
                <Field name="navXMLSignKey" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="navXMLSignKey"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>XML aláírókulcs</label>
                    <span className={"p-d-block"}>
                      <InputText id="navXMLSignKey" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     navXMLSignKey: e.target.value
                                   })
                                 }}
                                 value={formData.navXMLSignKey}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col-12 p-lg-6"}>
                <Field name="navXMLExchangeKey" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="navXMLExchangeKey"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>XML cserekulcs</label>
                    <span className={"p-d-block"}>
                      <InputText id="navXMLExchangeKey" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     navXMLExchangeKey: e.target.value
                                   })
                                 }}
                                 value={formData.navXMLExchangeKey}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col-12 p-lg-3 p-xl-3"}>
                <Field name="navTest" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="navTest"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Teszt környezet</label>
                    <span className={"p-d-block"}>
                    <InputSwitch
                      className={"p-mt-1"}
                      checked={formData.navTest}
                      value={formData.navTest}
                      onChange={e => setFormData({...formData, navTest: e.target.value})}/>
                  </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
            </div>
            <div className={"p-grid p-mt-1"}>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>
              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                <Button type="button" label="Mentés" icon="pi pi-check"
                        className={"p-button-success"}
                        onClick={() => {
                          CompanyNavAddService(formData.id, formData.navUserName, formData.navUserPass,
                            formData.navXMLSignKey, formData.navXMLExchangeKey, formData.navTest)
                            .then(response => {
                              window.App.toastShow('success', 'Sikeres mentés!');
                              CompanyShowService(formData.id)
                                .then(response => {
                                  setFormData(response.data)
                                })
                                .catch(error => {
                                  window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                })
                            })
                            .catch(error => {
                              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                            })
                        }}
                        loading={loading}/>
              </div>
            </div>
          </Panel>
        </>
        }
        {formData.id && process.env.REACT_APP_COMPANY_LOGO === 'true' &&
        <>
          <Panel headerTemplate={
            <div className={"p-grid"}>
              <div className={"p-col-12"}>
                <h3>
                  <i className={"pi pi-image"}></i> Logó
                </h3>
              </div>
            </div>
          } className={"p-mt-3"}>
            <div className={"p-grid"}>
              <div className={"p-col"}>
                <div className="p-field p-fluid p-mb-3 p-text-left">
                  <label htmlFor="file">Feltöltött logó</label>
                  <img src={process.env.REACT_APP_API_HOST + "/company/logo/" + formData.id
                  + "?token=" + localStorage.getItem("userToken") + "?date=" + new Date()}
                       className={"w-75"}/>
                </div>
              </div>
              <div className={"p-col"}>
                <div className="p-field p-fluid">
                  <label htmlFor="file">Kép feltöltése</label>
                  <span className="p-d-block">
                            <FileUpload mode="basic"
                                        name="file"
                                        id={"file"}
                                        auto="false"
                                        customUpload
                                        uploadHandler={imageUpload}
                                        chooseLabel={"Fájl kiválasztása"}
                                        uploadLabel={"Feltöltés"}
                                        className={""}
                            />
                        </span>
                </div>
                <div className="p-field p-mt-3">
                  <label htmlFor="file">Kép törlése</label>
                  <span className="p-d-block">
                        <Button type="button"
                                className="p-button p-button-danger"
                                onClick={() => {
                                  CompanyLogoDeleteService(formData.id)
                                    .then(response => {
                                      CompanyShowService(props.initialValues.id)
                                        .then(response => {
                                          setFormData(response.data)
                                        })
                                        .catch(error => {
                                          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                        })
                                    })
                                    .catch(error => {
                                      window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                    })
                                }}
                        ><i className={"pi pi-trash"}></i>&nbsp;Törlés</Button>
                        </span>
                </div>
              </div>
            </div>
          </Panel>
        </>
        }
        {localStorage.getItem('roles').toString().includes('role_company_document_read') &&
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Dokumentumok
              </h3>
            </div>
          </div>
        } className={"p-mt-3"}>
          {documents && documents[0] &&
          <DataTable size={localStorage.getItem('scale')} value={documents}
                     emptyMessage="Nincs találat."
                     autoLayout={true}
                     filterDisplay="row"
                     responsiveLayout="scroll"
                     stripedRows
                     className={"p-mb-3"}
          >
            <Column field="name" header="Név"></Column>
            <Column field="filePath" header="Fájl" body={(rowData) => {
              return (
                <>
                  <a
                    href={process.env.REACT_APP_API_HOST + "/companyDocument/download/" + rowData.id
                    + "?token=" + localStorage.getItem('userToken') + "."
                    + localStorage.getItem("networkId")}
                    target="_blank"
                  >
                    <i className={"pi pi-file"}></i> {rowData.originalFileName}
                  </a>
                </>
              )
            }}></Column>
            {/*<Column body={itemActionBody} className={"p-text-right p-text-nowrap"}></Column>*/}
          </DataTable>
          }
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-col-align-center"}>
              {documents && !documents[0] &&
              <>Nincs dokumentum.</>
              }
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              {localStorage.getItem('roles').toString().includes('role_company_document_write') &&
              <Button type="button" label="Új dokumentum hozzáadása" icon="pi pi-plus" className="p-button-success "
                      onClick={() => {
                        setDocumentDialog(true)
                        setDocumentFormMethod('post')
                      }}
              />
              }
            </div>
          </div>
        </Panel>
        }
        {localStorage.getItem('roles').toString().includes('role_company_contact_read') &&
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Kapcsolattartók
              </h3>
            </div>
          </div>
        } className={"p-mt-3"}>
          {contacts && contacts[0] &&
          <DataTable size={localStorage.getItem('scale')} value={contacts}
                     emptyMessage="Nincs találat."
                     autoLayout={true}
                     filterDisplay="row"
                     responsiveLayout="scroll"
                     stripedRows
                     className={"p-mb-3"}
          >
            {localStorage.getItem('roles').toString().includes('role_company_contact_write') &&
            <Column body={contactActionBody} className={"p-text-nowrap"}></Column>
            }
            <Column field="name" header="Név"></Column>
            <Column field="email" header="Email"></Column>
            <Column field="phone" header="Telefon"></Column>
          </DataTable>
          }
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-col-align-center"}>
              {contacts && !contacts[0] &&
              <>Nincs kapcsolat.</>
              }
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              {localStorage.getItem('roles').toString().includes('role_company_contact_write') &&
              <Button type="button" label="Új kapcsolat hozzáadása" icon="pi pi-plus" className="p-button-success "
                      onClick={() => {
                        setContactDialog(true)
                        setContactFormMethod('post')
                      }}
              />
              }
            </div>
          </div>
        </Panel>
        }
        <Dialog visible={documentDialog}
                style={{width: '750px'}}
                header="Dokumentum hozzáadása"
                modal
                onHide={() => {
                  setDocumentDialog(false)
                }}>
          <Form onSubmit={documentOnSubmit}
                initialValues={documentFormData}
                validate={documentValidate}
                render={({handleSubmit, submitting}) => (
                  <form onSubmit={handleSubmit} className="">
                    <div className={"p-grid"}>
                      <div className={"p-col-12 p-lg-6 p-xl-6"}>
                        <div className={"p-grid"}>
                          <div className={"p-col-12 p-lg-12 p-xl-12"}>
                            <Field name="name" render={({input, meta}) => (
                              <div className="p-field p-fluid">
                                <label htmlFor="name"
                                       className={classNames({'p-error': isFormFieldValid(meta)})}>Megnevezés</label>
                                <span className={"p-d-block"}>
                              <InputText id="name" {...input}
                                         onChange={(e) => {
                                           setDocumentFormData({
                                             ...documentFormData,
                                             name: e.target.value
                                           })
                                         }}
                                         value={documentFormData.name}
                                         className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                                {getFormErrorMessage(meta)}
                              </div>
                            )}/>
                          </div>
                        </div>
                      </div>
                      <div className={"p-col-12 p-lg-6 p-xl-6"}>
                        {documentFormData.file ? (
                          <>
                            <label>Feltöltött fájl</label>
                            <p>
                              {/*<img src={documentFormData.file} className={"w-100 p-mt-2"}/>*/}
                              <i className={"pi pi-file"}></i> {documentFormData.originalFileName}
                            </p>
                          </>
                        ) : (
                          <>
                            <label>Feltöltött fájl</label>
                            <span className="p-d-block">
                            {documentFormData && documentFormData.id !== undefined ? (
                              <p>
                                <a
                                  href={process.env.REACT_APP_API_HOST + "/employeeDocument/"
                                  + formData.id + "/download/" + documentFormData.id
                                  + "?token=" + localStorage.getItem('userToken') + "."
                                  + localStorage.getItem("networkId")}
                                  target="_blank"
                                >
                                  <i className={"pi pi-file"}></i> {documentFormData.originalFileName}
                                </a>
                              </p>
                              // <img src={process.env.REACT_APP_API_HOST + "/Companydocument/"
                              // + formData.id + "/download/" + documentFormData.id
                              // + "?token=" + localStorage.getItem("userToken") + "."
                              // + localStorage.getItem('networkId')} className={"w-100 p-mt-2"}/>
                            ) : (
                              <p>Még nincs fájl feltöltve.</p>
                            )}
                          </span>
                          </>
                        )}
                        <Field name="file" render={({input, meta}) => (
                          <div className="p-field">
                            {!documentFormData.file ? (
                              <>
                                <label htmlFor="file"
                                       className={classNames({'p-error': isFormFieldValid(meta)})}>Fájl
                                  feltöltése</label>
                                <span className="p-d-block">
                              <FileUpload mode="basic"
                                          name="file"
                                          id={"file"}
                                          auto="true"
                                          maxFileSize={10000000000}
                                          customUpload
                                          uploadHandler={documentUpload}
                                          chooseLabel={"Fájl kiválasztása"}
                                          uploadLabel={"Feltöltés"}
                              />
                            </span>
                              </>
                            ) : (
                              <>
                              <span className="p-d-block">
                              <Button type="button"
                                      className="p-button p-button-danger p-mt-2"
                                      onClick={() => setDocumentFormData({...documentFormData, file: null})}
                              ><i className={"pi pi-trash"}></i>&nbsp;Törlés</Button>
                            </span>
                              </>
                            )}
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                    </div>
                    <div className={"p-grid p-mt-1"}>
                      <div className={"p-col-12 p-p-0"}>
                        <Message severity="warn" text="
                    Figyelem! A projekthez kapcsolt dokumentum megjelenik a projektgazda számára a projekt adatlapon.
                    "></Message>
                      </div>
                    </div>
                    <div className={"p-grid p-mt-1"}>
                      <div className={"p-col-12 p-lg-6 p-xl-6"}>
                      </div>
                      <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                        <Button type="submit" label="Mentés" icon="pi pi-check"
                                loading={documentsLoading}/>
                      </div>
                    </div>
                  </form>
                )}/>
        </Dialog>
        <Dialog visible={contactDialog}
                style={{width: '750px'}}
                header="Kapcsolat hozzáadása"
                modal
                onHide={() => {
                  setContactDialog(false)
                }}>
          <Form onSubmit={contactOnSubmit}
                initialValues={contactFormData}
                validate={contactValidate}
                render={({handleSubmit, submitting}) => (
                  <form onSubmit={handleSubmit} className="">
                    <div className={"p-grid"}>
                      <div className={"p-col-12 p-lg-4 p-xl-4"}>
                        <Field name="name" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="name"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>Megnevezés</label>
                            <span className={"p-d-block"}>
                              <InputText id="name" {...input}
                                         onChange={(e) => {
                                           setContactFormData({
                                             ...contactFormData,
                                             name: e.target.value
                                           })
                                         }}
                                         value={contactFormData.name}
                                         className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                      <div className={"p-col-12 p-lg-4 p-xl-4"}>
                        <Field name="email" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="email"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>Email</label>
                            <span className={"p-d-block"}>
                              <InputText id="email" {...input}
                                         onChange={(e) => {
                                           setContactFormData({
                                             ...contactFormData,
                                             email: e.target.value
                                           })
                                         }}
                                         value={contactFormData.email}
                                         className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                      <div className={"p-col-12 p-lg-4 p-xl-4"}>
                        <Field name="phone" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="phone"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>Telefon</label>
                            <span className={"p-d-block"}>
                              <InputText id="phone" {...input}
                                         onChange={(e) => {
                                           setContactFormData({
                                             ...contactFormData,
                                             phone: e.target.value
                                           })
                                         }}
                                         value={contactFormData.phone}
                                         className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                    </div>
                    <div className={"p-grid p-mt-1"}>
                      <div className={"p-col-12 p-lg-6 p-xl-6"}>
                      </div>
                      <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                        <Button type="submit" label="Mentés" icon="pi pi-check"
                                loading={contactsLoading}/>
                      </div>
                    </div>
                  </form>
                )}/>
        </Dialog>
      </form>
    )}/>
  )
}
export default withTranslation('common')(CompanyFormComponent);
