import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {Chart} from 'primereact/chart';
import {Sidebar} from "primereact/sidebar";
import {Link} from "react-router-dom";
import {TabView, TabPanel} from 'primereact/tabview';

import PartnerListComponent from "../../components/partner/PartnerListComponent";
import PartnerFormComponent from "../../components/partner/PartnerFormComponent";

export const PartnerDashboardPage = () => {

  useEffect(() => {
    window.App.pageHeight()
    document.title = 'Partnerek' + ' - ' + process.env.REACT_APP_TITLE;
    setTimeout(function () {
      if (document.getElementsByClassName('activeMenu')[0]) {
        document.getElementsByClassName('activeMenu')[0].classList.remove("activeMenu")
      }
      if (document.getElementsByClassName('partner')[0]) {
        document.getElementsByClassName('partner')[0].classList.add("activeMenu")
      }
    }, 1000)
    if (
      document.getElementById('tabScroll')
      && document.getElementById('tabScroll').children
      && document.getElementById('tabScroll').children[0]
      && document.getElementById('tabScroll').children[0].querySelector("li:not(.p-disabled)").children[0]
    ) {
      document.getElementById('tabScroll').children[0].querySelector("li:not(.p-disabled)").children[0].click()
    }
  }, []);

  return (
    <div className="ModuleDashboardPage DashboardPage p-component p-p-3 ">
      {localStorage.getItem('module_partner') ? (
        <>
          <div className={"p-grid p-d-flex p-flex-wrap"}>
            <div className={"p-col-12 p-lg-12 p-xl-12 p-p-0 left p-position-relative"}>
              {localStorage.getItem('roles').toString().includes('role_partner_read') &&
                <>
                  <TabView renderActiveOnly="true" className={"nav"} scrollable={window.App.state.tabScroll}
                           id="tabScroll">
                    <TabPanel header="Partnerek listja">
                      <div className={"p-px-2 p-pb-2"}>
                        {/*<Button className={"p-button-outlined p-button-sm fixed"}*/}
                        {/*        label="Kiterjesztett nézet" icon="pi pi-window-maximize"*/}
                        {/*        onClick={() => {*/}
                        {/*          window.App.setState({sidebarPartnerList: true})*/}
                        {/*        }}*/}
                        {/*/>*/}
                        <PartnerListComponent view="simple"/>
                      </div>
                    </TabPanel>
                  </TabView>
                </>
              }
            </div>
          </div>
          <Sidebar position="left" visible={window.App.state.sidebarPartnerCreate} closeOnEscape={true}
                   style={{width: '980px'}}
                   onHide={() => {
                     window.App.setState({sidebarPartnerCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új partner</h2>
            <PartnerFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarPartnerUpdate} closeOnEscape={true}
                   style={{width: '980px'}}
                   onHide={() => {
                     window.App.setState({sidebarPartnerUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Partner módosítása</h2>
            <PartnerFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
        </>
      ) : (
        <p className={"p-text-center p-my-5 p-py-5"}>
          <i className={"pi pi-exclamation-circle p-d-block p-mb-5"} style={{'fontSize': '5em'}}></i>
          <span className={"p-text-bold p-d-block "}>Ehhez a modulhoz sajnos nem rendelkezel hozzáféréssel.</span>
          Amennyiben további információra van szükséged írj emailt a {process.env.REACT_APP_DEVOPS_CONTACT} címre.
          {process.env.REACT_APP_SUBSCRIPTIONS === 'enabled' &&
            <span className={"p-text-bold p-d-block p-mt-5"}>
                       <a className={"p-button p-button-warning"}
                          onClick={() => {
                            window.location.replace('/#/subscriptions');
                            window.App.forceUpdate()
                          }}
                       >
                         <i className={"pi pi-building"}></i>&nbsp;Előfizetői fiók áttekintése
                       </a>
                   </span>
          }
        </p>
      )}
    </div>
  )
}
export default withTranslation('common')(PartnerDashboardPage);
