import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Dialog} from "primereact/dialog";
import {Panel} from "primereact/panel"

import ProductionjobListService from "../../services/productionjob/ProductionjobListService";
import ProjectitemJobsFormComponent from "./ProjectitemJobsFormComponent";
import moment from "moment";
import ProductionjobDeleteService from "../../services/productionjob/ProductionjobDeleteService";

const ProjectitemJobsListComponent = (props) => {

  const {t} = props;
  const [projectitemJobs, setProjectitemJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(Number(process.env.REACT_APP_LIST_ROW_DEFAULT));
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1,
    filters: {
      projectItem: {
        value: window.App.state.projectitemRowData.id
      }
    }
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderProjectitemJobsList: false})
      ProductionjobListService(JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setProjectitemJobs(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: Number(process.env.REACT_APP_LIST_ROW_OP1), value: Number(process.env.REACT_APP_LIST_ROW_OP1)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP2), value: Number(process.env.REACT_APP_LIST_ROW_OP2)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP3), value: Number(process.env.REACT_APP_LIST_ROW_OP3)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP4), value: Number(process.env.REACT_APP_LIST_ROW_OP4)},
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const deleteProjectitemJob = (rowData) => {
    ProductionjobDeleteService(rowData.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres törlés!',);
        loadLazyData();
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-pencil" className="  p-button-warning p-mr-2"
                tooltip="Módosítás"
                onClick={() => {
                  window.App.setState({
                    dialogProjectitemJob: true,
                    projectitemJobFormMethod: 'update',
                    projectitemJobRowData: rowData
                  })
                }}/>
        <Button icon="pi pi-trash" className="  p-button-danger"
                tooltip="Törlés"
                onClick={() => {
                  if (window.confirm('A törlés megerősítésére van szükség!')) {
                    deleteProjectitemJob(rowData)
                  }
                }}
        />
      </React.Fragment>
    );
  }

  useEffect(() => {
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderProjectitemJobsList === true])

  return (
    <>
      <h2 className={"text-primary p-mb-0"}>Munkaidő</h2>
      <div className={"p-grid"}>
        {window.App.state.projectitemRowData.name  &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            {t('project')} tétel
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            <>{window.App.state.projectitemRowData.name}</>
          </p>
        </div>
        }
        {window.App.state.projectitemRowData.quantity  &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            Mennyiség
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            <>{window.App.state.projectitemRowData.quantity} {window.App.state.projectitemRowData.unitName} </>
          </p>
        </div>
        }
        {window.App.state.projectitemRowData.serial  &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            Sorszám
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            <>{window.App.state.projectitemRowData.serial}</>
          </p>
        </div>
        }
        {window.App.state.projectitemRowData.projectName  &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            {t('project')}
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            <>{window.App.state.projectitemRowData.projectName}</>
          </p>
        </div>
        }
      </div>
      <hr/>
      <Panel className={"p-mt-3"}>
        <DataTable size={localStorage.getItem('scale')}
          emptyMessage="Nincs találat."
          value={projectitemJobs} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
          onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
          onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
          paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
          autoLayout={true}
          filterDisplay="row"
          responsiveLayout="scroll"
          stripedRows
          dataKey="id"
        >
          <Column field="employeeName" header="Munkavállaló" sortable filter filterPlaceholder="Munkavállaló"></Column>
          <Column field="machineName" header="Gép" sortable filter filterPlaceholder="Gép"></Column>
          <Column field="producedFrom" header="Munka kezdete" sortable className={"p-text-nowrap"} body={(rowData) => {
            return (
              <>{moment.utc(rowData.producedFrom).local().format('YYYY-MM-DD hh:mm')}</>
            )
          }}></Column>
          <Column field="producedUntil" header="Munka vége" sortable className={"p-text-nowrap"} body={(rowData) => {
            return (
              <>{moment.utc(rowData.producedFrom).local().format('YYYY-MM-DD hh:mm')}</>
            )
          }}></Column>
          <Column body={actionBodyTemplate} className={"p-py-0 p-text-right p-text-nowrap"}></Column>
        </DataTable>
      </Panel>

      <div className={"p-grid p-mt-1"}>
        <div className={"p-col-12 p-lg-6 p-xl-6"}>

        </div>
        <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
          <Button type="button" label="Új munkaidő bejegyzés" icon={"pi pi-plus"}
                  className="p-button-success "
                  onClick={() => {
                    window.App.setState({
                      dialogProjectitemJob: true,
                      projectitemJobFormMethod: 'create',
                    })
                  }}
          />
        </div>
      </div>
      <Dialog visible={window.App.state.dialogProjectitemJob}
              style={{width: '750px'}}
              header="Munkaidő hozzáadása"
              modal
              onHide={() => {
                window.App.setState({
                  dialogProjectitemJob: false
                })
              }}>
        <ProjectitemJobsFormComponent />
      </Dialog>
    </>
  )
}
export default withTranslation('common')(ProjectitemJobsListComponent);
