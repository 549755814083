import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Dialog} from "primereact/dialog";
import currencyFormatter from "currency-formatter";
import OfferitemPricelistitemListService from "../../services/offer/OfferitemPricelistitemListService";
import OfferitemPricelistitemFormComponent from "./OfferitemPricelistitemFormComponent";
import OfferitemPricelistitemDeleteService from "../../services/offer/OfferitemPricelistitemDeleteService";

const OfferitemPricelistitemListComponent = (props) => {

  const [offeritemPricelistitem, setOfferitemPricelistitem] = useState([]);
  const [formData, setFormData] = useState({});

  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(Number(process.env.REACT_APP_LIST_ROW_DEFAULT));
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'name',
    sortOrder: -1
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderOfferitemPricelistitemList: false})
      OfferitemPricelistitemListService(window.App.state.props.id, window.App.state.offerItemRowData.id, JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setOfferitemPricelistitem(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: Number(process.env.REACT_APP_LIST_ROW_OP1), value: Number(process.env.REACT_APP_LIST_ROW_OP1)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP2), value: Number(process.env.REACT_APP_LIST_ROW_OP2)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP3), value: Number(process.env.REACT_APP_LIST_ROW_OP3)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP4), value: Number(process.env.REACT_APP_LIST_ROW_OP4)},
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  const deleteOfferitemPricelistitem = (rowData) => {
    OfferitemPricelistitemDeleteService(window.App.state.props.id, window.App.state.offerItemRowData.id, rowData.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres törlés!',);
        loadLazyData();
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {window.App.state.props && window.App.state.props.status && window.App.state.props.status === 'DRAFT' &&
          <>
            {/*{localStorage.getItem('roles').toString().includes('role_offeritemPricelistitem_write') &&*/}
            <Button icon="pi pi-pencil" className="  p-button-warning p-mr-2"
                    tooltip="Módosítás"
                    onClick={() => {
                      window.App.setState({
                        dialogOfferitemPricelistitemUpdate: true,
                        listItemRowData: rowData
                      })
                    }}/>
            {/*}*/}
            {/*{localStorage.getItem('roles').toString().includes('role_offeritemPricelistitem_write') &&*/}
            <Button icon="pi pi-trash" className="  p-button-danger p-mr-2"
                    tooltip="Törlés"
                    onClick={() => {
                      if (window.confirm('A törlés megerősítésére van szükség!')) {
                        deleteOfferitemPricelistitem(rowData)
                      }
                    }}
            />
            {/*}*/}
          </>
        }
      </React.Fragment>
    );
  }

  useEffect(() => {
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderOfferitemPricelistitemList === true])

  return (
    <>
      <DataTable size={localStorage.getItem('scale')}
        emptyMessage="Nincs találat."
        value={offeritemPricelistitem} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
        paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
        autoLayout={true}
        stripedRows
        selectionMode="checkbox"
        dataKey="id"
        filterDisplay="row"
        responsiveLayout="scroll"
        dataKey="id"
      >
        <Column field="name" header="Név" sortable filter filterPlaceholder="Név"></Column>
        <Column field="quantity" header="Mennyiség" className={"p-text-right"} alignHeader="right" body={(rowData) => {
          return (
            <>
              {rowData.quantity} {rowData.unitName}
            </>
          )
        }}></Column>
        <Column field="unitPrice" header="Nettó egységár" className={"p-text-right p-text-nowrap"}
                alignHeader="right"
                body={(rowData) => {
                  let currency
                  if (formData.currency === 'HUF') {
                    currency = {
                      precision: 2,
                      symbol: '',
                      decimal: ',',
                      thousand: ' ',
                    }
                  } else {
                    currency = {
                      precision: 2,
                      symbol: '',
                      decimal: '.',
                      thousand: ' ',
                    }
                  }
                  return (
                    <>
                      {currencyFormatter.format(
                        rowData.unitPrice, {
                          precision: currency.precision,
                          symbol: currency.symbol,
                          decimal: currency.decimal,
                          thousand: currency.thousand,
                        }
                      )
                      }
                      &nbsp;{window.App.state.props.currency}
                    </>
                  )
                }}></Column>
        <Column body={actionBodyTemplate} className={"p-py-0 p-text-right p-text-nowrap"}></Column>
      </DataTable>
      <div className={"p-grid p-mt-1"}>
        <div className={"p-col-12 p-lg-6"}>

        </div>
        <div className={"p-col-12 p-lg-6 p-text-right"}>
          {window.App.state.props && window.App.state.props.status && window.App.state.props.status === 'DRAFT' ? (
            <>
              <Button type="button" label="Szolgáltatás hozzáadása" icon="pi pi-plus" className="p-button-success  p-ml-2"
                      onClick={() => {
                        window.App.setState({
                          dialogOfferitemPricelistitemCreate: true,
                        })
                      }}
              />
            </>
            ):(
              <>
                <Button type="button" label="Szolgáltatás hozzáadása" icon="pi pi-plus" className="disabled p-button-success  p-ml-2"
                />
              </>
            )}
          <Dialog visible={window.App.state.dialogOfferitemPricelistitemUpdate}
                  style={{width: '750px'}}
                  header="Szolgáltatás módosítása"
                  modal
                  onHide={() => {
                    window.App.setState({
                      dialogOfferitemPricelistitemUpdate: false
                    })
                  }}>
            <OfferitemPricelistitemFormComponent offerId={window.App.state.props.id} initialValues={window.App.state.offerItemRowData}/>
          </Dialog>
          <Dialog visible={window.App.state.dialogOfferitemPricelistitemCreate}
                  style={{width: '750px'}}
                  header="Szolgáltatás hozzáadása"
                  modal
                  onHide={() => {
                    window.App.setState({
                      dialogOfferitemPricelistitemCreate: false
                    })
                  }}>
            <OfferitemPricelistitemFormComponent offerId={window.App.state.props.id}/>
          </Dialog>
        </div>
      </div>
    </>

  )
}
export default withTranslation('common')(OfferitemPricelistitemListComponent);
