import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {PickList} from 'primereact/picklist';
import {Panel} from "primereact/panel";
import {Dropdown} from "primereact/dropdown";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {AutoComplete} from "primereact/autocomplete";

import PurchaseorderFormService from '../../services/purchaseorder/PurchaseorderFormService'
import EmployeeListService from "../../services/employee/EmployeeListService";
import PurchaseorderRequestItemsService from "../../services/purchaseorder/PurchaseorderRequestItemsService";
import PurchaseorderRequestItemsAddedService from "../../services/purchaseorder/PurchaseorderRequestItemsAddedService";
import PurchaseorderRequestItemsAddService from "../../services/purchaseorder/PurchaseorderRequestItemsAddService";
import PurchaseorderRequestItemsRemoveService
  from "../../services/purchaseorder/PurchaseorderRequestItemsRemoveService";
import download from "js-file-download";
import moment from "moment";
import PurchaseorderDownloadService from "../../services/purchaseorder/PurchaseorderDownloadService";

const PurchaseorderFormComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);

  // const [statuses, setStatuses] = useState([]);

  const validate = (data) => {
    let errors = {};
    // if (!data.contact) {
    //   errors.contact = 'A kapcsolat nem lehet üres';
    // }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if ((props && props.initialValues) || formData.id) {
      PurchaseorderFormService(data, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          loadLazyDataRequestItemsSource(response.data.id);
          loadLazyDataRequestItemsTarget(response.data.id);
          window.App.setState({rerenderPurchaseorderList: true})
          if (props.closeForm === true) {
            window.App.setState({
              sidebarPurchaseorderCreate: false,
              sidebarPurchaseorderUpdate: false,
            })
          }
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      PurchaseorderFormService(data, 'post')
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          setFormData(response.data)
          loadLazyDataRequestItemsSource(response.data.id);
          loadLazyDataRequestItemsTarget(response.data.id);
          window.App.setState({rerenderPurchaseorderList: true})
          if (props.closeForm === true) {
            window.App.setState({
              sidebarPurchaseorderCreate: false
            })
          }
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // employee autocomplete
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState('');

  const searchEmployee = (event) => {
    EmployeeListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        setEmployees(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // request items assign
  const [requestItemsSource, setRequestItemsSource] = useState([]);
  const [requestItemsToAdd, setRequestItemsToAdd] = useState([]);
  const [requestItemsTarget, setRequestItemsTarget] = useState([]);
  const [requestItemsToDelete, setRequestItemsToDelete] = useState([]);

  const [loadingRequestItemsSource, setLoadingRequestItemsSource] = useState(false);
  const [totalRecordsRequestItemsSource, setTotalRecordsRequestItemsSource] = useState(0);
  const [rowsRequestItemsSource, setRowsRequestItemsSource] = useState(10);
  const [lazyParamsRequestItemsSource, setLazyParamsRequestItemsSource] = useState({
    first: 0,
    rows: rowsRequestItemsSource,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeoutRequestItemsSource = null;
  const loadLazyDataRequestItemsSource = (orderId) => {
    setLoadingRequestItemsSource(true);
    if (loadLazyTimeoutRequestItemsSource) {
      clearTimeout(loadLazyTimeoutRequestItemsSource);
    }
    loadLazyTimeoutRequestItemsSource = setTimeout(() => {
      PurchaseorderRequestItemsService(orderId, JSON.stringify(lazyParamsRequestItemsSource))
        .then(response => {
          setTotalRecordsRequestItemsSource(response.data.total_item_count);
          setRequestItemsSource(response.data.items);
          setLoadingRequestItemsSource(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPageRequestItemsSource = (event) => {
    let _lazyParams = {...lazyParamsRequestItemsSource, ...event};
    setLazyParamsRequestItemsSource(_lazyParams);
  }
  const onSortRequestItemsSource = (event) => {
    let _lazyParams = {...lazyParamsRequestItemsSource, ...event};
    setLazyParamsRequestItemsSource(_lazyParams);
  }
  const onFilterRequestItemsSource = (event) => {
    let _lazyParams = {...lazyParamsRequestItemsSource, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParamsRequestItemsSource(_lazyParams);
  }
  const pagerRequestItemsSource = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: Number(process.env.REACT_APP_LIST_ROW_OP1), value: Number(process.env.REACT_APP_LIST_ROW_OP1)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP2), value: Number(process.env.REACT_APP_LIST_ROW_OP2)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP3), value: Number(process.env.REACT_APP_LIST_ROW_OP3)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP4), value: Number(process.env.REACT_APP_LIST_ROW_OP4)},
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions}
                    onChange={(e) => setRowsRequestItemsSource(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const onRequestItemSubmit = () => {
    window.App.setState({loadingPurchaseorderRequestItemAdd: true})
    let _itemsToAdd = []
    Object.entries(requestItemsToAdd).map(function (item, index) {
      _itemsToAdd.push(item[1].id)
    })
    PurchaseorderRequestItemsAddService(_itemsToAdd, formData.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres mentés!');
        loadLazyDataRequestItemsSource(formData.id);
        loadLazyDataRequestItemsTarget(formData.id);
        window.App.setState({loadingPurchaseorderRequestItemAdd: false})
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        window.App.setState({loadingPurchaseorderRequestItemAdd: false})
      })
  }

  const [loadingRequestItemsTarget, setLoadingRequestItemsTarget] = useState(false);
  const [totalRecordsRequestItemsTarget, setTotalRecordsRequestItemsTarget] = useState(0);
  const [rowsRequestItemsTarget, setRowsRequestItemsTarget] = useState(10);
  const [lazyParamsRequestItemsTarget, setLazyParamsRequestItemsTarget] = useState({
    first: 0,
    rows: rowsRequestItemsTarget,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeoutRequestItemsTarget = null;
  const loadLazyDataRequestItemsTarget = (orderId) => {
    setLoadingRequestItemsTarget(true);
    if (loadLazyTimeoutRequestItemsTarget) {
      clearTimeout(loadLazyTimeoutRequestItemsTarget);
    }
    loadLazyTimeoutRequestItemsTarget = setTimeout(() => {
      PurchaseorderRequestItemsAddedService(orderId,  JSON.stringify(lazyParamsRequestItemsTarget))
        .then(response => {
          setTotalRecordsRequestItemsTarget(response.data.total_item_count);
          setRequestItemsTarget(response.data.items);
          setLoadingRequestItemsTarget(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPageRequestItemsTarget = (event) => {
    let _lazyParams = {...lazyParamsRequestItemsTarget, ...event};
    setLazyParamsRequestItemsTarget(_lazyParams);
  }
  const onSortRequestItemsTarget = (event) => {
    let _lazyParams = {...lazyParamsRequestItemsTarget, ...event};
    setLazyParamsRequestItemsTarget(_lazyParams);
  }
  const onFilterRequestItemsTarget = (event) => {
    let _lazyParams = {...lazyParamsRequestItemsTarget, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParamsRequestItemsTarget(_lazyParams);
  }
  const pagerRequestItemsTarget = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: Number(process.env.REACT_APP_LIST_ROW_OP1), value: Number(process.env.REACT_APP_LIST_ROW_OP1)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP2), value: Number(process.env.REACT_APP_LIST_ROW_OP2)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP3), value: Number(process.env.REACT_APP_LIST_ROW_OP3)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP4), value: Number(process.env.REACT_APP_LIST_ROW_OP4)},
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions}
                    onChange={(e) => setRowsRequestItemsTarget(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const onRequestItemsDelete = () => {
    window.App.setState({loadingPurchaseorderRequestItemsRemove: true})
    let _requestItemsToDelete = []
    Object.entries(requestItemsToDelete).map(function (item, index) {
      _requestItemsToDelete.push(item[1].id)
    })
    PurchaseorderRequestItemsRemoveService(_requestItemsToDelete, formData.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres mentés!');
        loadLazyDataRequestItemsSource(formData.id);
        loadLazyDataRequestItemsTarget(formData.id);
        window.App.setState({loadingPurchaseorderRequestItemsRemove: false})
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        window.App.setState({loadingPurchaseorderRequestItemsRemove: false})
      })
  }

  useEffect(() => {
    // PurchaseorderStatusListService()
    //   .then(response => {
    //     setStatuses(response.data)
    //   })
    //   .catch(error => {
    //     window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
    //   })
    if (formData && formData.id) {
      loadLazyDataRequestItemsSource(formData.id);
      loadLazyDataRequestItemsTarget(formData.id);
    }
    if (props && props.initialValues) {
      loadLazyDataRequestItemsSource(props.initialValues.id);
      loadLazyDataRequestItemsTarget(props.initialValues.id);
      setFormData(props.initialValues)
      setSelectedEmployee(props.initialValues.employeeName)
    }
  }, [lazyParamsRequestItemsSource, lazyParamsRequestItemsTarget])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <div className={"p-grid"}>
          <div className={"p-col-12"}>
            <Panel headerTemplate={
              <div className={"p-grid"}>
                <div className={"p-col-12"}>
                  <h3>
                    <i className={"pi pi-list"}></i> Alapadatok
                  </h3>
                </div>
              </div>
            } className={"w-100"}>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-3"}>
                  <Field name="contact" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="contact"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Kapcsolat</label>
                      <span className={"p-d-block"}>
                    <AutoComplete value={selectedEmployee}
                                  id={"contact"}
                                  suggestions={employees}
                                  forceSelection
                                  completeMethod={searchEmployee}
                                  field={"name"}
                                  delay="1000"
                                  placeholder={"Keresés gépeléssel..."}
                                  className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                  onSelect={(e) => {
                                    setFormData({
                                      ...formData,
                                      contact: {id: e.value.id},
                                      contactName: e.value.name
                                    })
                                  }}
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      contact: {id: null},
                                      contactName: null
                                    })
                                    setSelectedEmployee(e.value)
                                  }}
                                  dropdown
                                  onClear={(e) => {
                                    setSelectedEmployee('')
                                  }}
                    />
                  </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                {/*<div className={"p-col-12 p-md-12 p-lg-12"}>*/}
                {/*  <Field name="status" render={({input, meta}) => (*/}
                {/*    <div className="p-field p-fluid">*/}
                {/*      <label htmlFor="status"*/}
                {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Státusz</label>*/}
                {/*      <span>*/}
                {/*            <Dropdown showClear options={statuses}*/}
                {/*                      optionLabel="name"*/}
                {/*                      optionValue="id"*/}
                {/*                      id={"status"}*/}
                {/*                      emptyMessage={"Még nincs státusz..."}*/}
                {/*                      onChange={(e) => {*/}
                {/*                        setFormData({*/}
                {/*                          ...formData,*/}
                {/*                          status: e.value*/}
                {/*                        })*/}
                {/*                      }}*/}
                {/*                      value={formData.status}*/}
                {/*            />*/}
                {/*  </span>*/}
                {/*      {getFormErrorMessage(meta)}*/}
                {/*    </div>*/}
                {/*  )}/>*/}
                {/*</div>*/}
                <div className={"p-col-12 p-lg-3"}>
                  <Field name="address" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="address"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Szállítási cím</label>
                      <span className={"p-d-block"}>
                      <InputText id="address" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     address: e.target.value
                                   })
                                 }}
                                 value={formData.address}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
              </div>
            </Panel>
          </div>
        </div>
        <div className={"p-grid p-mt-1"}>
          <div className={"p-col-12"}>
            <Panel className={"w-100"}>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <div className={"p-grid"}>
                    <div className={"p-col-12"}>
                      <h3>
                        <i className={"pi pi-list"}></i> Megrendelés igény tételek
                      </h3>
                    </div>
                  </div>
                  {formData.id ? (
                    <>
                      <DataTable size={localStorage.getItem('scale')}
                        emptyMessage="Nincs találat."
                        value={requestItemsSource} paginator first={lazyParamsRequestItemsSource.first}
                        totalRecords={totalRecordsRequestItemsSource} onPage={onPageRequestItemsSource}
                        onSort={onSortRequestItemsSource} sortField={lazyParamsRequestItemsSource.sortField}
                        sortOrder={lazyParamsRequestItemsSource.sortOrder}
                        onFilter={onFilterRequestItemsSource} filters={lazyParamsRequestItemsSource.filters}
                        loading={loadingRequestItemsSource} lazy
                        paginatorTemplate={pagerRequestItemsSource} rows={rowsRequestItemsSource}
                        emptyMessage="Nincs találat."
                        autoLayout={true}
                        selection={requestItemsToAdd} onSelectionChange={e => {
                        setRequestItemsToAdd(e.value)
                      }} dataKey="id"
                        filterDisplay="row"
responsiveLayout="scroll"
                        selectionMode="checkbox"
                        stripedRows
                      >
                        <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                        <Column field="productName" header="Név" sortable filter filterPlaceholder="Név"></Column>
                        <Column field="quantity" header="Db" sortable className={"p-text-right"}></Column>
                        <Column field="projectName" header={t('project')} sortable></Column>
                      </DataTable>
                      <div className={"p-grid p-mt-1"}>
                        <div className={"p-col-12 p-lg-6 p-xl-6"}>

                        </div>
                        <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                          <Button type="button" label="Tételek hozzáadása" icon={"pi pi-plus"}
                                  loading={window.App.state.loadingPurchaseorderRequestItemAdd}
                                  onClick={onRequestItemSubmit}
                                  className="p-button-success "/>
                        </div>
                      </div>
                    </>
                  ) : (
                    <><i className={"pi pi-lock"}></i> A tételek kiválasztása csak mentés után lehetséges!</>
                  )}
                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <div className={"p-grid"}>
                    <div className={"p-col-12"}>
                      <h3>
                        <i className={"pi pi-list"}></i> Kiválasztott tételek
                      </h3>
                    </div>
                  </div>
                  {formData.id ? (
                    <>
                      <DataTable size={localStorage.getItem('scale')}
                        emptyMessage="Nincs találat."
                        value={requestItemsTarget} paginator first={lazyParamsRequestItemsTarget.first}
                        totalRecords={totalRecordsRequestItemsTarget} onPage={onPageRequestItemsTarget}
                        onSort={onSortRequestItemsTarget} sortField={lazyParamsRequestItemsTarget.sortField}
                        sortOrder={lazyParamsRequestItemsTarget.sortOrder}
                        onFilter={onFilterRequestItemsTarget} filters={lazyParamsRequestItemsTarget.filters}
                        loading={loadingRequestItemsTarget} lazy
                        paginatorTemplate={pagerRequestItemsTarget} rows={rowsRequestItemsTarget}
                        emptyMessage="Nincs találat."
                        autoLayout={true}
                        selection={requestItemsToDelete} onSelectionChange={e => {
                        setRequestItemsToDelete(e.value)
                      }} dataKey="id"
                        selectionMode="checkbox"
                        filterDisplay="row"
responsiveLayout="scroll"
                        stripedRows
                      >
                        <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                        <Column field="productName" header="Név" sortable filter filterPlaceholder="Név"></Column>
                        <Column field="quantity" header="Db" sortable className={"p-text-right"}></Column>
                      </DataTable>
                      <div className={"p-grid p-mt-1"}>
                        <div className={"p-col-12 p-lg-6 p-xl-6"}>

                        </div>
                        <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                          <Button type="button" label="Kijelöltek eltávolítása"
                                  onClick={onRequestItemsDelete}
                                  loading={window.App.state.loadingPurchaseorderRequestItemsRemove}
                                  className="p-button-danger"/>
                        </div>
                      </div>
                    </>
                  ) : (
                    <><i className={"pi pi-lock"}></i> A tételek megtekintése csak mentés után lehetséges!</>
                  )}
                </div>
              </div>
            </Panel>
          </div>
        </div>
        <div className={"p-grid p-mt-1"}>
          <div className={"p-col-12 p-lg-6 p-xl-6"}>
            {formData && formData.id &&
            <Button type="button" label="Megtekintés" icon="pi pi-eye"
                    onClick={() => {
                      window.App.setState({
                        sidebarPurchaseorderUpdate: false,
                        sidebarPurchaseorderShow: true,
                        props: formData
                      })

                    }}
                    className="p-button p-mr-2"/>
            }
          </div>
          <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
            {loadingDownload === true ? (
              <>
                <Button type="button" label="Ajánlatkérő sablon" icon="pi pi-download" disabled loading
                        className="p-button-secondary p-mr-2"/>
              </>
            ) : (
              <>
                <Button type="button" label="Ajánlatkérő sablon" icon="pi pi-download"
                        className="p-button-secondary p-mr-2"
                        onClick={() => {
                          setLoadingDownload(true)
                          PurchaseorderDownloadService(formData.id)
                            .then(response => {
                              download(response.data, localStorage.getItem('networkName')
                                + '_'
                                + 'ajánlat_sablon'
                                + '_'
                                + formData.orderNumber
                                + '_'
                                + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
                                + '.xlsx')
                              setLoadingDownload(false)
                            })
                            .catch(error => {
                              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                              setLoadingDownload(false)
                            })
                        }}
                />
              </>
            )}
            <Button type="submit" label="Mentés és bezár" icon={"pi pi-check"} loading={loading}
                    onClick={() => {
                      props = {...props, closeForm: true}
                    }}
                    className="p-button-success"/>
            <Button type="submit" label="Mentés" icon={"pi pi-check"} loading={loading}
                    onClick={() => {
                      props = {...props, closeForm: false}
                    }}
                    className="p-button-success p-ml-2"/>

          </div>
        </div>
      </form>
    )}/>
  )
}
export default withTranslation('common')(PurchaseorderFormComponent);
