import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {TabView, TabPanel} from 'primereact/tabview';
import moment from "moment";
import currencyFormatter from "currency-formatter";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import download from "js-file-download";

import ReceiptShowService from "../../services/receipt/ReceiptShowService";
import ReceiptFileGetService from "../../services/receipt/ReceiptFileGetService";
import ReceiptDownloadPdfService from "../../services/receipt/ReceiptDownloadPdfService";
import ReceiptNavListService from "../../services/receipt/ReceiptNavListService";
import ReceiptNavAnnualService from "../../services/receipt/ReceiptNavAnnualService";
import ReceiptNavNewService from "../../services/receipt/ReceiptNavNewService";
import ReceiptNavRefreshService from "../../services/receipt/ReceiptNavRefreshService";
import ReceiptParentsComponent from "./ReceiptParentsComponent";
import ReceiptDeleteService from "../../services/receipt/ReceiptDeleteService";
import ReceiptStornoService from "../../services/receipt/ReceiptStornoService";
import VatListService from "../../services/vat/VatListService";
import {InputMask} from "primereact/inputmask";
import ReceiptBatchService from "../../services/receipt/ReceiptBatchService";
import ReceiptListService from "../../services/receipt/ReceiptListService";
import PartneraddressListService from "../../services/partneraddress/PartneraddressListService";

const ReceiptShowComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [partnerAddresses, setPartnerAddresses] = useState([]);
  const [shippingAddress, setShippingAddress] = useState({});
  const [loading, setLoading] = useState(false);
  const [navLoading, setNavLoading] = useState(false);
  const [file, setFile] = useState(false);
  const [vats, setVats] = useState([]);
  const [navReports, setNavReports] = useState([]);
  const [selectedDate, setSelectedDate] = useState();

  const priceFormatter = (price) => {
    let currency
    if (formData.currency === 'HUF') {
      currency = {
        precision: 2,
        symbol: '',
        decimal: ',',
        thousand: ' ',
      }
    } else {
      currency = {
        precision: 2,
        symbol: '',
        decimal: '.',
        thousand: ' ',
      }
    }
    return (
      <>
        <span className={""}>
        {currencyFormatter.format(
          price, {
            precision: currency.precision,
            symbol: currency.symbol,
            decimal: currency.decimal,
            thousand: currency.thousand,
          }
        )
        }
          &nbsp;{formData.currency}
          </span>
      </>
    )
  }

  const vat = (id) => {
    if (vats && vats[0]) {
      let vat = vats.filter(item => item.id === id)
      if (vat[0] && vat[0].acronym) {
        return vat[0].acronym
      }
    }
  }

  useEffect(() => {
    setLoading(true)
    VatListService()
      .then(response => {
        setVats(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    ReceiptShowService(props.initialValues.id)
      .then(response => {
        setFormData(response.data)
        if(response.data.partner && response.data.partner.id) {
          PartneraddressListService('', response.data.partner.id)
            .then(response => {
              let _partnerAddresses = []
              response.data.items.map((address, index) => {
                _partnerAddresses.push({
                  id: address.id,
                  active: address.active,
                  addressCity: address.addressCity,
                  addressCountryCode: address.addressCountryCode,
                  addressDetail: address.addressDetail,
                  addressPostalCode: address.addressPostalCode,
                  note: address.note,
                  type: address.type,
                  fullAddress: address.addressPostalCode + " " + address.addressCity + " " + address.addressDetail,
                })
              })
              setPartnerAddresses(_partnerAddresses)
            })
        }
        ReceiptFileGetService(props.initialValues.id)
          .then(response => {
            setLoading(false)
            if (response.data && response.data.file && response.data.file !== null) {
              setFile(response.data.file)
            }
          })
          .catch(error => {
            setLoading(false)
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          })
        if (process.env.REACT_APP_COMPANY_NAV_INTERFACE === 'true' && response.data.isNavInvoice === true) {
          ReceiptNavListService(response.data.id, JSON.stringify({
            sortField: "reportDate",
            sortOrder: 1
          }))
            .then(response => {
              setNavReports(response.data.items)
            })
            .catch(error => {
              setLoading(false)
              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
            })
        }
      })
      .catch(error => {
        setLoading(false)
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    // CompanyLogoShowService(props.initialValues.company.id)
    //   .then(response => {
    //     setCompanyLogo(response.data)
    //   })
    //   .catch(error => {
    //     window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
    //   })
  }, [])

  useEffect(() => {
    if (partnerAddresses && partnerAddresses[0] && formData.partnerShippingAddress && formData.partnerShippingAddress.id) {
      let shippingAddress = partnerAddresses.filter(item => item.id === formData.partnerShippingAddress.id)
      setShippingAddress(shippingAddress[0])
    }
  }, [formData.partnerShippingAddress, partnerAddresses])

  return (
    <>
      {loading === true ? (
        <>
          <span className={"p-d-block p-py-4"} style={{fontSize: '1.5em'}}>
            <i className="pi pi-spin pi-spinner" style={{fontSize: '0.9em'}}></i>
            &nbsp;Betöltés...
          </span>
        </>
      ) : (
        <>
          <h2 className={"text-primary p-mb-0"}>Bizonylat megtekintése</h2>
          <div className={"p-grid p-mb-2"}>
            {formData && formData.receiptNumber &&
            <div className={"p-col"}>
              <small className={"p-text-uppercase"}>
                Sorszám
              </small>
              <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                <>{formData.receiptNumber}</>
              </p>
            </div>
            }
            {formData && formData.receiptGroupName &&
            <div className={"p-col"}>
              <small className={"p-text-uppercase"}>
                Bizonylat tömb
              </small>
              <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                <>{formData.receiptGroupName}</>
              </p>
            </div>
            }
            <div className={"p-col"}>
              <small className={"p-text-uppercase"}>
                Fizetve
              </small>
              <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                {formData.isPaid === true ? (
                  <>
                    <i className={"pi pi-check text-success"}></i>&nbsp;
                    {formData.paidDate &&
                    <>
                      {moment.utc(formData.paidDate).local().format('YYYY.MM.DD.')}
                    </>
                    }

                  </>
                ) : (
                  <>
                    <i className={"pi pi-times text-danger"}></i>&nbsp; Nincs
                  </>
                )}
              </p>
            </div>
            {formData && formData.name &&
            <div className={"p-col"}>
              <small className={"p-text-uppercase"}>
                Bizonylat tömb
              </small>
              <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                <>{formData.name}</>
              </p>
            </div>
            }
            {formData && formData.receiptType &&
            <div className={"p-col"}>
              <small className={"p-text-uppercase"}>
                Típus
              </small>
              <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                {formData.receiptType === 'invoice' && formData.outgoing === true &&
                <>
                  {
                    formData.isStornoReceipt === true &&
                    <>Sztornó számla</>
                  }
                  {formData.isReversedReceipt === true &&
                  <>Sztornózott számla</>
                  }
                  {formData.isReversedReceipt !== true && formData.isStornoReceipt !== true &&
                  <>Számla</>
                  }
                </>
                }
                {formData.receiptType === 'invoice' && formData.outgoing === false &&
                <>Költségszámla</>
                }
                {formData.receiptType === 'fee_request' &&
                <>Díjbekérő</>
                }
                {formData.receiptType === 'delivery_note' &&
                <>Szállítólevél</>
                }
              </p>
            </div>
            }
            {formData && formData.partnerType &&
            <div className={"p-col"}>
              <small className={"p-text-uppercase"}>
                Partner típus
              </small>
              <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                {formData.partnerType === "person" &&
                <>
                  Személy
                </>
                }
                {formData.partnerType === "organisation" &&
                <>
                  Cég
                </>
                }
                {formData.partnerType === "other" &&
                <>
                  Egyéb
                </>
                }
              </p>
            </div>
            }
          </div>
          <hr/>
          <TabView className="tabview-header-icon menu" renderActiveOnly="true">
            <TabPanel header="Előnézet" leftIcon="pi pi-eye">
              <div style={{width: '21cm', minHeight: '29cm', borderRadius: '0', margin: 'auto'}}>
                <Panel className={"p-mx-auto p-p-5"} style={{width: '21cm', minHeight: '29cm', borderRadius: '0'}}>
                  <div className={"p-grid"}>
                    <div className={"p-col-12 p-lg-6 p-col-align-center"}>
                      {formData && formData.company && formData.company.id &&
                      <img src={process.env.REACT_APP_API_HOST + "/company/logo/" + formData.company.id
                      + "?token=" + localStorage.getItem("userToken") + "?date=" + new Date()}
                           className={"w-100 p-d-block"} height="30mm" style={{width: "auto",}}/>
                      }
                    </div>
                    <div className={"p-col-12 p-lg-6"}>
                      <h1 className={"p-m-0 p-p-0 p-text-uppercase p-text-right p-text-normal"}>
                        {formData.receiptType === 'invoice' && formData.outgoing === true &&
                        <>
                          Számla
                        </>
                        }
                        {formData.receiptType === 'invoice' && formData.outgoing === false &&
                        <>Költségszámla</>
                        }
                        {formData.receiptType === 'delivery_note' &&
                        <>Szállítólevél</>
                        }
                        {formData.receiptType === 'fee_request' &&
                        <>Díjbekérő</>
                        }
                      </h1>
                    </div>
                  </div>
                  <div className={"p-grid p-mt-1"}>
                    <div className={"p-col"}>
                      <label>
                        {formData.receiptType === 'delivery_note' ? (
                          <>
                            Szállítólevél kelte
                          </>
                        ) : (
                          <>
                            Bizonylat kelte
                          </>
                        )}
                      </label>
                      <strong className={"p-d-block"}>
                        {moment.utc(formData.issueDate).local().format('YYYY.MM.DD.')}
                      </strong>
                    </div>
                    {formData.receiptType !== 'delivery_note' &&
                    <div className={"p-col"}>
                      <label>
                        Teljesítés
                      </label>
                      <strong className={"p-d-block"}>
                        {formData.deliveryDate && formData.deliveryDate !== null &&
                        <>
                          {moment.utc(formData.deliveryDate).local().format('YYYY.MM.DD.')}
                        </>
                        }
                      </strong>
                    </div>
                    }
                    {formData.receiptType !== 'delivery_note' &&
                    <div className={"p-col"}>
                      <label>
                        Fiz. határidő
                      </label>
                      <strong className={"p-d-block"}>
                        {moment.utc(formData.paymentDate).local().format('YYYY.MM.DD.')}
                      </strong>
                    </div>
                    }
                    {formData.receiptType !== 'delivery_note' &&
                    <div className={"p-col"}>
                      <label>
                        Fizetés módja
                      </label>
                      <strong className={"p-d-block"}>
                        {formData.paymentMethodName}
                      </strong>
                    </div>
                    }
                    <div className={"p-col p-text-right p-text-nowrap"}>
                      <label>
                        {formData.receiptType === 'delivery_note' ? (
                          <>
                            Szállítólevél sorszáma
                          </>
                        ) : (
                          <>
                            Bizonylat sorszáma
                          </>
                        )}
                      </label>
                      <strong className={"p-d-block"}>
                        {formData.receiptNumber}
                      </strong>
                    </div>
                  </div>
                  <div className={"p-grid p-mt-1"}>
                    <div className={"p-col"}>
                      <label>
                        {formData.outgoing === true &&
                        <>Szállító</>
                        }
                        {formData.outgoing === false &&
                        <>Vevő</>
                        }
                      </label>
                      <h3 className={"p-m-0 p-p-0"}>
                        {formData.companyName}
                      </h3>
                      {formData.companyAddressPostalCode}&nbsp;{formData.companyAddressCity}
                      <br/>
                      {formData.companyAddressDetail}
                      <br/>
                      Adószám: {formData.companyVatNumber}
                      {formData.companyCommunityVatNumber && formData.companyCommunityVatNumber !== null &&
                      <>
                        <br/>
                        Közösségi adószám: {formData.companyCommunityVatNumber}
                      </>
                      }
                      {formData.companyBankAccountNumber && formData.companyBankAccountNumber !== null &&
                      <>
                        <br/>
                        Számlaszám: {formData.companyBankAccountNumber}
                      </>
                      }
                      {formData.companyIBAN && formData.companyIBAN !== null &&
                      <>
                        <br/>
                        IBAN: {formData.companyIBAN}
                      </>
                      }
                      {formData.companySwift && formData.companySwift !== null &&
                      <>
                        <br/>
                        Swift: {formData.companySwift}
                      </>
                      }
                    </div>
                    <div className={"p-col"}>
                      <label>
                        {formData.outgoing === true &&
                        <>Vevő</>
                        }
                        {formData.outgoing === false &&
                        <>Szállító</>
                        }
                      </label>
                      <h3 className={"p-m-0 p-p-0"}>
                        {formData.partnerName}
                      </h3>
                      {formData.partnerShippingAddress ? (
                        <>
                          Szállítási cím:&nbsp;
                          {shippingAddress.addressPostalCode}&nbsp;{shippingAddress.addressCity}
                          <br/>
                          {shippingAddress.addressDetail}
                        </>
                      ):(
                        <>
                          {formData.partnerAddressPostalCode}&nbsp;{formData.partnerAddressCity}
                          <br/>
                          {formData.partnerAddressDetail}
                        </>
                      )}
                      {formData.partnerVatNumber && formData.partnerVatNumber !== null &&
                      <>
                        <br/>
                        Adószám: {formData.partnerVatNumber}
                      </>
                      }
                      {formData.partnerCommunityVatNumber && formData.partnerCommunityVatNumber !== null &&
                      <>
                        <br/>
                        Közösségi adószám: {formData.companyCommunityVatNumber}
                      </>
                      }
                    </div>
                  </div>
                  {formData.receiptType !== 'delivery_note' ? (
                    <table className={"p-mt-3 w-100"} cellPadding={'5px'} cellSpacing={0}>
                      <tr>
                        <th className={"p-text-normal p-text-uppercase p-text-left"}
                            style={{borderBottom: '1px solid #ccc'}}>
                          Mennyiség
                        </th>
                        <th className={"p-text-normal p-text-uppercase"} style={{borderBottom: '1px solid #ccc'}}>
                          Nettó egységár
                        </th>
                        <th className={"p-text-normal p-text-uppercase"} style={{borderBottom: '1px solid #ccc'}}>
                          ÁFA
                        </th>
                        <th className={"p-text-normal p-text-uppercase"} style={{borderBottom: '1px solid #ccc'}}>
                          Nettó érték
                        </th>
                        <th className={"p-text-normal p-text-uppercase"} style={{borderBottom: '1px solid #ccc'}}>
                          ÁFA érték
                        </th>
                        <th className={"p-text-normal p-text-uppercase p-text-right"}
                            style={{borderBottom: '1px solid #ccc'}}>
                          Bruttó érték
                        </th>
                      </tr>
                      {formData.items && formData.items[0] ? (
                        [formData.items.map((item, index) => {
                          return (
                            <>
                              <tr>
                                <td colSpan={6}>
                                  <strong>
                                    {item.description}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {item.quantity}&nbsp;{item.unitName}
                                </td>
                                <td className={"p-text-right"}>
                                  {priceFormatter(item.unitPrice)}
                                </td>
                                <td>
                                  {vat(item.vat)}
                                </td>
                                <td className={"p-text-right"}>
                                  {priceFormatter(item.netAmount)}
                                </td>
                                <td className={"p-text-right"}>
                                  {priceFormatter(item.vatAmount)}
                                </td>
                                <td className={"p-text-right"}>
                                  {priceFormatter(item.grossAmount)}
                                </td>
                              </tr>
                            </>
                          )
                        })]
                      ) : (
                        <tr>
                          <td colSpan={6}>
                            <small>Nincs tétel rögzítve.</small>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <th colSpan={3} className={"p-text-left"} style={{borderTop: '1px solid #ccc'}}>
                          <strong>
                            Összesen
                          </strong>
                        </th>
                        <th className={"p-text-right"} style={{borderTop: '1px solid #ccc'}}>
                          <strong>
                            {priceFormatter(formData.netAmount)}
                          </strong>
                        </th>
                        <th className={"p-text-right"} style={{borderTop: '1px solid #ccc'}}>
                          <strong>
                            {priceFormatter(formData.vatAmount)}
                          </strong>
                        </th>
                        <th className={"p-text-right"} style={{borderTop: '1px solid #ccc'}}>
                          <strong>
                            {priceFormatter(formData.grossAmount)}
                          </strong>
                        </th>
                      </tr>
                    </table>
                    ):(
                    <table className={"p-mt-3 w-100"} cellPadding={'5px'} cellSpacing={0}>
                      <tr>
                        <th className={"p-text-normal p-text-uppercase p-text-left"}
                            style={{borderBottom: '1px solid #ccc'}}>
                          Tétel
                        </th>
                        <th className={"p-text-normal p-text-uppercase p-text-right"}
                            style={{borderBottom: '1px solid #ccc'}}>
                          Mennyiség
                        </th>
                      </tr>
                      {formData.items && formData.items[0] ? (
                        [formData.items.map((item, index) => {
                          return (
                            <>
                              <tr>
                                <td>
                                  <strong>
                                    {item.description}
                                  </strong>
                                </td>
                                <td className={"p-text-right"}>
                                  {item.quantity}&nbsp;{item.unitName}
                                </td>
                              </tr>
                            </>
                          )
                        })]
                      ) : (
                        <tr>
                          <td colSpan={6}>
                            <small>Nincs tétel rögzítve.</small>
                          </td>
                        </tr>
                      )}
                    </table>
                    )}
                  <div className={"p-grid p-mt-1"}>
                    {formData.projectSummary && formData.projectSummary[0] &&
                    <div className={"p-col"}>
                      <table className={"w-100"}>
                        <tr>
                          <th className={"p-text-normal p-text-uppercase p-text-left"}
                              style={{borderBottom: '1px solid #ccc'}}>
                            {t('project')}
                          </th>
                          <th className={"p-text-normal p-text-uppercase p-text-right"}
                              style={{borderBottom: '1px solid #ccc'}}>
                            Összesen
                          </th>
                        </tr>
                        {formData.projectSummary && formData.projectSummary[0] &&
                        [formData.projectSummary.map((item, index) => {
                          return (
                            <>
                              <tr>
                                <td>
                                  {item.projectName}
                                </td>
                                <td className={"p-text-right p-text-nowrap"}>
                                  {priceFormatter(item.totalNetAmount)}
                                </td>
                              </tr>
                            </>
                          )
                        })]
                        }
                      </table>
                    </div>
                    }
                    {formData.costTypeSummary && formData.costTypeSummary[0] &&
                    <div className={"p-col"}>
                      <table className={"w-100"}>
                        <tr>
                          <th className={"p-text-normal p-text-uppercase p-text-left"}
                              style={{borderBottom: '1px solid #ccc'}}>
                            Költségnem
                          </th>
                          <th className={"p-text-normal p-text-uppercase p-text-right"}
                              style={{borderBottom: '1px solid #ccc'}}>
                            Összesen
                          </th>
                        </tr>
                        {formData.costTypeSummary && formData.costTypeSummary[0] &&
                        [formData.costTypeSummary.map((item, index) => {
                          return (
                            <>
                              <tr>
                                <td>
                                  {item.costTypeName}
                                </td>
                                <td className={"p-text-right p-text-nowrap"}>
                                  {priceFormatter(item.totalNetAmount)}
                                </td>
                              </tr>
                            </>
                          )
                        })]
                        }
                      </table>
                    </div>
                    }
                  </div>
                  {formData && formData.partnerNote &&
                  <p className={"p-mt-3"}>
                    <strong>
                      Megjegyzés:
                    </strong>
                    {formData.partnerNote}
                  </p>
                  }
                </Panel>
                <div className={"p-grid p-mt-1"}>
                  <div className={"p-col-12 p-lg-6"}>
                    {formData.isPaid === true &&
                    <Button type="button" label="Fizetve visszavonása" icon="pi pi-times"
                            className="p-button-danger p-mr-2"
                            onClick={() => {
                              setLoading(true)
                              let data = {
                                method: 'isPaid',
                                value: false,
                                receipts: [formData.id]
                              }
                              ReceiptBatchService(data)
                                .then(response => {
                                  ReceiptShowService(props.initialValues.id)
                                    .then(response => {
                                      setFormData(response.data)
                                      setLoading(false)
                                    })
                                    .catch(error => {
                                      window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                      setLoading(false);
                                    })
                                })
                                .catch(error => {
                                  window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                  setLoading(false);
                                })
                            }}
                    />
                    }
                    {(formData.isPaid === false || !formData.isPaid) &&
                    <div className="p-inputgroup p-d-inline">
                      <InputMask id={"validUntil"}
                                 mask={"9999-99-99"}
                                 placeholder={"ÉÉÉÉ-HH-NN"}
                                 value={selectedDate}
                                 onChange={(e) => {
                                   if (e.value.indexOf("_") === -1) {
                                     if (e.value === '') {
                                       e.value = null
                                     }
                                     setSelectedDate(e.value)
                                   }
                                 }}
                                 style={{width: '160px'}}
                      />
                      {selectedDate === undefined || selectedDate === null || selectedDate === '' || selectedDate.toString().includes('_') ? (
                        <Button type="button" label="Bizonylat fizetve" icon="pi pi-check"
                                className="disabled p-button-warning p-mr-2"
                                onClick={() => alert('Fizetve dátum választása kötelező!')}
                        />
                      ) : (
                        <Button type="button" label="Bizonylat fizetve" icon="pi pi-check"
                                className="p-button-warning p-mr-2"
                                onClick={() => {
                                  setLoading(true)
                                  let data = {
                                    method: 'isPaid',
                                    value: selectedDate,
                                    receipts: [formData.id]
                                  }
                                  ReceiptBatchService(data)
                                    .then(response => {
                                      ReceiptShowService(props.initialValues.id)
                                        .then(response => {
                                          setFormData(response.data)
                                          setLoading(false)
                                        })
                                        .catch(error => {
                                          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                          setLoading(false);
                                        })
                                    })
                                    .catch(error => {
                                      window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                      setLoading(false);
                                    })
                                }}
                        />
                      )}
                    </div>
                    }
                  </div>
                  <div className={"p-col-12 p-lg-6 p-text-right"}>
                    {formData.draft === true ? (
                      <>
                        {formData.id &&
                        <Button type="button" label="Törlés"
                                onClick={() => {
                                  if (window.confirm('A törlés megerősítésére van szükség!')) {
                                    setLoading(true)
                                    ReceiptDeleteService(formData.id)
                                      .then(response => {
                                        window.App.toastShow('success', 'Sikeres törlés!');
                                        window.App.setState({
                                          rerenderReceiptList: true,
                                          sidebarReceiptUpdate: false,
                                          sidebarReceiptCreate: false,
                                          sidebarReceiptShow: false,
                                        })
                                        setLoading(false)
                                      })
                                      .catch(error => {
                                        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                        setLoading(false)
                                      })
                                  }
                                }}
                                loading={loading}
                                icon={"pi pi-trash"}
                                className="p-button-danger p-ml-2"/>
                        }
                      </>
                    ) : (
                      <>
                        {window.App.state.props.external === true ? (
                          <>
                            {/*{formData.id &&*/}
                            {/*<Button type="button" label="Törlés"*/}
                            {/*        loading={loading}*/}
                            {/*        icon={"pi pi-trash"}*/}
                            {/*        className="p-button-danger p-ml-2 disabled"/>*/}
                            {/*}*/}
                          </>
                        ) : (
                          <>
                            {formData.receiptType === 'invoice' &&
                            <>
                              {(formData.id && formData.isReversedReceipt !== true && formData.isStornoReceipt !== true) &&
                              <Button type="button" label="Sztornó"
                                      onClick={() => {
                                        if (window.confirm('A törlés megerősítésére van szükség!')) {
                                          setLoading(true)
                                          ReceiptStornoService(formData.id)
                                            .then(response => {
                                              window.App.toastShow('success', 'Sikeres sztornózás!');
                                              window.App.setState({
                                                rerenderReceiptList: true,
                                                sidebarReceiptUpdate: false,
                                                sidebarReceiptCreate: false,
                                                sidebarReceiptShow: false,
                                              })
                                              setLoading(false)
                                            })
                                            .catch(error => {
                                              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                              setLoading(false)
                                            })
                                        }
                                      }}
                                      loading={loading}
                                      icon={"pi pi-times"}
                                      className="p-button-danger p-ml-2"/>
                              }
                            </>
                            }
                            {formData.receiptType === 'fee_request' &&
                            <>
                              <Button type="button" label="Törlés"
                                      onClick={() => {
                                        if (window.confirm('A törlés megerősítésére van szükség!')) {
                                          setLoading(true)
                                          ReceiptDeleteService(formData.id)
                                            .then(response => {
                                              window.App.toastShow('success', 'Sikeres törlés!');
                                              window.App.setState({
                                                rerenderReceiptList: true,
                                                sidebarReceiptUpdate: false,
                                                sidebarReceiptCreate: false,
                                                sidebarReceiptShow: false,
                                              })
                                              setLoading(false)
                                            })
                                            .catch(error => {
                                              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                              setLoading(false)
                                            })
                                        }
                                      }}
                                      loading={loading}
                                      icon={"pi pi-trash"}
                                      className="p-button-danger p-ml-2"/>
                            </>
                            }
                          </>
                        )}
                      </>
                    )}
                    {formData.outgoing === true ? (
                      <Button icon="pi pi-download" className="p-button p-ml-2"
                              label={"Letöltés"}
                              onClick={() => {
                                ReceiptDownloadPdfService(formData.id)
                                  .then(response => {
                                    window.App.toastShow('success', 'Sikeres letöltés!',);
                                    download(response.data, localStorage.getItem('networkName')
                                      + '_'
                                      +
                                      'bizonylat'
                                      + '_'
                                      + formData.receiptNumber
                                      + '.pdf')
                                  })
                                  .catch(error => {
                                    window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                  })
                              }}/>
                    ) : (
                      <Button icon="pi pi-download" className="p-button p-ml-2"
                              label={"Letöltés"}
                              disabled/>
                    )}
                  </div>
                </div>
              </div>
            </TabPanel>
            {file && file !== false ? (
              <TabPanel header="Bizonylat kép" leftIcon="pi pi-file">
                <div style={{maxWidth: '21cm', minHeight: '29cm', borderRadius: '0', margin: 'auto'}}>
                  {file && file.toString().includes('data:application/') &&
                  <>
                    {navigator.userAgent.indexOf("Safari") != -1 ? (
                      <iframe
                        src={file}
                        width='100%'
                        height='987px'
                        className={"p-d-block p-mb-4"}
                      />
                    ) : (
                      <object
                        data={file + "#toolbar=0&navpanes=0&scrollbar=0"}
                        type='application/pdf'
                        width='100%'
                        height='987px'
                        className={"p-d-block p-mb-4"}
                      />
                    )}
                  </>
                  }
                  {file && file.toString().includes('data:image/') &&
                  <img src={file} className={"w-100 p-d-block p-mb-4"}/>
                  }
                </div>
              </TabPanel>
            ) : (
              <TabPanel disabled></TabPanel>
            )}
            {formData.external === true ? (
              <TabPanel header="Kapcsolódó bizonylatok" leftIcon="pi pi-link">
                <div className={"p-grid"}>
                  <div className={"p-col"}>
                    <ReceiptParentsComponent initialValues={formData}/>
                  </div>
                </div>
              </TabPanel>
            ) : (
              <TabPanel disabled></TabPanel>
            )}
            {process.env.REACT_APP_COMPANY_NAV_INTERFACE === 'true' && formData.isNavInvoice === true && formData.receiptNumber !== 'Vázlat' ? (
              <TabPanel header="NAV adatszolgáltatások" leftIcon="pi pi-file">
                <div className={"p-grid"}>
                  <div className={"p-col"}>
                    <DataTable size={localStorage.getItem('scale')} value={navReports}
                               emptyMessage="Nincs találat."
                               autoLayout={true}
                               filterDisplay="row"
                               responsiveLayout="scroll"
                               stripedRows
                               className={"p-mb-3"}
                    >
                      <Column body={(rowData) => {
                        return (
                          <>
                            {rowData && rowData.type !== 'ANNULMENT' && rowData.lastStatus === 'DONE' ? (
                              <Button icon="pi pi-trash" className=" p-mr-2 p-button-danger"
                                      tooltip="Tranzakció visszavonása"
                                      loading={navLoading}
                                      onClick={() => {
                                        setNavLoading(true)
                                        ReceiptNavAnnualService(formData.id, rowData.id)
                                          .then(response => {
                                            setTimeout(() => {
                                              ReceiptNavListService(formData.id, JSON.stringify({
                                                sortField: "reportDate",
                                                sortOrder: 1
                                              }))
                                                .then(response => {
                                                  setNavReports(response.data.items)
                                                  setNavLoading(false)
                                                })
                                                .catch(error => {
                                                  setNavLoading(false)
                                                  window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                                })
                                            }, 800)
                                          })
                                          .catch(error => {
                                            setNavLoading(false)
                                            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                          })
                                      }}/>
                            ) : (
                              <Button icon="pi pi-trash" className=" p-mr-2 p-button-danger"
                                      tooltip="Tranzakció visszavonása"
                                      disabled
                                      loading={navLoading}
                              />
                            )}
                            <Button icon="pi pi-refresh" className=" p-mr-2 p-button-warning"
                                    tooltip="Státusz frissítése"
                                    loading={navLoading}
                                    onClick={() => {
                                      setNavLoading(true)
                                      ReceiptNavRefreshService(formData.id, rowData.id)
                                        .then(response => {
                                          ReceiptNavListService(formData.id, JSON.stringify({
                                            sortField: "reportDate",
                                            sortOrder: 1
                                          }))
                                            .then(response => {
                                              setNavReports(response.data.items)
                                              setNavLoading(false)
                                            })
                                            .catch(error => {
                                              setNavLoading(false)
                                              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                            })
                                        })
                                        .catch(error => {
                                          setNavLoading(false)
                                          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                        })
                                    }}/>
                          </>
                        )
                      }} className={"p-py-0 p-text-nowrap"}/>
                      <Column field="lastStatus" header="Státusz" sortable
                              body={(rowData) => {
                                return (
                                  <>
                                    {rowData.lastStatus === 'PROCESSING' &&
                                    <>
                                          <span className={"text-warning"}><i
                                            className={"pi pi-exclamation-circle"}></i> Feldolgozás folyamatban</span>
                                    </>
                                    }
                                    {rowData.lastStatus === 'RECEIVED' &&
                                    <>
                                          <span className={"text-warning"}><i
                                            className={"pi pi-exclamation-circle"}></i> Információk átadva</span>
                                    </>
                                    }
                                    {rowData.lastStatus === 'SAVED' &&
                                    <>
                                      <span className={"text-success"}><i className={"pi pi-check"}></i> Információk rögzítve</span>
                                    </>
                                    }
                                    {rowData.lastStatus === 'ABORTED' &&
                                    <>
                                      <span className={"text-danger"}><i
                                        className={"pi pi-times"}></i> Megszakítva</span>
                                    </>
                                    }
                                    {rowData.lastStatus === 'DONE' &&
                                    <>
                                          <span className={"text-success"}><i
                                            className={"pi pi-check"}></i> Sikeres</span>
                                    </>
                                    }
                                    {rowData.lastStatus === null &&
                                    <>
                                      <span><i className={"pi pi-times"}></i> Nincs adat</span>
                                    </>
                                    }
                                    {rowData.lastStatusMessage !== null &&
                                    <>
                                      <br/>
                                      <small style={{
                                        fontSize: '10px',
                                        wordWrap: 'break-word',
                                        display: 'block',
                                        maxWidth: '220px'
                                      }}>{rowData.lastStatusMessage}</small>
                                    </>
                                    }
                                  </>
                                )
                              }}
                      />
                      <Column field="type" header="Típus" sortable
                              body={(rowData) => {
                                return (
                                  <>
                                    {rowData.type === 'CREATE' &&
                                    <>
                                      Kiállított számla
                                    </>
                                    }
                                    {rowData.type === 'STORNO' &&
                                    <>
                                      Sztornó számla
                                    </>
                                    }
                                    {rowData.type === 'MODIFY' &&
                                    <>
                                      Módosító számla
                                    </>
                                    }
                                    {rowData.type === 'ANNULMENT' &&
                                    <>
                                      Technikai érvénytelenítés
                                    </>
                                    }
                                  </>
                                )
                              }}></Column>
                      <Column field="transactionId" header="Azonosító" filter sortable
                              filterPlaceholder="Azonosító"></Column>
                      <Column field="reportDate" header="Időpont" sortable className={"p-text-nowrap"}
                              body={(rowData) => {
                                return (
                                  <>{moment.utc(rowData.reportDate).local().format('YYYY-MM-DD HH:mm:ss')}</>
                                )
                              }}></Column>
                    </DataTable>
                    <div className={"p-grid p-mt-1"}>
                      <div className={"p-col-12 p-lg-12 p-xl-12 p-text-right p-col-align-center"}>
                        <Button type="button" label="Új adatszolgáltatás indítása" loading={navLoading}
                                icon="pi pi-plus" className="p-button-success p-ml-2"
                                onClick={() => {
                                  setNavLoading(true)
                                  ReceiptNavNewService(formData.id)
                                    .then(response => {
                                      ReceiptNavListService(formData.id, JSON.stringify({
                                        sortField: "reportDate",
                                        sortOrder: 1
                                      }))
                                        .then(response => {
                                          setNavReports(response.data.items)
                                          setNavLoading(false)
                                        })
                                        .catch(error => {
                                          setNavLoading(false)
                                          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                        })
                                    })
                                    .catch(error => {
                                      setNavLoading(false)
                                      window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                    })
                                }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
            ) : (
              <TabPanel disabled></TabPanel>
            )}
          </TabView>
        </>
      )}
    </>
  )
}
export default withTranslation('common')(ReceiptShowComponent);
