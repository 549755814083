import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Dropdown} from "primereact/dropdown";
import axios from "axios";

const PurchaseorderItemsComponent = (props) => {

  const [purchaseorderitems, setPurchaseorderitems] = useState([]);
  const [loading, setLoading] = useState(false);
  const dt = useRef(null);

  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(Number(process.env.REACT_APP_LIST_ROW_DEFAULT));
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderPurchaseorderitemsList: false})
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_HOST + "/project/purchaseOrderItems/" + props.projectId + "?" + JSON.stringify(lazyParams).replace(',"multiSortMeta":[]', ''),
        params: {},
        headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
      })
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setPurchaseorderitems(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: Number(process.env.REACT_APP_LIST_ROW_OP1), value: Number(process.env.REACT_APP_LIST_ROW_OP1)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP2), value: Number(process.env.REACT_APP_LIST_ROW_OP2)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP3), value: Number(process.env.REACT_APP_LIST_ROW_OP3)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP4), value: Number(process.env.REACT_APP_LIST_ROW_OP4)},
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  // const deletePurchaseorder = (rowData) => {
  //   PurchaseorderDeleteService(rowData.id)
  //     .then(response => {
  //       window.App.toastShow('success', 'Sikeres törlés!',);
  //       loadLazyData();
  //     })
  //     .catch(error => {
  //       window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
  //     })
  // }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>

      </React.Fragment>
    );
  }

  useEffect(() => {
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderPurchaseorderitemsList === true])

  return (

    <DataTable size={localStorage.getItem('scale')}
      emptyMessage="Nincs találat."
      value={purchaseorderitems} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
      onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
      onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
      paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
      autoLayout={true}
      filterDisplay="row"
      responsiveLayout="scroll"
      ref={dt}
      stripedRows
    >
      {/*<Column body={actionBodyTemplate} className={"p-py-0 p-text-right p-text-nowrap"}></Column>*/}
      <Column field="partnerName" header="Szállító" sortable filter filterPlaceholder="Szállító"></Column>
      <Column field="productName" header="Termék" sortable filter filterPlaceholder="Termék"></Column>
      <Column field="deliveryNoteReceiptNumber" header="Szállítólevél" sortable></Column>
      <Column field="articleNumber" header="Cikkszám" sortable></Column>
      <Column field="unitPrice" header="Egységár" sortable></Column>
      <Column field="quantity" header="Mennyiség" className={"p-text-right"} alignHeader="right" body={(rowData) => {
        return (
          <>
            {rowData.quantity} {rowData.unitName}
          </>
        )
      }}></Column>
      <Column field="netAmount" header="Nettó beszerzési érték" className={"p-text-right"} body={(rowData) => {
        return (
          <>
            {rowData.netAmount} {rowData.currency}
          </>
        )
      }}></Column>
    </DataTable>

  )
}
export default withTranslation('common')(PurchaseorderItemsComponent);
