import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Tooltip} from "primereact/tooltip";
import currencyFormatter from "currency-formatter";
import moment from "moment";

import OfferListService from "../../services/offer/OfferListService";
import OfferstatusListService from "../../services/offerstatus/OfferstatusListService";
import ProjectCopyService from "../../services/project/ProjectCopyService";
import OfferCopyService from "../../services/offer/OfferCopyService";
import OfferDownloadService from "../../services/offer/OfferDownloadService";
import download from "js-file-download";
import ProjectItemDownloadService from "../../services/project/ProjectItemDownloadService";

const OfferListComponent = (props) => {

  const [offer, setOffer] = useState([]);
  const [loading, setLoading] = useState(false);
  const dt = useRef(null);

  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(Number(process.env.REACT_APP_LIST_ROW_DEFAULT));
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: 1
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderOfferList: false})
      OfferListService(JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setOffer(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: Number(process.env.REACT_APP_LIST_ROW_OP1), value: Number(process.env.REACT_APP_LIST_ROW_OP1)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP2), value: Number(process.env.REACT_APP_LIST_ROW_OP2)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP3), value: Number(process.env.REACT_APP_LIST_ROW_OP3)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP4), value: Number(process.env.REACT_APP_LIST_ROW_OP4)},
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {localStorage.getItem('roles').toString().includes('role_offer_write') &&
          <>
            <Button icon="pi pi-pencil" className="  p-button-warning p-mr-2"
                    tooltip="Módosítás"
                    onClick={() => {
                      window.App.setState({
                        sidebarOfferView: true,
                        modeOfferView: 'edit',
                        props: rowData,
                        activeindexOfferView: 0,
                      })
                    }}/>
            <Button icon="pi pi-clone" className=" p-button-warning p-mr-2"
                    tooltip="Másolás"
                    onClick={() => {
                      if (window.confirm('A másolás megerősítésére van szükség!')) {
                        OfferCopyService(rowData.id)
                          .then(response => {
                            window.App.setState({
                              props: response.data,
                              rerenderOfferList: true,
                              modeOfferView: 'edit',
                              sidebarOfferView: true,
                              activeindexOfferView: 0,
                            })
                          })
                          .catch(error => {
                            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                          })
                      }
                    }}/>
          </>
        }
        <Button icon="pi pi-eye" className=" p-button p-mr-2"
                tooltip="Megtekintés"
                onClick={() => {
                  window.App.setState({
                    sidebarOfferView: true,
                    props: rowData,
                    activeindexOfferView: 0,
                    modeOfferView: 'show',
                  })
                }}/>
        {/*<Button icon="pi pi-trash" className="  p-button-danger"*/}
        {/*        tooltip="Törlés"*/}
        {/*        onClick={() => {*/}
        {/*          if (window.confirm('A törlés megerősítésére van szükség!')) {*/}
        {/*            deleteOffer(rowData)*/}
        {/*          }*/}
        {/*        }}*/}
        {/*/>*/}
        <Button icon="pi pi-download" className="  p-button p-mr-2"
                tooltip="Letöltés"
                onClick={() => {
                  OfferDownloadService(rowData.id, 'pdf')
                    .then(response => {
                      window.App.toastShow('success', 'Sikeres letöltés!');
                      download(response.data, localStorage.getItem('networkName')
                        + '_'
                        + 'arajanlat'
                        + '_'
                        + rowData.id
                        + '_'
                        + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
                        + '.pdf')
                    })
                    .catch(error => {
                      window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                    })
                }}/>
      </React.Fragment>
    );
  }

  const [statuses, setStatuses] = useState([]);
  const status = (rowData) => {
    if (statuses && statuses[0]) {
      let status = statuses.filter(item => item.id === rowData.status)
      if (status[0] && status[0].name) {
        return status[0].name
      }
    }
  }

  useEffect(() => {
    OfferstatusListService()
      .then(response => {
        setStatuses(response.data);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderOfferList === true])

  return (
    <>
      {props && props.view === 'simple' &&
        <>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6 p-col-align-center p-text-center p-text-lg-left"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-col-align-center p-text-center p-text-lg-right"}>
              {localStorage.getItem('roles').toString().includes('role_offer_write') &&
                <a onClick={() => window.App.setState({sidebarOfferCreate: true})}
                   className={"p-button  p-button-success"}>
                  <i className={"pi pi-plus"}></i>
                  &nbsp;
                  Új árajánlat
                </a>
              }
            </div>
          </div>
        </>
      }
      <DataTable size={localStorage.getItem('scale')}
        emptyMessage="Nincs találat."
        value={offer} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
        paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
        autoLayout={true}
        filterDisplay="row"
        responsiveLayout="scroll"
        stripedRows
        ref={dt}
      >
        <Column field="name" header="Tárgy" sortable filter filterPlaceholder="Tárgy"
                className={""}></Column>
        <Column field="status" header="Státusz" sortable body={status}
                filter
                filterElement={() => {
                  return (
                    <>
                      {lazyParams && lazyParams.filters && lazyParams.filters.status ? (
                        <Dropdown options={statuses}
                                  id={"statusFilter"}
                                  optionValue={"id"}
                                  optionLabel={"name"}
                                  showClear
                                  emptyMessage={"Még nincs státusz..."}
                                  onChange={(e) => {
                                    dt.current.filter(e.value, 'status', '');
                                  }}
                                  value={lazyParams.filters.status.value}
                        />
                      ) : (
                        <Dropdown options={statuses}
                                  id={"statusFilter"}
                                  optionValue={"id"}
                                  optionLabel={"name"}
                                  showClear
                                  emptyMessage={"Még nincs státusz..."}
                                  onChange={(e) => {
                                    dt.current.filter(e.value, 'status', '');
                                  }}
                        />
                      )}
                    </>
                  )
                }}
        ></Column>
        <Column field="offerNumber" header="Ajánlat szám" sortable filter filterPlaceholder="Ajánlat szám"
                className={"p-text-right p-text-nowrap"}></Column>
        <Column field="netAmount" header="Nettó összesen" sortable className={"p-text-right p-text-nowrap"}
                alignHeader="right"
                body={(rowData) => {
                  let currency
                  if (rowData.currency === 'HUF') {
                    currency = {
                      precision: 2,
                      symbol: '',
                      decimal: ',',
                      thousand: ' ',
                    }
                  } else {
                    currency = {
                      precision: 2,
                      symbol: '',
                      decimal: '.',
                      thousand: ' ',
                    }
                  }
                  return (
                    <>
                      {currencyFormatter.format(
                        rowData.sumNetAmount, {
                          precision: currency.precision,
                          symbol: currency.symbol,
                          decimal: currency.decimal,
                          thousand: currency.thousand,
                        }
                      )}&nbsp;{rowData.currency}
                    </>
                  )
                }}></Column>
        <Column field="vatAmount" header="ÁFA" sortable className={"p-text-right p-text-nowrap"}
                alignHeader="right"
                body={(rowData) => {
                  let currency
                  if (rowData.currency === 'HUF') {
                    currency = {
                      precision: 2,
                      symbol: '',
                      decimal: ',',
                      thousand: ' ',
                    }
                  } else {
                    currency = {
                      precision: 2,
                      symbol: '',
                      decimal: '.',
                      thousand: ' ',
                    }
                  }
                  return (
                    <>
                      {currencyFormatter.format(
                        rowData.vatAmount, {
                          precision: currency.precision,
                          symbol: currency.symbol,
                          decimal: currency.decimal,
                          thousand: currency.thousand,
                        }
                      )}&nbsp;{rowData.currency}
                    </>
                  )
                }}></Column>
        <Column field="grossAmount" header="Bruttó összeg" sortable className={"p-text-right p-text-nowrap"}
                alignHeader="right"
                body={(rowData) => {
                  let currency
                  if (rowData.currency === 'HUF') {
                    currency = {
                      precision: 2,
                      symbol: '',
                      decimal: ',',
                      thousand: ' ',
                    }
                  } else {
                    currency = {
                      precision: 2,
                      symbol: '',
                      decimal: '.',
                      thousand: ' ',
                    }
                  }
                  return (
                    <>
                      {currencyFormatter.format(
                        rowData.grossAmount, {
                          precision: currency.precision,
                          symbol: currency.symbol,
                          decimal: currency.decimal,
                          thousand: currency.thousand,
                        }
                      )}&nbsp;{rowData.currency}
                    </>
                  )
                }}></Column>
        <Column field="createdAt" header="Létrehozva" sortable className={"p-text-nowrap"} body={(rowData) => {
          if (rowData.createdAt !== null) {
            return (
              <>{moment.utc(rowData.createdAt).local().format('YYYY-MM-DD')}</>
            )
          }
        }}></Column>
        <Column field="validUntil" header="Érvényesség vége" sortable className={"p-text-nowrap"} body={(rowData) => {
          if (rowData.validUntil !== null) {
            return (
              <>{moment.utc(rowData.validUntil).local().format('YYYY-MM-DD')}</>
            )
          }
        }}></Column>
        <Column field="partnerName" header="Partner" sortable filter filterPlaceholder="Partner"></Column>
        <Column field="partnerContactName" header="Partner kapcsolattartó" sortable filter
                filterPlaceholder="Partner kapcsolattartó"></Column>
        <Column field="companyName" header="Vállalkozás" sortable filter filterPlaceholder="Kapcsolattartó"></Column>
        <Column field="companyContactName" header="Vállalkozás kapcsolattartó" sortable filter
                filterPlaceholder="Vállalkozás kapcsolattartó"></Column>
        <Column body={actionBodyTemplate} className={"p-py-0 p-text-right p-text-nowrap"}></Column>
      </DataTable>
    </>
  )
}
export default withTranslation('common')(OfferListComponent);
