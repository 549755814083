import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import moment from "moment";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";

import IdentitydocumenttypeListService from "../../services/identitydocumenttype/IdentitydocumenttypeListService";
import GuestAllowService from "../../services/guest/GuestAllowService";
import GuestDocumentValidService from "../../services/guest/GuestDocumentValidService";
import GuestShowService from "../../services/guest/GuestShowService";
import IdentifierByGuestListService from "../../services/identifier/IdentifierByGuestListService";
import IdentifierListService from "../../services/identifier/IdentifierListService";
import {Field, Form} from "react-final-form";
import {classNames} from "primereact/utils";
import {AutoComplete} from "primereact/autocomplete";

const GuestShowComponent = (props) => {

  const [formData, setFormData] = useState({});
  const [identifiers, setIdentifiers] = useState([]);
  const [loadingInvite, setLoadingInvite] = useState(false);
  const [loadingAllow, setLoadingAllow] = useState(false);
  const [loadingValidate, setLoadingValidate] = useState(false);

  const [identitydocumenttypes, setIdentitydocumenttypes] = useState([]);
  const identityDocumentType = (id) => {
    if (identitydocumenttypes && identitydocumenttypes[0]) {
      let identitydocumenttype = identitydocumenttypes.filter(item => item.id === id)
      if (identitydocumenttype && identitydocumenttype[0] && identitydocumenttype[0].name) {
        return identitydocumenttype[0].name
      }
    }
  }

  // allow dialog

  const [allowDialog, setAllowDialog] = useState(false);
  const [allowLoading, setAllowLoading] = useState(false);
  const [allowFormData, setAllowFormData] = useState({});
  const [allowFormMethod, setAllowFormMethod] = useState('post');

  const allowValidate = (data) => {
    let errors = {};
    if (!data.identifier) {
      errors.identifier = 'A kártya nem lehet üres';
    }
    return errors;
  };

  const allowOnSubmit = (data, form) => {
    setAllowLoading(true)
    GuestDocumentValidService(formData.id, allowFormData)
      .then(response => {
        window.App.toastShow('success', 'Sikeres dokumentum ellőrizés!',);
        window.App.setState({
          rerenderGuestList: true
        })
        GuestShowService(formData.id)
          .then(response => {
            IdentifierByGuestListService('', props.initialValues.id)
              .then(response => {
                setIdentifiers(response.data.items)
              })
              .catch(error => {
                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
              })
            setFormData(response.data)
            setLoadingValidate(false)
            setAllowLoading(false)
            setAllowDialog(false)
          })
          .catch(error => {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          })
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setAllowLoading(false)
      })

    //GuestAllowByIdentifierService(allowFormData, props.initialValues.id)

  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };


  // allowableIdentifier autocomplete
  const [allowableIdentifiers, setAllowableIentifiers] = useState([]);
  const [selectedAllowableIdentifier, setSelectedAllowableIdentifier] = useState('');

  const searchAllowableIdentifiers = (event) => {
    IdentifierListService(JSON.stringify({
      filters: {
        allowGuest: {value: true},
        network: {value: Number(localStorage.getItem("networkId"))}
      }
    }))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          let items = []
          response.data.items.map((item, index) => {
            items.push({
              identifier: item.serial,
              id: item.id,
            })
          })
          setAllowableIentifiers(items)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  useEffect(() => {
    IdentitydocumenttypeListService()
      .then(response => {
        setIdentitydocumenttypes(response.data)
      })
    GuestShowService(props.initialValues.id)
      .then(response => {
        setFormData(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    IdentifierByGuestListService('', props.initialValues.id)
      .then(response => {
        setIdentifiers(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [])

  return (
    <>
      <Panel headerTemplate={
        <div className={"p-grid"}>
          <div className={"p-col-12"}>
            <h3>
              <i className={"pi pi-list"}></i> Alapadatok
            </h3>
          </div>
        </div>
      } className={""}>
        <div className={"p-grid"}>
          {formData.name &&
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <label>Név</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {formData.name}
            </p>
          </div>
          }
          {/*{formData.organisation &&*/}
          {/*<div className={"p-col-12 p-lg-4 p-xl-4"}>*/}
          {/*  <label>Szervezet</label>*/}
          {/*  <p className={"p-mt-1 p-mb-0 p-text-bold"}>*/}
          {/*    {formData.organisation}*/}
          {/*  </p>*/}
          {/*</div>*/}
          {/*}*/}
          {formData.email &&
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <label>Email</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {formData.email}
            </p>
          </div>
          }
          {formData.note &&
          <div className={"p-col-12 p-lg-8 p-xl-8"}>
            <label>Megjegyzés</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {formData.note}
            </p>
          </div>
          }
          {formData.type &&
          <div className={"p-col-12 p-lg-6 p-xl-6"}>
            <label>Típus</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {formData.type === 'serial' &&
              <>
                Sorszámozott vendégkártya
              </>
              }
              {formData.type === 'invite' &&
              <>
                Elektronikus meghívó
              </>
              }
            </p>
          </div>
          }
        </div>
        <div className={"p-grid p-mt-1"}>
          <div className={"p-col-12"}>
            <h3>
              <i className={"pi pi-id-card"}></i> Azonosító
            </h3>
          </div>
        </div>
        <div className={"p-grid"}>
          {formData.identityDocumentType &&
          <div className={"p-col-12 p-lg-6 p-xl-6"}>
            <label>Azonosító dokumentum</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {identityDocumentType(formData.identityDocumentType)}: {formData.identityDocumentNumber && <>{formData.identityDocumentNumber}</>}
            </p>
          </div>
          }
          <div className={"p-col-12 p-lg-6 p-xl-6"}>
            <label>Dokumentum ellenőrizve</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {formData.documentValid === true ? (
                <>Igen</>
              ) : (
                <>Nem</>
              )}
            </p>
          </div>
        </div>
        <div className={"p-grid p-mt-1"}>
          <div className={"p-col-12"}>
            <h3>
              <i className={"pi pi-sitemap"}></i> Vendéglátó
            </h3>
          </div>
        </div>
        <div className={"p-grid"}>
          {formData.companyName &&
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <label>Cég</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {formData.companyName}
            </p>
          </div>
          }
          {formData.active &&
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <label htmlFor="email">Felhasznált</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {formData.active === true ? (
                <>Nem</>
              ) : (
                <>Igen</>
              )}
            </p>
          </div>
          }
        </div>
        <div className={"p-grid p-mt-1"}>
          <div className={"p-col-12 p-lg-8 p-xl-8"}>
            {/*{loadingInvite === true ?(*/}
            {/*  <Button type="button" label="Meghívás" loading loadingIcon="pi pi-spin pi-spinner" className="p-button-success p-mr-2"/>*/}
            {/*):(*/}
            {/*  <Button type="button" label="Meghívás" icon="pi pi-envelope" className="p-button-info p-mr-2"*/}
            {/*          onClick={() => {*/}
            {/*            EmployeeInviteService(formData.id)*/}
            {/*              .then(response => {*/}
            {/*                setLoadingInvite(false)*/}
            {/*                window.App.toastShow('success', 'Sikeres meghívás!',);*/}
            {/*              })*/}
            {/*              .catch(error => {*/}
            {/*                setLoadingInvite(false)*/}
            {/*                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);*/}
            {/*              })*/}
            {/*          }}*/}
            {/*  />*/}
            {/*)}*/}
            {localStorage.getItem('roles').toString().includes('role_guest_allow') &&
            <>
              {formData.allowed === true || loadingAllow === true ? (
                <Button icon="pi pi-qrcode" className="p-button-success p-mr-2 disabled"
                        label="Vendég jóváhagyása"/>
              ) : (
                <Button icon="pi pi-qrcode" className="p-button-success p-mr-2"
                        label="Vendég jóváhagyása"
                        onClick={() => {
                          setLoadingAllow(true)
                          GuestAllowService(formData.id)
                            .then(response => {
                              window.App.toastShow('success', 'Sikeres engedélyezés!');
                              window.App.setState({
                                rerenderGuestList: true
                              })
                              GuestShowService(formData.id)
                                .then(response => {
                                  setFormData(response.data)
                                  setLoadingAllow(false)
                                })
                                .catch(error => {
                                  window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                })
                            })
                            .catch(error => {
                              setLoadingAllow(false)
                              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                            })
                        }}
                />
              )}
            </>
            }

            {localStorage.getItem('roles').toString().includes('role_guest_document_valid') &&
            <>
              {formData.allowed === true && formData.documentValid === false ? (
                <>
                  {formData.type === 'serial' ? (
                    <>
                      <Button type="button" icon="pi pi-id-card" className="p-button-success p-mr-2"
                              label="Belépés engedélyezése"
                              onClick={() => {
                                setAllowDialog(true)
                              }}
                      />
                      <Dialog visible={allowDialog}
                              style={{width: '450px'}}
                              header="Belépés engedélyezése"
                              modal
                              onHide={() => {
                                setAllowDialog(false)
                              }}>
                        <Form onSubmit={allowOnSubmit}
                              initialValues={allowFormData}
                              validate={allowValidate}
                              render={({handleSubmit, submitting}) => (
                                <form onSubmit={handleSubmit} className="">
                                  <div className={"p-grid"}>
                                    <div className={"p-col-12"}>
                                      <Field name="identifier" render={({input, meta}) => (
                                        <div className="p-field p-fluid">
                                          <label htmlFor="identifier"
                                                 className={classNames({'p-error': isFormFieldValid(meta)})}>Kártya
                                            kiválasztása</label>
                                          <span className="">
                                            <AutoComplete value={selectedAllowableIdentifier}
                                                          id={"identifier"}
                                                          suggestions={allowableIdentifiers}
                                                          forceSelection
                                                          completeMethod={searchAllowableIdentifiers}
                                                          field={"identifier"}
                                                          delay="1000"
                                                          placeholder={"Keresés gépeléssel..."}
                                                          className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                                          onSelect={(e) => {
                                                            setAllowFormData({
                                                              ...allowFormData,
                                                              identifier: {id: e.value.id}
                                                            })
                                                          }}
                                                          onChange={(e) => {
                                                            setAllowFormData({
                                                              ...allowFormData,
                                                              identifier: {id: null}
                                                            })
                                                            setSelectedAllowableIdentifier(e.value)
                                                          }}
                                                          dropdown
                                                          onClear={(e) => {
                                                            setSelectedAllowableIdentifier('')
                                                          }}
                                            />
                                        </span>
                                          {getFormErrorMessage(meta)}
                                        </div>
                                      )}/>
                                    </div>
                                  </div>
                                  <div className={"p-grid p-mt-1"}>
                                    <div className={"p-col-12 p-lg-6 p-xl-6"}>
                                    </div>
                                    <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                                      <Button type="button" label="Mentés" icon="pi pi-check"
                                              onClick={() => {
                                                if (window.confirm('Biztosan a megfelelő vendégkártyát választotta ki? ' +
                                                  'Mentés után módosításra már nem lesz lehetőség!')) {
                                                  allowOnSubmit(allowFormData)
                                                }
                                              }}
                                              loading={allowLoading}/>
                                    </div>
                                  </div>
                                </form>
                              )}/>
                      </Dialog>
                    </>
                  ) : (
                    <Button type="button" icon="pi pi-id-card" className="p-button-success p-mr-2"
                            label="Belépés engedélyezése"
                            onClick={() => {
                              setLoadingValidate(true)
                              GuestDocumentValidService(formData.id)
                                .then(response => {
                                  window.App.toastShow('success', 'Sikeres dokumentum ellőrizés!',);
                                  window.App.setState({
                                    rerenderGuestList: true
                                  })
                                  GuestShowService(formData.id)
                                    .then(response => {
                                      setFormData(response.data)
                                      setLoadingValidate(false)
                                      IdentifierByGuestListService('', props.initialValues.id)
                                        .then(response => {
                                          setIdentifiers(response.data.items)
                                        })
                                        .catch(error => {
                                          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                        })
                                    })
                                    .catch(error => {
                                      window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                    })
                                })
                                .catch(error => {
                                  setLoadingValidate(false)
                                  window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                })
                            }}
                    />
                  )}
                </>
              ) : (
                <>
                  <Button type="button" icon="pi pi-id-card" className="p-button-success p-mr-2 disabled"
                          label="Belépés engedélyezése"
                  />
                </>
              )}
            </>
            }
          </div>
          <div className={"p-col-12 p-lg-4 p-xl-4 p-text-right"}>
            {localStorage.getItem('roles').toString().includes('role_guest_write') &&
            <>
              {(formData.id) && (formData.allowed === false || formData.status === "not_administrated") ? (
                <Button type="button" label="Módosítás" icon="pi pi-pencil" className="p-button-warning"
                        onClick={() => {
                          window.App.setState({
                            sidebarGuestShow: false,
                            sidebarGuestUpdate: true,
                            props: formData
                          })
                        }}
                />
              ) : (
                <Button type="button" label="Módosítás" icon="pi pi-pencil" className="disabled p-button-warning"/>
              )}
            </>
            }
          </div>
        </div>
      </Panel>
      <Panel headerTemplate={
        <div className={"p-grid"}>
          <div className={"p-col-12"}>
            <h3>
              <i className={"pi pi-list"}></i> Azonosítók
            </h3>
          </div>
        </div>
      } className={"p-mt-3"}>
        <div className={"p-grid"}>
          {identifiers && identifiers[0] ? (
            [identifiers.map((identifier, index) => {
              return (
                <div className={"p-col p-fluid p-text-center"} style={{maxWidth: '200px'}}>
                  <img src={process.env.REACT_APP_API_HOST + "/identifier/qr/" + identifier.id} className={"w-100"}/>
                  {identifier.serial ? (
                    <h3>{identifier.serial}</h3>
                  ) : (
                    <h3>{identifier.identifier}</h3>
                  )}
                </div>
              )
            })]
          ) : (
            <div className={"p-col-12"}>
              Még nincs hozzárendelve azonosító vagy nincs jogosultság az azonosító megtekintéséhez.
            </div>
          )}
        </div>
      </Panel>
    </>
  )
}
export default withTranslation('common')(GuestShowComponent);
